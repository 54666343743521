import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Context } from "../../../MasterLayout";
import Axios from "../../plugins/axios";

const AdsRedirect = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);

  const search = useLocation().search;
  const auth_code = new URLSearchParams(search).get("auth_code");
  const store_id = new URLSearchParams(search).get("state");
  const localState = localStorage.getItem("ads_state");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (store_id === localState) {
          let response = await Axios.patch("/api/store/ads-connect", {
            auth_code: auth_code,
            store_id: store_id,
          });

          dispatch({
            type: "subscription_expired",
            response: response.data.statusCode,
          });

          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 1500,
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }

      setTimeout(() => {
        return navigate("/tiktok-store/store-listing");
      }, 4000);
    };

    fetchData();
  }, []);
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h1>Connecting....</h1>
      <h2>Redirecting to <b>Store Manager</b></h2>
    </div>
  );
};

export default AdsRedirect;
