import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../../MasterLayout.js";
import Axios from "../../plugins/axios.js";
import { isEmpty } from "../CommonComponents/isEmpty.js";
import "./OrderDetail.css";

const OrdersDetail = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [item, setItem] = useState(null);
  const params = useParams();

  useEffect(() => {
    getOrderDetail();
  }, []);

  const getOrderDetail = async () => {
    try {
      dispatch({ type: "loader_show", response: true });
      let response = await Axios.get(`/api/orders/${params?.order_id}`).catch(
        (e) => console.error("Axios-Catch: " + e.message)
      );
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200) {
        setItem(response.data.data);
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  };

  return (
    <>
      {!isEmpty(item) && (
        <div className="manage-orders-page">
          <Row className="justify-content-md-center">
            <div className="order-number">{item?.id || "--"}</div>
            <Col xs lg="8">
              <div className="order-details">
                <div className="order-status order-title">
                  {item?.status || "--"}
                </div>
                <div className="order-info">
                  <div className="shipping-method">
                    <div className="label">Location</div>
                    <div className="value">
                      {item?.recipient_address?.district_info?.[0]
                        ?.address_name || "--"}
                    </div>
                  </div>
                  <div className="shipping-method">
                    <div className="label">Created Time</div>
                    <div className="value">
                      {item?.create_time
                        ? moment(item?.create_time).format(
                          "DD/MM/YYYY hh:mm:ss a"
                        )
                        : "--"}
                    </div>
                  </div>
                  <div className="shipping-method">
                    <div className="label">Tracking number:</div>
                    <div className="value">
                      {item?.tracking_number != "" ? (
                        <Link
                          className="btn-link value"
                          to={`https://www.google.com/search?q=${item.tracking_number}`}
                          target="_blank"
                        >
                          {item.tracking_number}
                        </Link>
                      ) : (
                        "--"
                      )}
                    </div>
                  </div>
                  <div className="shipping-method">
                    <div className="label">Shipping method:</div>
                    <div className="value">{item?.shipping_type || "--"}</div>
                  </div>
                  <div className="order-type">
                    <div className="label">Order type:</div>
                    <div className="value">
                      {item?.line_items?.[0]?.sku_type || "--"}
                    </div>
                  </div>
                  <div className="late-dispatch">
                    <div className="label">Delivery option ID</div>
                    <div className="value">
                      {item?.delivery_option_id || "--"}
                    </div>
                  </div>
                  <div className="warehouse">
                    <div className="label">Delivery option name:</div>
                    <div className="value">
                      {item?.delivery_option_name || "--"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-details">
                <div className="package-label">Order Items</div>
                {item?.line_items.map((v, i) => (
                  <div key={i}>
                    <div className="package-details">
                      <div className="package-info">
                        <div className="item-details">
                          <div className="item-image me-3">
                            <img src={v?.sku_image} alt="Item" />
                          </div>
                          <div className="item-info">
                            <div className="d-flex">
                              <div>
                                <div className="item-name">
                                  {v?.product_name}
                                </div>
                                <div className="sku">SKU ID: {v?.sku_id}</div>
                                <div className="dflt">{v?.sku_name}</div>
                              </div>
                              <div>
                                <div className="item-price">
                                  ${v?.sale_price} x {v?.quantity_sold}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col xs lg="4">
              <div className="customer-details">
                <div className="customer-paid-label mb-3">
                  What your customer paid
                </div>
                <div className="customer-paid-details">
                  <div className="payment-method">
                    <div className="pl-0">Payment method</div>
                    <div className="value">{item?.payment_method_name}</div>
                  </div>
                  <hr />
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="order-subtotal">
                      <div className="item-subtotal d-flex justify-content-between">
                        <div className="pl-0">
                          <b>Net sales</b>
                        </div>
                        <div className="value d-flex">
                          <b>
                            {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                            {parseFloat(
                              (item?.total_payment || 0) +
                              (item?.refund?.total_payment || 0) +
                              parseFloat(
                                (item?.total_discount || 0) +
                                (item?.refund?.total_discount || 0)
                              )
                            ).toFixed(2)}
                          </b>
                          <Accordion.Header></Accordion.Header>
                        </div>
                      </div>
                      <Accordion.Body>
                        {item?.total_payment ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">Gross sales</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_payment}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund?.total_payment ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">Gross sales refund</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.refund?.total_payment}
                            </div>
                          </div>
                        ) : null}
                        {item?.total_discount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">Seller discount</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_discount}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund?.total_discount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">Seller discount refund</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.refund?.total_discount}
                            </div>
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="item-subtotal d-flex justify-content-between">
                    <div className="pl-0">
                      <b>COGS</b>
                    </div>
                    <div className="value d-flex">
                      <b>
                        {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                        {item?.total_cost || 0}
                      </b>
                    </div>
                  </div>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1" className="order-subtotal">
                      <div className="item-subtotal d-flex justify-content-between">
                        <div className="pl-0">
                          <b>Shipping</b> {item?.total_shipping_price !== 0 &&
                            item?.is_estimated_fees ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  Showing Estimated Shipping From Products Added
                                  Shipping
                                </Tooltip>
                              }
                            >
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              />
                            </OverlayTrigger>
                          ) : null}
                        </div>
                        <div className="value d-flex align-items-center gap-1">
                          <b>
                            {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                            {parseFloat(
                              (item?.total_shipping_price || 0) +
                              (item?.refund?.total_shipping_price || 0)
                            ).toFixed(2)}
                          </b>
                          <Accordion.Header />
                        </div>
                      </div>
                      <Accordion.Body>
                        {item?.total_customer_paid_shipping_fee_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Customer-paid shipping fee
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_customer_paid_shipping_fee_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund
                          ?.total_customer_paid_shipping_fee_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Customer-paid shipping fee refund
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {
                                item?.refund
                                  ?.total_customer_paid_shipping_fee_amount
                              }
                            </div>
                          </div>
                        ) : null}
                        {item?.total_platform_shipping_fee_discount_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              TikTok shop shipping incentive fee
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {
                                item?.total_platform_shipping_fee_discount_amount
                              }
                            </div>
                          </div>
                        ) : null}
                        {item?.refund
                          ?.total_platform_shipping_fee_discount_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              TikTok shop shipping incentive refund
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {
                                item?.refund
                                  ?.total_platform_shipping_fee_discount_amount
                              }
                            </div>
                          </div>
                        ) : null}
                        {item?.total_customer_shipping_fee_offset_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Customer shipping fee offset
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_customer_shipping_fee_offset_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund
                          ?.total_customer_shipping_fee_offset_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Customer shipping fee offset refund
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {
                                item?.refund
                                  ?.total_customer_shipping_fee_offset_amount
                              }
                            </div>
                          </div>
                        ) : null}
                        {item?.total_fbt_fulfillment_fee_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">FBT fulfillment fee</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_fbt_fulfillment_fee_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund?.total_fbt_fulfillment_fee_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              FBT fulfillment fee refund
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.refund?.total_fbt_fulfillment_fee_amount}
                            </div>
                          </div>
                        ) : null}
                        {/* <div className="item-subtotal d-flex justify-content-between">
                        <div className="pl-0">Co-funded fee shipping reimbursement</div>
                        <div className="value">{getSymbolFromCurrency(item?.payment?.currency)} {"item"}</div>
                      </div> */}
                        {item?.total_shipping_fee_subsidy_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Shipping fee subsidy{" "}
                              <span className="text-green">
                                <b>Co-funded</b>
                              </span>
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_shipping_fee_subsidy_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund?.total_shipping_fee_subsidy_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Shipping fee subsidy refund{" "}
                              <span className="text-green">
                                <b>Co-funded</b>
                              </span>
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.refund?.total_shipping_fee_subsidy_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.total_customer_estimated_paid_shipping ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">Estimated Paid Shipping</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_customer_estimated_paid_shipping}
                            </div>
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion defaultActiveKey="2">
                    <Accordion.Item eventKey="2" className="order-subtotal">
                      <div className="item-subtotal d-flex align-items-center justify-content-between">
                        <div className="pl-0">
                          <b>Fees</b> {item?.total_item_fees !== 0 &&
                            item?.is_estimated_fees ? (
                            <OverlayTrigger
                              overlay={<Tooltip>Estimated Fee</Tooltip>}
                            >
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              />
                            </OverlayTrigger>
                          ) : null}
                        </div>
                        <div className="value d-flex align-items-center gap-1">
                          <b>
                            {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                            {parseFloat(
                              (item?.total_item_fees || 0) +
                              (item?.refund?.total_item_fees || 0)
                            ).toFixed(2)}
                          </b>
                          <Accordion.Header></Accordion.Header>
                        </div>
                      </div>
                      <Accordion.Body>
                        {item?.total_referral_fee_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">Referral fee</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_referral_fee_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund?.total_referral_fee_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">Referral fee refund</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.refund?.total_referral_fee_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.total_affiliate_commission_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">Affiliate commission</div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_affiliate_commission_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund?.total_affiliate_commission_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Affiliate commission refund
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.refund?.total_affiliate_commission_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.total_affiliate_ads_commission_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Affiliate shop ads commission
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {item?.total_affiliate_ads_commission_amount}
                            </div>
                          </div>
                        ) : null}
                        {item?.refund?.total_affiliate_ads_commission_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Affiliate shop ads commission refund
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {
                                item?.refund
                                  ?.total_affiliate_ads_commission_amount
                              }
                            </div>
                          </div>
                        ) : null}
                        {item?.refund
                          ?.total_refund_administration_fee_amount ? (
                          <div className="item-subtotal d-flex justify-content-between">
                            <div className="pl-0">
                              Administration fee refund
                            </div>
                            <div className="value">
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {
                                item?.refund
                                  ?.total_refund_administration_fee_amount
                              }
                            </div>
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {parseFloat(item?.reimbursement || 0) != 0 && (
                    <Accordion defaultActiveKey="3">
                      <Accordion.Item eventKey="3" className="order-subtotal">
                        <div className="item-subtotal d-flex justify-content-between">
                          <div className="pl-0">
                            <b>Reimbursement</b>
                          </div>
                          <div className="value d-flex">
                            <b>
                              {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                              {parseFloat(item?.reimbursement || 0).toFixed(2)}
                            </b>
                            <Accordion.Header></Accordion.Header>
                          </div>
                        </div>
                        <Accordion.Body>
                          {item?.platform_reimbursement ? (
                            <div className="item-subtotal d-flex justify-content-between">
                              <div className="pl-0">Platform Reimbursement</div>
                              <div className="value">
                                {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                                {item?.platform_reimbursement}
                              </div>
                            </div>
                          ) : null}
                          {item?.logistics_reimbursement ? (
                            <div className="item-subtotal d-flex justify-content-between">
                              <div className="pl-0">
                                Logistics Reimbursement
                              </div>
                              <div className="value">
                                {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                                {item?.logistics_reimbursement}
                              </div>
                            </div>
                          ) : null}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                  <div className="total d-flex justify-content-between">
                    <div className="label pl-0">Total:</div>
                    <div className="value">
                      {getSymbolFromCurrency(item?.payment?.currency)}{" "}
                      {item?.profit_loss || 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer-details d-none">
                <div className="customer-paid-label">What you earned</div>
                <div className="customer-paid-label">
                  <p>Loading, refresh the page and check again</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default OrdersDetail;
