import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import noImage from "../../../images/no-image.jpg";
import { Context } from '../../../MasterLayout.js';
import Axios from '../../plugins/axios.js';
import { isEmpty } from '../CommonComponents/isEmpty.js';
import InfiniteScrollWrapper from '../Products/InfiniteScrollWrapper.js';
import './InvitationManagement.css';
import ProfitLanding from "./ProfitLanding.js";

const INITIAL_FORM = {
    name: '',
    message: '',
    date: '',
    status: '',
    products: []
};

const INITIAL_ERRORS = {
    name: '',
    message: '',
    date: '',
    store_id: '',
    products: ''
};

const InvitationManagement = () => {
    const { dispatch } = useContext(Context);
    const latestRef = useRef(0);

    const searchDate = useLocation().search;
    const id = new URLSearchParams(searchDate).get("id");
    const isUpdate = !!id;
    const navigate = useNavigate();

    const [stores, setStores] = useState([]);
    const [currentStore, setCurrentStore] = useState("");
    const [showProductModal, setShowProductModal] = useState(false);
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [searchKey, setSearchKey] = useState("");

    const [formData, setFormData] = useState(INITIAL_FORM);
    const [errors, setErrors] = useState(INITIAL_ERRORS);
    const [searchPage, setSearchPage] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsSearched, setItemsSearched] = useState([]);
    const [searchItems, setSearchItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    // Fetch data functions
    const fetchData = useCallback(async (endpoint, params = {}) => {
        dispatch({ type: 'loader_show', response: true });
        try {
            const { data } = await Axios.get(endpoint, { params });
            dispatch({ type: 'subscription_expired', response: data?.data.statusCode });
            setLoadedOnce(true)
            dispatch({ type: 'loader_hide', response: true });
            return data?.data;
        } catch (error) {
            toast.error(error.response?.data?.message || `Error fetching data`);
            dispatch({ type: 'loader_hide', response: true });
            return null;
        }
    }, []);

    // Initialize stores
    useEffect(() => {
        const getStores = async () => {
            const storesData = await fetchData("/api/dashboard/stores");
            if (storesData?.stores) {
                storesData.stores.map((v) => {
                    v.value = v._id;
                    v.label = v.name;
                    return v;
                });
                setStores(storesData.stores);
                let storeIndex = 0;
                if (storesData?.default_store) {
                    storeIndex = storesData.stores.findIndex(n => n._id == storesData.default_store)
                };
                setCurrentStore(storesData.stores[storeIndex]);
                if (!id && !isUpdate) {
                    setSelectedItems([]);
                    setSearchItems([]);
                    setItemsSearched([]);
                    setSearchKey("");
                }
            }
        };
        getStores();
    }, []);

    useEffect(() => {
        const getInvitation = async () => {
            const invitationData = await fetchData(`/api/invitation/${id}`);
            if (invitationData) {
                setSelectedItems(invitationData?.products?.map(v => ({ ...v, ...v.product_id })));
                setItemsSearched(invitationData?.products?.map(v => ({ ...v, ...v.product_id })));
                setCurrentStore(invitationData.store_id);
                setFormData({
                    name: invitationData.name,
                    message: invitationData.message,
                    date: invitationData.date,
                    products: invitationData?.products?.map(v => ({ ...v, ...v.product_id }))
                });
            };
        };
        if (id && isUpdate) getInvitation();
    }, [id, isUpdate]);

    useEffect(() => {
        setHasMore(true);
        if (currentStore) loadProducts(1);
    }, [searchKey, currentStore]);

    async function loadProducts(currentPage) {
        const requestId = ++latestRef.current;
        try {
            let response = await Axios.post(`/api/dashboard/search-products`, {
                search: searchKey,
                store_id: currentStore._id,
                page: currentPage,
                sku_id: itemsSearched.map((v) => v?.sku_id),
                limit: 20,
            }).catch((e) => console.error("Axios-Catch: " + e.message));

            dispatch({
                type: "subscription_expired",
                response: response.data.statusCode,
            });
            setSearchPage(currentPage);
            if (
                response &&
                response.status === 200 &&
                Array.isArray(response?.data?.data?.records) &&
                requestId === latestRef.current
            ) {
                let filteredArr = [],
                    dataArray =
                        currentPage === 1
                            ? [...itemsSearched, ...response?.data?.data?.records]
                            : [...searchItems, ...response?.data?.data?.records];
                dataArray.map((v) => {
                    if (filteredArr.filter((n) => n?._id === v?._id).length === 0) {
                        filteredArr.push(v);
                    }
                });
                setSearchItems(filteredArr);
                setHasMore(response?.data?.data?.records?.length >= 20);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            setHasMore(false);
            console.error("Error-Catch: " + error.message);
        }
    }

    // Validation function
    const validateField = (name, value) => {
        switch (name) {
            case 'name':
                return !value.trim() ? 'Name is required' : '';
            case 'message':
                return !value.trim() ? 'Message is required' : '';
            case 'store_id':
                return !value.trim() ? 'Store is required' : '';
            case 'date':
                return !value ? 'Date is required' :
                    new Date(value) <= new Date() ? 'Date must be from tomorrow onwards' : '';
            case 'products':
                return value.length === 0 ? 'At least one product is required' :
                    value.some(p => !p.rate) ? 'Rate is required for all products' : '';
            default:
                return '';
        }
    };

    // Form handlers
    const handleInputChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: validateField(name, value) }));
    };

    const handleRateChange = (product, value) => {
        const updated = selectedItems.map((p, i) =>
            p._id === product._id ? { ...p, rate: value } : p
        );
        setSelectedItems(updated);
        setItemsSearched(updated);
        setErrors(prev => ({ ...prev, products: validateField('products', updated) }));
    };

    // Submit handler
    const handleSubmit = async (status) => {
        // Validate all fields
        const newErrors = {
            name: validateField('name', formData.name),
            message: validateField('message', formData.message),
            date: validateField('date', formData.date),
            store_id: validateField('store_id', currentStore?._id),
            products: validateField('products', selectedItems)
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error)) {
            return;
        }

        try {
            const payload = {
                ...formData,
                store_id: currentStore._id,
                products: selectedItems.map(v => {
                    return { product_id: v._id, sku_id: v.sku_id, rate: v.rate, }
                }),
                status: status,
            };

            isUpdate && id
                ? await Axios.patch(`/api/invitation/${id}`, payload)
                : await Axios.post('/api/invitation', payload);

            navigate('/tiktok-store/invitations');
            toast.success("Invitation created successfully");
            setFormData(INITIAL_FORM);
            setSelectedItems([]);
            setErrors(INITIAL_ERRORS);
        } catch (error) {
            toast.error(error.response?.data?.message || "Error creating invitation");
        }
    };

    const handleDropdownToggle = () => {
        if (itemsSearched.length > 0) {
            let selectedItems = [],
                notSelectedItems = [];
            for (let i = 0; i < searchItems.length; i++) {
                const element = searchItems[i];
                if (itemsSearched.find((n) => n?._id === element?._id)) {
                    selectedItems.push(element);
                } else {
                    notSelectedItems.push(element);
                }
            }

            setSearchItems([...selectedItems, ...notSelectedItems]);
        }
    };

    const handleOptionChange = (product) => {
        setItemsSearched((prev) => {
            if (prev.find((v) => v?._id === product?._id)) {
                return prev.filter((opt) => opt?._id !== product?._id);
            } else {
                return [...prev, product];
            }
        });
    };


    if (!stores.length && loadedOnce) return <ProfitLanding />;

    return (
        <Card className="invitation-management">
            <Card.Body>
                {/* Section 1: Basic Information */}
                <div className="section border p-4 mb-4 rounded">
                    <h5 className="mb-4">Basic Information</h5>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Invitation Name *</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.name}
                                onChange={e => handleInputChange('name', e.target.value)}
                                isInvalid={!!errors.name}
                            />
                            {errors.name && <div className="error-message text-danger">{errors.name}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Valid Till *</Form.Label>
                            <Form.Control
                                type="date"
                                value={formData.date}
                                onChange={e => handleInputChange('date', e.target.value)}
                                min={new Date(new Date().setDate(new Date().getDate() + 1))
                                    .toISOString().split('T')[0]}
                                isInvalid={!!errors.date}
                            />
                            {errors.date && <div className="error-message text-danger">{errors.date}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Message *</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={formData.message}
                                onChange={e => handleInputChange('message', e.target.value)}
                                isInvalid={!!errors.message}
                            />
                            {errors.message && <div className="error-message text-danger">{errors.message}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Select Store *</Form.Label>
                            <Select
                                closeMenuOnSelect={true}
                                value={currentStore}
                                options={stores}
                                onChange={(e) => {
                                    setCurrentStore(e);
                                    setErrors(prev => ({ ...prev, store_id: "" }));
                                    setSelectedItems([]);
                                    setSearchItems([]);
                                    setItemsSearched([]);
                                    setSearchKey("");
                                }}
                            />
                            {errors.store_id && <div className="error-message text-danger">{errors.store_id}</div>}
                        </Form.Group>
                    </Form>
                </div>

                {/* Section 2: Products */}
                <div className="section border p-4 mb-4 rounded">
                    <h5 className="mb-4">Products</h5>
                    <Button
                        variant="primary"
                        className="mb-3"
                        onClick={() => {
                            if (!currentStore) {
                                toast.error("Select Store First");
                            } else {
                                handleDropdownToggle()
                                setShowProductModal(true)
                            }
                        }}
                    >
                        Add Products
                    </Button>

                    {!isEmpty(selectedItems) ?
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Rate</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedItems?.map((product, index) => (
                                    <tr key={index}>
                                        <td>
                                            <img
                                                src={
                                                    product?.main_images?.[0]?.urls?.[0] ||
                                                    noImage
                                                }
                                                alt="icon"
                                                className="icon"
                                            />
                                            {product?.title}/{product?.sku_id}
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                value={product.rate}
                                                onChange={e => handleRateChange(product, e.target.value)}
                                                placeholder="Rate *"
                                                isInvalid={!product.rate}
                                            />
                                        </td>
                                        <td>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => {
                                                    const updated = selectedItems.filter((_, i) => _._id !== product._id);
                                                    setSelectedItems(updated);
                                                    setItemsSearched(updated);
                                                    setErrors(prev => ({
                                                        ...prev,
                                                        products: validateField('products', updated)
                                                    }));
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        : null}
                    {errors.products && <div className="error-message text-danger">{errors.products}</div>}
                </div>

                {/* Section 3: Actions */}
                <div className="d-flex justify-content-end gap-3">
                    <Button variant="secondary" onClick={() => handleSubmit("Draft")}>Save as Draft</Button>
                    <Button variant="primary" onClick={() => handleSubmit("Create")}>Create Invitation</Button>
                </div>

                {/* Product Selection Modal */}
                <Modal
                    show={showProductModal}
                    onHide={() => setShowProductModal(false)}
                    size="xl"
                    scrollable={true}
                    backdrop="static"
                >
                    <Modal.Header>
                        <Modal.Title>Select Products</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-2">
                            <input
                                type="text"
                                id="search-box"
                                placeholder="Search here"
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                            />
                        </div>
                        <div id="scrollable_div">
                            <InfiniteScrollWrapper
                                parentDivId="scrollable_div"
                                isInfiniteScrollOn={true}
                                lengthData={searchItems.length}
                                functionNext={() => loadProducts(searchPage + 1)}
                                hasMore={hasMore}
                            >
                                {searchItems?.map((v, i) => (
                                    <label htmlFor={v?._id} key={v?._id}>
                                        <div className="d-flex align-items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                id={v?._id}
                                                className="mr-2 cursor-pointer"
                                                value={v?._id}
                                                checked={itemsSearched?.find((n) => n?._id === v?._id)?._id ? true : false}
                                                onChange={() => handleOptionChange(v)}
                                            />
                                            <div className="prd-img mr-3">
                                                <OverlayTrigger
                                                    key="right"
                                                    trigger={["hover", "focus"]}
                                                    placement="right"
                                                    rootClose
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Body>
                                                                <img width={500} src={v?.main_images?.[0]?.urls?.[0] || noImage} alt="" />
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <Button variant="link" className='p-0' size="xs">
                                                        <img width={70} src={v?.main_images?.[0]?.urls?.[0] || noImage} alt="" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="prd-title-list">
                                                <p>
                                                    <OverlayTrigger overlay={<Tooltip>{v?.product_title || v.title}</Tooltip>}>
                                                        <span>{v?.product_title ? v?.product_title?.length > 90 ? v?.product_title?.substring(0, 90) + '...' : v?.product_title : v?.title > 90 ? v?.title?.substring(0, 90) + '...' : v?.title}</span>
                                                    </OverlayTrigger>
                                                </p>
                                                <p>{"product_list.sku_id"}:&nbsp;{v?.sku_id}</p>
                                                <p>{"product_list.sku"}:&nbsp;{v?.seller_sku}</p>
                                            </div>
                                        </div>
                                    </label>
                                ))}
                            </InfiniteScrollWrapper>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-end gap-3">
                            <Button variant="secondary" onClick={() => {
                                setItemsSearched(selectedItems)
                                setShowProductModal(false)
                            }}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => {
                                setSelectedItems(itemsSearched)
                                setShowProductModal(false)
                            }}>Add</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Card.Body>
        </Card >
    );
};

export default InvitationManagement;
