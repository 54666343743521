import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../images/logo.png";
import Axios from "../plugins/axios";
import FooterSlick from "./components/FooterSlick";

const ResetPassword = () => {
  const navigaate = useNavigate();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);
  const [error, setError] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [validResponse, setValidResponse] = useState(false);

  useEffect(() => {
    validateToken();
  }, []);

  const validateToken = async () => {
    try {
      const response = await Axios.post("/api/validateresettoken", {
        token: token,
      });
      if (response.data.statusCode !== 200) {
        navigaate("/login");
      }
    } catch (error) {
      console.error("Error validating reset token:", error);
    }
  };

  const handleNewPasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);
    validatePasswordStrength(newPasswordValue, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    validatePasswordStrength(newPassword, confirmPasswordValue);
  };

  const validatePasswordStrength = (password, confirmPassword) => {
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const isPasswordMatching = password === confirmPassword;
    const isStrongPassword = strongPasswordRegex.test(password);

    setIsPasswordStrong(isPasswordMatching && isStrongPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPasswordStrong) {
      setError(
        "Password Must Contain Minimum Eight Characters With At Least One Uppercase Letter, One Lowercase Letter, One Number And One Special Character!"
      );
      return;
    }
    try {
      const response = await Axios.post("/api/validateresettoken", {
        token: token,
      });
      if (response.data.statusCode === 200) {
        const resetPassword = await Axios.post("/api/resetpassword", {
          email: response.data.email.toLocaleLowerCase(),
          password: confirmPassword,
        });
        if (resetPassword.data.statusCode === 200) {
          setValidResponse(true);
          setResetMessage(resetPassword.data.message);
          alert("password has been successfuly changed.");
          navigaate("/login");
        }
      } else {
        const data = await response.json();
        setError(data.message || "Password reset failed");
      }
    } catch (error) {
      setError("An error occurred while resetting the password");
    }
  };

  return (
    <>
      <div className="row justify-content-center h-100 align-items-center h-80">
        <div className="col-md-6">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <div className="text-center mb-3">
                  <a href="/">
                    <img src={logo} alt="Viralytic" />
                  </a>
                </div>
                <h4 className="text-center mb-4">Forgot Password</h4>
                <div className="authincation-content p-5">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="mb-1 text-white">
                        <strong>New Password</strong>{" "}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-1 text-white">
                        <strong>Confirm Password</strong>{" "}
                      </label>
                      <input
                        type="password"
                        className={`form-control ${
                          validResponse ? "" : "is-invalid"
                        }`}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                    </div>
                    <div>
                      <ul style={{ listStyleType: "circle", color: "white" }}>
                        <li>Password should contain below details</li>
                        <ul style={{ listStyleType: "square", color: "white" }}>
                          <li>- At least one special character</li>
                          <li>- At least 8 characters</li>
                          <li>- At least one capital letter</li>
                          <li>- At least one digit</li>
                        </ul>
                      </ul>
                    </div>
                    {validResponse && (
                      <div className="text-white">{resetMessage}</div>
                    )}
                    {error && <p className="text-danger">{error}</p>}
                    <div className="text-center mt-4">
                      <button
                        type="submit"
                        className="btn bg-white text-primary btn-block"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSlick />
    </>
  );
};

export default ResetPassword;
