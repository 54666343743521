import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from '../../../MasterLayout';
import Axios from '../../plugins/axios';

function AddUpdateCoupon() {
    const { t } = useTranslation();

    const location = useLocation();
    const { state, pathname } = location
    const navigate = useNavigate()
    const { dispatch } = useContext(Context);

    const [type, setType] = useState("");
    let data = {};
    const [duration, setDuration] = useState(t("coupon.forever"));
    const [name, setName] = useState("");
    const [max_redemptions, setMaxRedemptions] = useState('');
    const [amount_or_percentage, setAmountOrPercentage] = useState('');
    const [no_of_months, setNoOfMonths] = useState(0);
    const [reedeem_by_date, setReedeemByDate] = useState("");
    const [isedit, setIsedit] = useState(false)
    const [error, setError] = useState({
        type: { error: false },
        duration: { error: false },
        name: { error: false },
        amount_or_percentage: { error: false },
        max_redemptions: { error: false },
        no_of_months: { error: false },
        reedeem_by_date: { error: false }
    });
    const [plans, setPlans] = useState([]);
    const [planID, setPlanID] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const [coupon, setCoupon] = useState('');

    const handleChange = (event) => {
        const { value } = event.target;
        setType(value);
    };

    const handleChangeduration = (event) => {
        const { value } = event.target;
        setDuration(value);
    };

    useEffect(() => {
        getPlans();
        getCoupons();
        if (pathname === "/coupon/edit") {
            setIsedit(true);
            setType(state?.type === 'amount' ? "Amount" : "Percentage")
            setDuration(state?.duration)
            setName(state?.name ? state.name : "");
            setMaxRedemptions(state?.max_redemptions)
            setAmountOrPercentage(state?.amount_or_percentage)
            if (state?.duration === 'repeating') {
                setNoOfMonths(state?.no_of_months)
            }
            setReedeemByDate(new Date(state?.reedeem_by_date).toISOString().split('T')[0]);
            setPlanID(state?.plan_id || []);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])


    function validation() {
        let validate = true;


        if (type === '') {
            setError(prev => ({ ...prev, type: { error: true } }));
            validate = false;
        } else {
            data.type = type;
        }

        if (duration === '') {
            setError(prev => ({ ...prev, duration: { error: true } }));
            validate = false;
        } else {
            data.duration = duration;
        }

        if (reedeem_by_date !== '') {
            var date = moment(reedeem_by_date + " 23:59:59").format(), timestamp = moment(date).format("X"),
                tomorrowTimestamp = moment().add(1, 'days').format("X");
            if (parseInt(tomorrowTimestamp) >= parseInt(timestamp)) {
                setError(prev => ({ ...prev, reedeem_by_date: { error: true } }));
                validate = false;
            } else {
                data.reedeem_by_date = reedeem_by_date;
            }
        } else {
            data.reedeem_by_date = reedeem_by_date;
        }

        if (name === '') {
            setError(prev => ({ ...prev, name: { error: true } }));
            validate = false;
        } else {
            data.name = name;
        }

        if (duration === 'repeating') {
            if (no_of_months === 0) {
                setError(prev => ({ ...prev, no_of_months: { error: true } }));
                validate = false;
            } else {
                data.no_of_months = no_of_months;
            }
        }

        if (max_redemptions === '') {
            setError(prev => ({ ...prev, max_redemptions: { error: true } }));
            validate = false;
        } else {
            data.max_redemptions = max_redemptions;
        }

        if (amount_or_percentage === '') {
            setError(prev => ({ ...prev, amount_or_percentage: { error: true } }));
            validate = false;
        } else {
            data.amount_or_percentage = amount_or_percentage;
        }

        return validate;
    }

    const handleSave = async () => {
        const isValid = validation();
        if (isValid) {
            setError({
                type: { error: false },
                duration: { error: false },
                name: { error: false },
                amount_or_percentage: { error: false },
                max_redemptions: { error: false },
                no_of_months: { error: false },
                reedeem_by_date: { error: false }
            })
            data.type = data.type === "Amount" ? "amount" : "percentage";
            data.plan_id = planID?.map(v => v?._id);
            data.next_coupon = coupon?._id;
            try {
                if (isedit) {
                    data._id = state._id;
                    const response = await Axios.patch("/api/coupon", data);
                    if (response?.data?.message === "Coupon name already exists" || response?.data?.statusCode === 400) {
                        return toast.warn("Coupon name already exists", {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                    if (response?.data?.data?.acknowledged) {
                        toast.success("Coupon Updated successfully", {
                            position: "top-center",
                            autoClose: 3000,
                        });
                        navigate('/coupon');

                    } else {
                    }
                } else {
                    let response = await Axios.post("/api/coupon", data);
                    if (response.data.statusCode === 201) {
                        return toast.error(response.data.message, {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else if (response.data && response.data.data._id) {
                        setTimeout(() => {
                            navigate('/coupon');
                        }, 2000);

                        return toast.success("Coupon Sucesfully Created", {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    } else {
                        return toast.error("Coupon creation Failed", {
                            position: "top-center",
                            autoClose: 3000,
                        });
                    }
                }

            } catch (error) {
                console.error(error)
            }
        }
    };

    const getPlans = async () => {
        let response = await Axios.get('/api/plans').catch((e) => console.error('Axios-Catch: ' + e.message));
        if (response.status == 200 && response.data.data) {
            response.data.data.map((v) => {
                v.value = v.slug;
                v.label = v.name;
                return v;
            })
            setPlans(response.data.data)
        };
    };

    const getCoupons = async () => {
        let response = await Axios.get('/api/coupons').catch((e) => console.error('Axios-Catch: ' + e.message));
        if (response.status == 200 && response.data.data) {
            response.data.data.map((v) => {
                v.value = v.name;
                v.label = v.name;
                return v;
            })
            setCoupons(response.data.data)
        };
    };

    return (
        <div className='row'>
            <div className='col-lg-5'>
                <Card>
                    <Card.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('coupon.name')}</Form.Label>
                                <Form.Control size='sm' value={name} onChange={(e) => setName((e.target.value).trim().toUpperCase())} type="text" placeholder={t('coupon.coupon_name')} />
                                {error.name.error && <p style={{ color: 'red' }}>{t('coupon.coupon_name_required')}</p>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>{t('coupon.type')}</Form.Label>
                                <Form.Select size='sm' onChange={handleChange} value={type}
                                    aria-label="Default select example">
                                    <option value={''} disabled>{t('coupon.type')}</option>
                                    <option value='amount'>{t('coupon.amount')}</option>
                                    <option value="percentage">{t('coupon.percentage')}</option>
                                </Form.Select>
                                {error.type.error && <p style={{ color: 'red' }}>{t('coupon.coupon_type_required')}</p>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>Plan</Form.Label>
                                <Select
                                    closeMenuOnSelect={true}
                                    placeholder="Select Plans"
                                    isMulti
                                    value={planID}
                                    options={plans}
                                    onChange={(e) => setPlanID(e)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>Next Coupon</Form.Label>
                                <Select
                                    closeMenuOnSelect={true}
                                    placeholder="Next Coupon"
                                    value={coupon}
                                    options={coupons}
                                    onChange={(e) => setCoupon(e)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label>{t('coupon.amount_or_percentage')}</Form.Label>
                                <Form.Control min="0" size='sm' value={amount_or_percentage} onChange={(e) => setAmountOrPercentage(e.target.value)} type="Number" placeholder="Amount or Percentage" />
                                {error.amount_or_percentage.error && <p style={{ color: 'red' }}>{t('coupon.coupon_amount_or_percentage_required')}</p>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label>{t('coupon.max_redemptions')}</Form.Label>
                                <Form.Control min="0" size='sm' value={max_redemptions} onChange={(e) => setMaxRedemptions(parseInt(e.target.value))} type="Number" placeholder={t('coupon.max_redemptions')} />
                                {error.max_redemptions.error && <p style={{ color: 'red' }}>{t('coupon.coupon_max_redemptions_required')}</p>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label>{t('coupon.duration')}</Form.Label>
                                <Form.Select size='sm' onChange={handleChangeduration} value={duration} aria-label="Default select example">
                                    <option value='Forever' >{t('coupon.forever')}</option>
                                    <option value="Once">{t('coupon.once')}</option>
                                    <option value="repeating">{t('coupon.multiple_months')}</option>
                                </Form.Select>
                                {error.duration.error && <p style={{ color: 'red' }}>{t('coupon.coupon_duration_required')}</p>}
                            </Form.Group>
                            {duration === 'repeating' &&
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                    <Form.Label>{t('coupon.no_of_months')}</Form.Label>
                                    <Form.Control min="0" size='sm' value={no_of_months} onChange={(e) => setNoOfMonths(e.target.value)} type="Number" placeholder="Coupon Name" />
                                    {error.no_of_months.error && <p style={{ color: 'red' }}>{t('coupon.coupon_no_of_months_required')}</p>}
                                </Form.Group>
                            }
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label>{t('coupon.reedem_by_date')}</Form.Label>
                                <Form.Control size='sm' value={reedeem_by_date} onChange={(e) => setReedeemByDate(e.target.value)} type="Date" placeholder="Coupon Name" />
                                {error.reedeem_by_date.error && <p style={{ color: 'red' }}>{t('coupon.coupon_reedem_by_date_future_date')}</p>}
                            </Form.Group>
                            <Button onClick={() => handleSave()} className='mr-2' size='sm'>{t('coupon.save')}</Button>
                            <Button onClick={() => navigate('/coupon')} variant='outline-danger' size='sm'>{t('coupon.cancel')}</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            <ToastContainer />
        </div>
    )
}

export default AddUpdateCoupon