import "bootstrap-daterangepicker/daterangepicker.css";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Button, Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Select from "react-select";
import '../../../../config';
import { Context } from '../../../../MasterLayout';
import Axios from "../../../plugins/axios";
import Conversion from "../../CommonComponents/Conversion";
import Export from "../../CommonComponents/export";
import InfiniteScrollWrapper from "../../Products/InfiniteScrollWrapper";
import RangeSlider from "../../Products/RangeSlider";
import Category from "./Category";

const RelevantInfluencersTab = forwardRef((props, ref) => {
   const { t } = useTranslation();
   const { dispatch } = useContext(Context);
   const params = useParams();
   const [page, setPage] = useState(0);
   const [onLoad, setOnLoad] = useState(false);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [sorting, setSorting] = useState({ sort: 18, sort_type: 'DESC' });
   const [favourite, setFavourite] = useState([]);
   const [category1, setCategory1] = useState('');
   const [category2, setCategory2] = useState('');
   const [categoryId1, setCategoryId1] = useState('');
   const [categoryId2, setCategoryId2] = useState('');
   const [contactInfo, setContactInfo] = useState('');
   const [keyword, setKeyword] = useState('');
   const [searchClick, setSearchClick] = useState(0);
   const [salesRange, setSalesRange] = useState([]);
   const [influencerRange, setInfluencerRange] = useState([]);
   const [payload, setPayload] = useState({});
   const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
   const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const salesRangeOption = {
      '0-10000': '<10K',
      '10000-100000': '10K-100K',
      '100000-1000000': '100K-1M',
      '1000000-10000000': '1M-10M',
      '10000000-': '10M+',
   }, influencerRangeOption = {
      '0-5': '≤5',
      '5-10': '5-10',
      '10-20': '10-20',
      '20-50': '20-50',
      '50-100': '50-100',
      '-100': '>100',
   }, contactArray = [
      { value: 0, label: 'Email' },
      { value: 3, label: 'Instagram' },
      { value: 4, label: 'Twitter' },
      { value: 2, label: 'Whatsapp' },
      { value: 5, label: 'Youtube' },
      { value: 1, label: 'Zalo' }
   ];

   const tableHead = [
      {
         title: t('common.rank'),
         className: 'text-center',
      },
      {
         title: t('common.influencers'),
         className: 'text-rigth',
      },
      {
         title: t('common.revenue'),
         className: 'text-center sorting',
         sort: 18
      },
      {
         title: t('common.sales'),
         className: 'text-center sorting',
         sort: 19
      },
      {
         title: t('common.atv'),
         className: 'text-center sorting',
         sort: 20
      },
      {
         title: t('common.products'),
         className: 'text-center sorting',
         sort: 15
      },
      {
         title: t('common.videos'),
         className: 'text-center sorting',
         sort: 12,
      },
      {
         title: t('common.average_views'),
         className: 'text-center sorting',
         sort: 16,
      },
      {
         title: t('common.followers'),
         className: 'text-center sorting',
         sort: 1
      },
      {
         title: t('common.favorite'),
         className: 'text-center',
      },
   ];

   const handleSalesRange = async (value) => {
      setSalesRange(value);
   };
   const handleInfluencerRange = async (value) => {
      setInfluencerRange(value);
   };

   useImperativeHandle(ref, () => ({
      async relevantInfluencersTab() {
         if (onLoad == false) {
            setHasMore(true);
            setPage(0)
            relevantInfluencer(0);
         }
         setOnLoad(true);
      }
   }));

   useEffect(() => {
      if (onLoad == true) {
         setHasMore(true);
         setPage(0);
         relevantInfluencer(0);
      }
   }, [sorting, salesRange, influencerRange, contactInfo, category1, category2, searchClick]);

   const relevantInfluencer = async (page) => {
      try {
         let data = {
            sort: sorting.sort,
            page: page,
            sort_type: sorting.sort_type,
            seller_id: params.shop_id,
         }

         if (salesRange.from) {
            data['sold_count_start'] = salesRange.from;
         }
         if (salesRange.to && salesRange.to != '') {
            data['sold_count_end'] = salesRange.to;
         }

         if (influencerRange.from) {
            data['fan_start'] = influencerRange.from;
         }
         if (influencerRange.to && influencerRange.to != '') {
            data['fan_end'] = influencerRange.to;
         }
         if (contactInfo) {
            data['contact_type'] = contactInfo;
         }
         if (keyword) {
            data['keyword'] = keyword;
         }
         if (category1 != '') {
            data['category1'] = category1;
            data['category_id1'] = categoryId1;
         }
         if (category2 != '') {
            data['category2'] = category2;
            data['category_id2'] = categoryId2;
         }

         setPayload(data);
         let response = await Axios.post('/api/shop/relevant-influencers', data);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });
         if (response && response.status === 200) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.influencer_id) : favourite;

            if (page == 0) {
               setItems(response.data.data);
               setFavourite(array);
            } else {
               setItems(items.concat(response.data.data));
               setFavourite(favourite.concat(array));
            }
            setHasMore(response?.data?.data?.length === 20);
         } else {
            setHasMore(false);
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }
   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      }
   };
   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         relevantInfluencer(number);
      }
   }

   const handleCategory = async (categoryIds, categoryNames) => {
      setCategory1(categoryNames[0] ? categoryNames[0] : '');
      setCategory2(categoryNames[1] ? categoryNames[1] : '');
      setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
      setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
   };

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-influencer', data);
      const index = favourite.indexOf(data.influencer_id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.influencer_id);
      }

      setFavourite(favourite.concat([]));
   };

   const CustomClearText = () => "clear all";
   const ClearIndicator = (props) => {
      const {
         children = <CustomClearText />,
         getStyles,
         innerProps: { ref, ...restInnerProps },
      } = props;
      return (
         <div
            {...restInnerProps}
            ref={ref}
            style={getStyles("clearIndicator", props)}
         >
            <div style={{ padding: "0px 5px" }}>{children}</div>
         </div>
      );
   };

   const handleContactInfo = async (e) => {
      if (e.length != 0) {
         let contacts = e.map((v) => { return v.value })
         setContactInfo(contacts.join(','))
      } else {
         setContactInfo('')
      };
   };

   return (
      <>
         <Card className='rounded-0'>
            <Card.Body>
               <div className='topfilter'>
                  <h3>{t('common.influencer_list')}</h3>
                  <div className="d-flex align-items-center mb-3 justify-content-end">
                     <div className="input-group mb-3 w-auto">
                        <input type="text" className="form-control form-control-sm" placeholder={t("common.search_influencers")} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                        <div className="input-group-append">
                           <button className="btn btn-primary btn-xs" type="button" onClick={() => { setHasMore(true); setItems([]); setSearchClick(searchClick + 1); }}>
                              {t('common.search')}
                           </button>
                        </div>
                     </div>
                  </div>
                  <Category category={props.category} showCategory={props.shop?.category_list} handleCategory={handleCategory} categoryId1={categoryId1} />
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('common.sales')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={1000000} rangeOption={salesRangeOption} rangeOptionName={'sales-range'} step={10} handleUpdate={handleSalesRange} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('common.followers')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={100} defaultMin={0} defaultMax={5000} rangeOption={influencerRangeOption} rangeOptionName={'influencer'} step={10} handleUpdate={handleInfluencerRange} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu btn-xs">
                        <Select closeMenuOnSelect={false} placeholder="Contact Infomation" components={{ ClearIndicator }} isMulti options={contactArray} onChange={(e) => { handleContactInfo(e) }} />
                     </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mx-2 my-3">
                     <Export payload={payload} data_source="Relevant Influencers" type="Shop" />
                  </div>
               </div>
            </Card.Body>
         </Card>
         <Card>
            <Card.Body className='p-2'>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <InfiniteScrollWrapper
                        isInfiniteScrollOn={true}
                        lengthData={items.length}
                        functionNext={functionNext}
                        hasMore={hasMore}
                     >
                        <table id="example" className="display w-100 dataTable">
                           <thead>
                              <tr role="row">
                                 {tableHead.map((value, index) => (
                                    <th className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {items.map((value, i) => (
                                 <tr key={i}>
                                    <td className="text-center">{value.rank}</td>
                                    <td>
                                       <div className="d-flex">
                                          <div className="prd-img mr-3">
                                             <Link to={'/influencers/details/' + value.id + '/video-creators'} target="_blank">
                                                {value.avatar_larger_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + value.avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={80} />}
                                             </Link>
                                          </div>
                                          <div className="prd-title">
                                             <p className='mb-2'>
                                                <Link to={'/influencers/details/' + value.id + '/video-creators'} target="_blank">
                                                   {value.nickname || ''}
                                                </Link>
                                             </p>
                                             <div className="d-flex align-items-center">
                                                <div className="mr-2">
                                                   <span className={value?.sys_country?.country_code.toLowerCase() ? `fi fi-${value?.sys_country?.country_code.toLowerCase()} mr-1` : ''}></span>
                                                </div>
                                                <div>
                                                   <Link to={'/influencers/details/' + value.id + '/video-creators'} target="_blank">@{value.unique_id}</Link>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </td>
                                    <td className="text-center">
                                       {value.estimated_sales_amount_usd}<br />
                                       <span className="text-muted">({value.estimated_sales_amount_str})</span>
                                    </td>
                                    <td className="text-center"><Conversion value={value.estimated_sales_volume || 0} /></td>
                                    <td className="text-center">
                                       {value.estimated_avg_price_usd}<br />
                                       <span className="text-muted">({value.estimated_avg_price_str})</span>
                                    </td>
                                    <td className="text-center"><Conversion value={value.related_products || 0} /></td>
                                    <td className="text-center"><Conversion value={value.related_videos || 0} /></td>
                                    <td className="text-center"><Conversion value={value.avg_play_count || 0} /></td>
                                    <td className="text-center"><Conversion value={value.follower_count || 0} /></td>
                                    <td className="text-center">
                                       <Button variant="outline-dark" size="xs" className="px-2 py-1" onClick={() => { markFavourite({ influencer_id: value.id, country_code: value.sys_country?.country_code }); }}>    <i className={"fa " + (favourite.indexOf(value.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i> </Button>
                                    </td>
                                 </tr >
                              ))}
                           </tbody>
                        </table>
                     </InfiniteScrollWrapper>
                  </div>
               </Table>
            </Card.Body>
         </Card>
      </>
   )
});

export default RelevantInfluencersTab;