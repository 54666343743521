import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../../css/storeListing/storeListing.css';
import { Context } from '../../../MasterLayout';
import Axios from "../../plugins/axios";

const CouponsList = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  let coloumns = [
    t("coupon.name"),
    "Plan",
    "Next Coupon",
    t("coupon.type"),
    t("coupon.duration"),
    t("coupon.amount_or_percentage"),
    t("coupon.max_redemptions"),
    t("coupon.actions"),
  ];
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);
  const [CouponData, setCouponData] = useState([]);
  const [SearchValue, setSearchValue] = useState("");
  const [sort, setsort] = useState({ name: 0, type: 0, duration: 0 });

  const [pageCount, setPageCount] = useState(0);
  const [filterValueDuration, setFilterValueDuration] = useState({
    Value: "",
    realValue: t("coupon.select_duration"),
  });
  const [filterValueType, setFilterValueType] = useState({
    Value: "",
    realValue: t("coupon.select_type"),
  });
  const [filterofType, setFilterofType] = useState("");
  const [filterofDuration, setFilterofDuration] = useState("");

  // COMMON FETCH COUPON DATA
  const fetchData = async (
    sortid = "createdAt",
    sortascdsc = -1,
    searchvalue = null
  ) => {
    try {
      let response = await Axios.get(
        `/api/coupon?filterOfType=${filterofType}&filterOfDuration=${filterofDuration}&search=${
          SearchValue ? SearchValue : ""
        }&filterType=${filterValueType.Value}&filterDuration=${
          filterValueDuration.Value
        }&page=${page}&limit=${limit}&sortby=${sortid}&sort=${sortascdsc}`
      );
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.data && response.data.result) {
        const { results, totalCount } = response?.data?.result;
        setCouponData(results ? results : []);
        setTotalEntries(totalCount ? totalCount : 0);
        setPageCount(Math.ceil(totalCount / limit));
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //COMMON CURRENT SORTBY AND SORT VALUES
  function currentSortValue() {
    let soryBy;
    let sortValue;
    if (sort.name) {
      soryBy = "name";
      sortValue = sort.name;
    } else if (sort.type) {
      soryBy = "type";
      sortValue = sort.type;
    } else if (sort.duration) {
      soryBy = "duration";
      sortValue = sort.duration;
    }
    return { soryBy, sortValue };
  }

  // INITIAL RENDERING STARTS FROM HERE (useEffect)
  useEffect(() => {
    const { soryBy, sortValue } = currentSortValue();
    fetchData(soryBy, sortValue, SearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const { soryBy, sortValue } = currentSortValue();
    fetchData(soryBy, sortValue, SearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filterValueDuration.Value, filterValueType.Value]);

  //DELETE COUPON DATA HANDLER
  const [DeleteData, setDeleteData] = useState();
  const [DeleteDataModal, setDeleteDataModal] = useState(false);
  const deleteModal = async (data) => {
    const { __v, ...newObject } = data;
    setDeleteData(newObject);
    setDeleteDataModal(true);
  };
  const handleCouponDelete = async (newObject) => {
    try {
      // const { __v, ...newObject } = data;
      newObject.deleted_at = new Date();
      setDeleteDataModal(false);
      const response = await Axios.patch("/api/coupon/delete", newObject);
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });
      if (response.data.data.acknowledged) {
        toast.success(t("coupon.sucesfully_deleted"), {
          position: "top-center",
          autoClose: 3000,
        });
        const { soryBy, sortValue } = currentSortValue();
        fetchData(soryBy, sortValue);
      } else {
        toast.error(t("coupon.deletion_failed"), {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //SORTING OPERATION STARTS HERE
  const handleSort = (e) => {
    const sortid = e.target.id;
    setPage(1);
    if (sortid === "name") {
      if (sort.name === 1) {
        setsort({ name: -1, type: 0, duration: 0 });
      } else {
        setsort({ name: 1, type: 0, duration: 0 });
      }
      fetchData(sortid, sort.name === 1 ? -1 : 1, SearchValue);
    } else if (sortid === "type") {
      if (sort.type === 1) {
        setsort({ name: 0, type: -1, duration: 0 });
      } else {
        setsort({ name: 0, type: 1, duration: 0 });
      }
      fetchData(sortid, sort.type === 1 ? -1 : 1, SearchValue);
    } else if (sortid === "duration") {
      if (sort.duration === 1) {
        setsort({ title: 0, brand: 0, duration: -1 });
      } else {
        setsort({ title: 0, brand: 0, duration: 1 });
      }
      fetchData(sortid, sort.duration === 1 ? -1 : 1, SearchValue);
    }
  };

  //SEARCHING OPERATION STARTS HERE
  const handlsearchvalue = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const handleEnterKeyPress = async (e) => {
    if (e.key === "Enter") {
      setPage(1);
      setsort({ name: 1, type: 0, duration: 0 });
      let response = await Axios.get(
        `/api/coupon?filterOfType=${filterofType}&filterOfDuration=${filterofDuration}&search=${
          SearchValue ? SearchValue : ""
        }&filterType=${filterValueType.Value}&filterDuration=${
          filterValueDuration.Value
        }&page=${1}&limit=${limit}&sortby=${"name"}&sort=${1}`
      );
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });
      if (response && response.data && response.data.result) {
        const { results, totalCount } = response?.data?.result;
        setCouponData(results ? results : []);
        setTotalEntries(totalCount ? totalCount : 0);
        setPageCount(Math.ceil(totalCount / limit));
      } else {
        setCouponData([]);
        console.error("Failed to fetch data");
      }
    }
  };

  //PAGINATION AND LIMIT
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  const onClickSetLimit = (e) => {
    setPage(1);
    const value = e.target.getAttribute("value");
    setlimit(value);
  };

  //Filter
  const onClickSetFilter = (e) => {
    const value = e.target.getAttribute("value");

    if (value.length) {
      setPage(1);
      setFilterofType("type");
      setFilterValueType({ Value: value, realValue: e.target.innerText });
    } else {
      setFilterofType("");
      setFilterValueType({ Value: value, realValue: e.target.innerText });
      setPage(1);
    }
  };
  function onClickSetDurationFilter(e) {
    const value = e.target.getAttribute("value");

    if (value.length) {
      setPage(1);
      setFilterofDuration("duration");
      setFilterValueDuration({ Value: value, realValue: e.target.innerText });
    } else {
      setFilterofDuration("");
      setFilterValueDuration({ Value: value, realValue: e.target.innerText });
      setPage(1);
    }
  }

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title mb-0">{t("coupon.lists_of_coupons")}</h4>
          <div className="d-flex">
            <div className="dropdown ml-2">
              <button
                className="btn btn-outline-dark dropdown-toggle btn-xs"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {limit}
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="#"
                  value="10"
                  onClick={onClickSetLimit}
                >
                  10
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  value="25"
                  onClick={onClickSetLimit}
                >
                  25
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  value="50"
                  onClick={onClickSetLimit}
                >
                  50
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  value="100"
                  onClick={onClickSetLimit}
                >
                  100
                </a>
              </div>
            </div>
            <div className="dropdown ml-2">
              <button
                className="btn btn-outline-dark dropdown-toggle btn-xs"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {filterValueType.realValue}
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="#"
                  value=""
                  onClick={onClickSetFilter}
                >
                  {t("coupon.select_type")}
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  value="percentage"
                  onClick={onClickSetFilter}
                >
                  {t("coupon.percentage")}
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  value="amount"
                  onClick={onClickSetFilter}
                >
                  {t("coupon.amount")}
                </a>
              </div>
            </div>
            <div className="dropdown ml-2">
              <button
                className="btn btn-outline-dark dropdown-toggle btn-xs"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {filterValueDuration.realValue}
              </button>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  href="#"
                  value=""
                  onClick={(e) => onClickSetDurationFilter(e)}
                >
                  {t("coupon.select_duration")}
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  value="Forever"
                  onClick={(e) => onClickSetDurationFilter(e)}
                >
                  {t("coupon.forever")}
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  value="Once"
                  onClick={onClickSetDurationFilter}
                >
                  {t("coupon.once")}
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  value="Multiple-months"
                  onClick={onClickSetDurationFilter}
                >
                  {t("coupon.multiple_months")}
                </a>
              </div>
            </div>
            <Button
              onClick={() => navigate("/coupon/create")}
              size="xs"
              className="ml-2"
            >
              {t("coupon.add_coupon")}
            </Button>
            <Form.Control
              onKeyPress={handleEnterKeyPress}
              value={SearchValue}
              onChange={(e) => handlsearchvalue(e)}
              className="w-25 form-control-sm ml-2"
              placeholder={t("coupon.search_coupons")}
            />
          </div>
        </div>
        <div className="card-body">
          <Table responsive className="w-100">
            <div id="example_wrapper" className="dataTables_wrapper">
              <table id="example" className="display w-100 dataTable">
                <thead>
                  <tr role="row">
                    <th
                      onClick={(e) => {
                        handleSort(e);
                      }}
                      id="name"
                      className={`text-center sorting ${
                        (sort.name === 1 ? "sorting-asc" : "") ||
                        (sort.name === -1 ? "sorting-desc" : "")
                      }`}
                    >
                      {coloumns[0]} &nbsp;
                      <span
                        onClick={(e) => {
                          handleSort(e);
                        }}
                        id="name"
                      ></span>
                    </th>
                    <th className="text-center">{coloumns[1]}</th>
                    <th className="text-center">{coloumns[2]}</th>
                    <th
                      onClick={(e) => {
                        handleSort(e);
                      }}
                      id="type"
                      className={`text-center sorting ${
                        (sort.type === 1 ? "sorting-asc" : "") ||
                        (sort.type === -1 ? "sorting-desc" : "")
                      }`}
                    >
                      {coloumns[3]} &nbsp;
                      <span
                        onClick={(e) => {
                          handleSort(e);
                        }}
                        id="type"
                      ></span>
                    </th>
                    <th
                      onClick={(e) => {
                        handleSort(e);
                      }}
                      id="duration"
                      className={`text-center sorting ${
                        (sort.duration === 1 ? "sorting-asc" : "") ||
                        (sort.duration === -1 ? "sorting-desc" : "")
                      }`}
                    >
                      {coloumns[4]} &nbsp;
                      <span
                        onClick={(e) => {
                          handleSort(e);
                        }}
                        id="duration"
                      ></span>
                    </th>

                    <th className="text-center">{coloumns[5]}</th>
                    <th className="text-center">{coloumns[6]}</th>
                    <th className="text-right">{coloumns[7]}</th>
                  </tr>
                </thead>
                <tbody>
                  {CouponData.map((data, i) => (
                    <tr style={{ height: "3.5rem" }} key={i}>
                      <td className="text-center">{data.name}</td>
                      <td className="text-center">
                        {data?.plan_id?.map((v) => (
                          <>
                            {v?.name} <br />
                          </>
                        ))}
                      </td>
                      <td className="text-center">{data?.next_coupon?.name}</td>
                      <td className="text-center">{data.type}</td>
                      <td className="text-center">{data.duration}</td>
                      <td className="text-center">
                        {data.amount_or_percentage}
                      </td>
                      <td className="text-center">{data.max_redemptions}</td>
                      <td className="text-right">
                        <div className="d-flex justify-content-end">
                          {/* <Button
                                                        variant="outline-dark"
                                                        onClick={() => navigate('/coupon/edit', { state: data })}
                                                        className="btn-xs mr-1"
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </Button> */}
                          <Link
                            onClick={() => deleteModal(data)}
                            className="btn btn-outline-danger btn-xs mr-1"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  {t("common.showing")}{" "}
                  {totalEntries ? (page - 1) * limit + 1 : 0} {t("common.to")}{" "}
                  {Math.min(page * limit, totalEntries) || 0} {t("common.of")}{" "}
                  {totalEntries} {t("common.entries")}
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    previousLabel="< Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={page - 1}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </Table>
        </div>
      </div>
      <ToastContainer />
      <Modal show={DeleteDataModal} onHide={() => setDeleteDataModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("coupon.delete_coupon")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Card>
            <Card.Body>
              {t("common.are_you_sure_you_want_to_delete")} {DeleteData?.name} ?
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCouponDelete(DeleteData)}
          >
            {t("common.yes")}
          </Button>
          <Button variant="primary" onClick={() => setDeleteDataModal(false)}>
            {t("common.no")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CouponsList;
