import React, { useContext, useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/landing.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import { GeneralContext } from '../../GeneralLayout';
import { Context } from '../../MasterLayout';
import Axios from "../plugins/axios";
import Footer from "./components/Footer";
import Header from "./components/Header";

const PageOne = () => {
   const { t } = useTranslation();
   const isUserLoggedIn = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
   const currentContext = localStorage.getItem('user') && localStorage.getItem('token') ? Context : GeneralContext;
   const { dispatch } = useContext(currentContext);
   const [limit, setLimit] = useState(12);
   const [page, setPage] = useState(1);
   const [recordsCount, setRecordsCount] = useState(0);
   const [pageCount, setPageCount] = useState(0);
   const [items, setItems] = useState([]);
   const [reinit, setReinit] = useState(0);

   useEffect(() => {
      getInfluencerVideo();
   }, [reinit])

   const handlePageClick = (event) => {
      setPage(event.selected + 1);
      setReinit(reinit + 1);
   };

   const getInfluencerVideo = async () => {
      try {
         dispatch({ type: 'loader_show', response: true });
         let params = {
            page: page,
            limit: limit,
         };
         const response = await Axios.get('/api/influencer-videos', params);
         if (response && response.status === 200 && response.data?.data) {
            setPage(response.data.data.currentPage);
            setPageCount(response.data.data.totalPages);
            setRecordsCount(response.data.data.recordsCount);
            setItems(response.data.data.results);
         } else {
            setPage(1);
            setPageCount(0);
            setRecordsCount(0);
            setItems([]);
         };
      } catch (error) {
         console.error('Error-Catch: ' + error.message);
      };
      dispatch({ type: 'loader_hide', response: true });
   };

   return (
      <>
         {isUserLoggedIn == false && <Header />}
         {isUserLoggedIn == false && <section id="home" className="hero-area hero-inner-area py-5">
            <div className="container">
               <div className="row align-items-center"></div>
            </div>
         </section>}
         <section className="pricing-table section mb-5">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title mb-0 px-0">
                        <h2>{t('common.content_creation')}</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  {items.length > 0 && items.map((v, i) => (
                     <div key={i} className="col-lg-4 col-md-6 col-12">
                        <div className="single-table">
                           <div className="table-head">
                              <div className='content-creation-img'><img src={v.image} className="mb-2 user_img" alt="" /></div>
                              <p className="mb-0"><strong>Name: </strong>{v.name}</p>
                              {v.followers && <p className="mb-0"><strong>Followers: </strong>{v.followers}</p>}
                              {v.tiktok_url && <Link to={v.tiktok_url} target="_blank">
                                 <strong>{t('common.tiktok_profile')}</strong>
                              </Link>}
                              <p className="mb-0"><strong>${v.price_list ? v.price_list[0].price.toFixed(2) : ''} </strong></p>
                              <div className="button mt-3">
                                 <Link to={`/content-creation/${v._id}/influencers-details`}>
                                    <Button variant="primary" size="sm">{t('common.view_details')}</Button>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
               <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                     {recordsCount === 0 ? "No - Record Found" :
                        `Showing ${(((page - 1) * limit) + 1)} to ${Math.min(page * limit, recordsCount)} of ${recordsCount} entries`}
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers">
                     <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        forcePage={page - 1}
                        previousLabel="< Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                     />
                  </div>
               </div>
            </div>
         </section>
         {isUserLoggedIn == false && <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2>{t('pricing.focus_on_tiktok_analytics')}</h2>
                        <div className="button">
                           <a href="/pre-register" className="btn">{t('pricing.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         }
         {isUserLoggedIn == false && <Footer />}
      </>
   );
};

export default PageOne;