import "bootstrap-daterangepicker/daterangepicker.css";
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Card, Collapse, Form, InputGroup, Table } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useTranslation } from "react-i18next";
import { Context } from "../../../../MasterLayout";
import Axios from "../../../plugins/axios";
import RangeSlider from "../../Products/RangeSlider";
import ViralVideos from './Videos';

const ViralVideosFilter = () => {
   const { t } = useTranslation();
   const { state, dispatch } = useContext(Context);
   const dateRangeRef = useRef();
   const [page, setPage] = useState(0);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [countryCode, setCountryCode] = useState('');
   const [followerRange, setFollowerRange] = useState();
   const [likeRange, setLikeRange] = useState();
   const [durationRange, setDurationRange] = useState();
   const [favourite, setFavourite] = useState([]);
   const [open, setOpen] = useState(false);
   const [isCommerce, setIsCommerce] = useState(false);
   const [industries, setIndustries] = useState([]);
   const [industrieId, setIndustrieId] = useState();
   const [sorting, setSorting] = useState({ sort: 3, sort_type: 'DESC' });
   const [dateFilter, setDateFilter] = useState('default');
   const [SearchValue, setSearchValue] = useState(state && state.keyword ? state.keyword : '');
   const [view, setView] = useState('card');
   const [dateRange, setDateRange] = useState({
      start_day: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end_day: moment().subtract(1, 'days').format('YYYY-MM-DD')
   });
   const [type, setType] = useState()
   const sortOptions = [
      {
         lable: 'Views',
         sort: 3
      },
      {
         lable: 'Likes',
         sort: 8
      },
      {
         lable: 'Like Rate',
         sort: 70
      },
      {
         lable: 'Comments',
         sort: 5
      },
      {
         lable: 'Shares',
         sort: 6
      },
   ]

   const followerRangeOption = {
      '0-10000': '0-10K',
      '10000-50000': '10K-50K',
      '50000-500000': '50K-500K',
      '500000-1000000': '500K-1000000',
      '1000000-5000000': '1M-5M',
      '5000000-1000000000': '5M-1B',
      '1000000000-': '1B+',
   };
   const likeRangeOption = {
      '0-10000': '<10K',
      '10000-100000': '10K-100K',
      '100000-1000000': '100K-1M',
      '1000000-10000000': '1M-10M',
      '10000000-': '10M+',
   };
   const durationRangeOption = {
      '0-5': '0-5s',
      '6-15': '6s-15s',
      '16-30': '16s-30s',
      '31-60': '31s-60s',
      '61-120': '61s-120s'
   };

   useEffect(() => {
      setHasMore(true);
      if (!type) {
         handleType('viral')
      } else {
         resetItemsAndPage();
         videoSearch(0);
      }
   }, [countryCode, likeRange, isCommerce, sorting, industrieId, followerRange, durationRange, dateRange]);

   const resetItemsAndPage = () => {
      setItems([]);
      setHasMore(true);
      setPage(0);
   };

   const markFavourite = async (data) => {
      try {
         await Axios.post('/api/favorite-product-video', data);
         const index = favourite.indexOf(data.id);
         if (index > -1) {
            favourite.splice(index, 1);
         } else {
            favourite.push(data.id);
         }
         setFavourite(favourite.concat([]));
      } catch (error) {
         console.error(error.message)
      }
   };

   const selectCountry = (countryCode) => {
      setCountryCode(countryCode);
   };

   const videoSearch = async (page) => {
      try {
         let params = { page: page, sort: sorting.sort, sort_type: sorting.sort_type };
         if (countryCode !== '') params['country_code'] = countryCode;
         if (SearchValue) {
            params['keyword'] = SearchValue;
         }
         if (dateFilter === 'custom') {
            params['start_day'] = dateRange.start_day;
            params['end_day'] = dateRange.end_day;
         }
         if (followerRange) {
            params['follower_start'] = followerRange.from;
            params['follower_end'] = followerRange.to;
         }
         if (likeRange) {
            params['digg_start'] = likeRange.from;
            params['digg_end'] = likeRange.to;
         }
         if (durationRange) {
            params['duration_start'] = durationRange.from;
            params['duration_end'] = durationRange.to;
         }
         if (isCommerce) {
            params['is_commerce'] = isCommerce;
         }
         if (industrieId) {
            params['industry_id'] = industrieId;
         }

         const response = await Axios.post('/api/viral/video', params);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });
         handleResponse(response, page);
      } catch (error) {
         console.error('Error fetching products:', error.message);
      }
   };

   const handleResponse = (response, page) => {
      if (response && response.status === 200 && response.data.data) {
         const responseData = response.data;
         const array = responseData.favorite ? responseData.favorite.map(value => value.video_id) : favourite;
         if (responseData.Industries && responseData.Industries.length > 0) {
            setIndustries(responseData.Industries)
         }
         if (page === 0) {
            setItems(responseData.data);
            setFavourite(array);
         } else {
            setItems(prevItems => [...prevItems, ...responseData.data]);
            setFavourite(prevFav => [...prevFav, ...array]);
         }
         setHasMore(responseData?.data?.length === 20);
         dispatch({ type: 'subscription_expired', response: responseData.statusCode });
      } else {
         setHasMore(false);
      }
   };
   const handleType = (type) => {
      setType(type)
      if (type === 'hot') {
         setLikeRange();
         setFollowerRange();
      }
      if (type === 'viral') {
         setCountryCode('US')
         setLikeRange({
            from: 10000,
            to: 10000000
         });
         setFollowerRange({
            from: 0,
            to: 5000
         });
      }
   }

   const functionNext = () => {
      const nextPage = page + 1;
      setPage(nextPage);
      videoSearch(nextPage);
   };
   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      }
   };
   const handleSortCard = (e) => {
      setSorting({ sort: e.target.value, sort_type: "DESC" })
   }
   const handleLikeRange = (value) => {
      setLikeRange(value);
   };

   const handleFollowerRange = (value) => {
      setFollowerRange(value);
   };
   const handleDurationRange = (value) => {
      setDurationRange(value);
   };
   const handleSwitchChange = (e) => {
      setIsCommerce(e.target.checked);
   };
   const handleApply = (event, picker) => {
      picker.element.val(`${picker.startDate.format('YYYY-MM-DD')} - ${picker.endDate.format('YYYY-MM-DD')}`);
      setDateRange({ start_day: picker.startDate.format('YYYY-MM-DD'), end_day: picker.endDate.format('YYYY-MM-DD') });
      setDateFilter('custom')
   };
   const style = {
      padding: "10px",
      backgroundColor: '#fef2f2',
      border: '1px solid rgba(255,62,95,.2)',
      color: '#ff3e5f'
   }


   const countries = { ...global.config.region, ...global.config.countries };
   const regionList = Object.keys(global.config.region).map(function (key) {
      return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>  {global.config.region[key]}</a>
   });

   return (
      <>
         <Card className='rounded-0'>
            <Card.Body>
               <div className='d-flex justify-content-end mb-2'>
                  <InputGroup className="w-25 h-25">
                     <Form.Control
                        type="text"
                        value={SearchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={t('creator_center.viral_videos.filter.search')}
                        className="w-25 h-25 p-2"
                        onKeyPress={() => { setPage(0); setHasMore(true); videoSearch(0) }}
                     />
                     <Button variant="outline-primary" size='xs' className="w-25 h-25 p-2" onClick={() => { setPage(0); setHasMore(true); videoSearch(0) }}>
                        <i className="fa fa-search" aria-hidden="true"></i>
                     </Button>
                  </InputGroup>
               </div>
               <div className='topfilter'>
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>{t('creator_center.viral_videos.filter.type')}</strong></p>
                     <div className="button-container">
                        <Button variant='' onClick={() => handleType('hot')} style={type === 'hot' ? style : {}}>Hot Videos</Button>
                        <Button variant='' onClick={() => handleType('viral')} style={type === 'viral' ? style : {}}>Viral Videos</Button>
                     </div>
                  </div>
                  <div className="d-flex align-items-center flex-wrap mb-2">
                     <p className="m-0 mr-3"><strong>{t('creator_center.viral_videos.filter.country')}</strong></p>
                     <a className={"btn " + (countryCode == '' ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 mb-2 my-1"}
                        onClick={() => selectCountry('')}>
                        {t('product_research.products_search.all')}
                     </a>
                     {regionList}
                     <a
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className='btn btn-outline-dark btn-xs show-sub mb-2 mb-2 my-1'><i className="fa fa-chevron-down" aria-hidden="true"></i></a>
                     <Collapse in={open}>
                        <div id="example-collapse-text" className='pl-5 mx-4'>
                           <div className='d-flex align-items-center flex-wrap '>
                              {
                                 Object.keys(countries).slice(8).map((key) => (
                                    (
                                       countries &&
                                       (
                                          <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => setCountryCode(key)}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{countries[key]}</a>
                                       )

                                    )

                                 ))
                              }
                           </div>
                        </div>
                     </Collapse>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>{t('creator_center.viral_videos.filter.filter')}</strong></p>
                     <div className="d-flex justify-content-end align-items-center">
                        <Form.Select onChange={(e) => { setIndustrieId(e.target.value) }}>
                           <option value="">Select Industry</option>
                           {industries.length > 0 &&
                              industries.map((industry, index) => (
                                 <option key={index} value={industry.industry_id}>{industry.value}</option>
                              ))
                           }
                        </Form.Select>
                     </div>
                     <p className='m-0 px-1 font-w500'>{t('creator_center.viral_videos.filter.like')}</p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                           select
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={likeRangeOption} rangeOptionName={'view'} step={10} handleUpdate={handleLikeRange} />
                        </div>
                     </div>
                     <p className='m-0 px-1 font-w500'>{t('creator_center.viral_videos.filter.follower')}</p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                           select
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={followerRangeOption} rangeOptionName={'duration'} step={1} handleUpdate={handleFollowerRange} />
                        </div>
                     </div>
                     <p className='m-0 px-1 font-w500'>{t('creator_center.viral_videos.filter.duration')}</p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                           select
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={durationRangeOption} rangeOptionName={'duration'} step={1} handleUpdate={handleDurationRange} />
                        </div>
                     </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center">
                     <p className='m-0 px-1 font-w500'>{t('creator_center.viral_videos.filter.post_date')}</p>
                     <div className="d-flex justify-content-center align-items-center">
                        <DateRangePicker ref={dateRangeRef} initialSettings={{ startDate: moment(new Date(dateRange.start_day)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_day)).format('MM/DD/YYYY') }} onApply={handleApply} >
                           <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={`${dateRange.start_day} - ${dateRange.end_day}`} />
                        </DateRangePicker>
                     </div>
                  </div>
                  <div className='d-flex align-items-center mb-2'>
                     <Form>
                        <Form.Check // prettier-ignore
                           type="switch"
                           id="custom-switch"
                           label={t('creator_center.viral_videos.filter.eCommerceSwitch')}
                           onChange={handleSwitchChange}
                           checked={isCommerce}
                        />
                     </Form>
                  </div>
                  <div className="d-flex align-items-center mb-2">

                     <p className='text-muted mb-0'><small>{t('product_research.top_sales.filter.long_text_2')}</small></p>
                  </div>
                  <div className="d-flex justify-content-end align-items-center">
                     {view === 'card' &&
                        <>
                           <div>
                              <Form.Select onChange={(e) => { handleSortCard(e) }}>
                                 {sortOptions.map((sort, index) => (
                                    <>
                                       <option key={sort.sort} value={sort.sort} className=''>
                                          <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                          {sort.lable}
                                       </option>
                                    </>
                                 ))}
                              </Form.Select>
                           </div>
                        </>
                     }
                     <div>
                        <ButtonGroup>
                           <Button variant="" className='border-1' onClick={() => { setView('card') }}>
                              <i className="fa fa-th-large" aria-hidden="true"></i>
                           </Button>
                           <Button variant="" onClick={() => { setView('list') }}>
                              <i className="fa fa-list" aria-hidden="true"></i>
                           </Button>
                        </ButtonGroup>
                     </div>
                  </div>
               </div>
            </Card.Body>
         </Card>
         <Card>
            <Card.Body className='p-2'>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <ViralVideos functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} handleSort={handleSort} sorting={sorting} view={view} />
                  </div>
               </Table>
            </Card.Body>
         </Card>
      </>
   );
};

export default ViralVideosFilter;
