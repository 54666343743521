import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Form, Row } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Select from "react-select";
import { Context } from '../../../MasterLayout.js';
import Axios from '../../plugins/axios.js';
import OrdersTable from './OrdersTable.js';
import ProfitLanding from "./ProfitLanding.js";
import StoreFetch from "./StoreFetch.js";

const OrdersList = () => {
  const limitArray = [10, 25, 50, 100];
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const searchDate = useLocation().search;
  const start_date = new URLSearchParams(searchDate).get("start_date");
  const end_date = new URLSearchParams(searchDate).get("end_date");
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [stores, setStores] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [currentStore, setCurrentStore] = useState('');
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');
  const [orderPagination, setOrderPagination] = useState({
    limit: 10,
    page: 1,
    recordsCount: 0,
    pageCount: 0
  });
  const [bestSellerDateRange, setBestSellerDateRange] = useState({
    start_date: moment().subtract(29, "days").toDate(),
    end_date: moment().toDate(),
  });

  useEffect(() => {
    if (start_date && end_date) {
      setBestSellerDateRange({
        start_date: start_date,
        end_date: end_date
      })
    }
    getStores();
  }, []);

  useEffect(() => {
    if (currentStore) getOrders();
  }, [bestSellerDateRange, orderPagination.page, orderPagination.limit, currentStore, search]);

  const getStores = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get('/api/dashboard/stores').catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        setLoadedOnce(true)
        response.data.data.stores.map((v) => {
          v.value = v._id;
          v.label = v.name;
          return v;
        });
        setStores(response.data.data.stores);
        let storeIndex = 0;
        if (response.data?.data?.default_store) {
          storeIndex = response.data.data.stores.findIndex(n => n._id == response.data.data.default_store)
        };
        setCurrentStore(response.data.data.stores[storeIndex]);
        setProductCount(response.data?.data?.productCount);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const getOrders = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get(`/api/orders?store_id=${currentStore._id}&page=${orderPagination.page}&limit=${orderPagination.limit}&start_date=${bestSellerDateRange?.start_date}&end_date=${bestSellerDateRange?.end_date}&search=${search}`).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200 && response.data?.data) {
        setOrderPagination((pre) => ({
          ...pre,
          pageCount: response.data.data.totalPages,
          recordsCount: response.data.data.recordsCount,
        }))
        setItems(response.data.data.results)
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    setBestSellerDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
  };

  const handlePageClick = (event) => {
    setOrderPagination((pre) => ({
      ...pre,
      page: event.selected + 1
    }));
  };

  const handleEnterKeyPress = async () => {
    setSearch(searchValue);
    setOrderPagination((pre) => ({
      ...pre,
      page: 1
    }));
  };

  return (
    <>
      {stores.length != 0 &&
        <>
          <div className="revenue-section">
            <Row className="align-items-center mb-3">
              <Col md="9" className="text-center text-sm-left">
              </Col>
              <Col md="3" className="">
                <div className="dropdown product-rating-menu mr-2">
                  <Select
                    closeMenuOnSelect={true}
                    defaultValue={currentStore}
                    options={stores}
                    onChange={(e) => setCurrentStore(e)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {productCount != 0 &&
            <>
              <div className="bestsellers-section">
                <Row className="align-items-center mb-3">
                  <Col md="6" className="text-center text-sm-left">
                  </Col>
                  <Col md="6" className="">
                    <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                      <div className="dropdown product-rating-menu mr-1">
                        <Form.Select className="form-control form-control-sm" defaultValue={orderPagination.limit}
                          onChange={(e) => { setOrderPagination((prev) => ({ ...prev, limit: e.target.value })); }}
                        >
                          {limitArray.map((v, i) => { return <option key={i} value={v}>{v}</option> })}
                        </Form.Select>
                      </div>
                      <Form.Control onKeyDown={(e) => { if (e.key === 'Enter') handleEnterKeyPress() }} onBlur={handleEnterKeyPress} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} className="w-auto form-control-sm mr-1" placeholder={"Search By Order ID"} />
                      <DateRangePicker onApply={handleApply} initialSettings={{
                        startDate: moment(new Date(bestSellerDateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(bestSellerDateRange.end_date)).format('MM/DD/YYYY'),
                        ranges: {
                          'Today': [moment().toDate(), moment().toDate()],
                          'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                          'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                          'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                          'This Month': [moment().startOf('month').toDate(), moment().toDate()],
                          'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                          'Last 3 Month': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                          'Last 6 Month': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                        },
                      }}>
                        <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                      </DateRangePicker>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body className="p-0">
                        <OrdersTable items={items} orderPagination={orderPagination} handlePageClick={handlePageClick} />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          }
          {productCount == 0 && loadedOnce && <StoreFetch />}
        </>
      }
      {stores.length == 0 && loadedOnce && <ProfitLanding />}
    </>
  );
};

export default OrdersList;