import React from "react";

const TermsOfService = () => {
  return (
    <div className="container-fluid p-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <h1 className="text-center mb-4">Terms of Service</h1>
          <p className="text-muted">
            <strong>Last Updated:</strong> January 1, 2024
          </p>

          <div className="card shadow-sm">
            <div className="card-body">
              <section className="mb-4">
                <p>
                  THE FOLLOWING SERVICE TERMS OF SERVICE (THESE “TOS”) SHALL BE
                  DEEMED INCORPORATED BY REFERENCE INTO EACH ORDER (AS DEFINED
                  BELOW) BETWEEN Viralytic LLP, A DELAWARE LIMITED LIABILITY
                  COMPANY (“Zonbase INC”) (TRADING AS WWW.VIRALYTIC.COM) AND THE
                  LICENSEE IDENTIFIED THEREIN (“LICENSEE”).
                </p>
                <p>
                  YOUR RIGHT TO ACCESS AND USE THE SERVICE (AS DEFINED BELOW),
                  WHETHER OR NOT AN ORDER HAS BEEN EXECUTED BETWEEN Viralytic
                  LLP AND YOU (OR AN ENTITY THAT YOU REPRESENT), IS EXPRESSLY
                  CONDITIONED ON ACCEPTANCE OF THESE TOS. BY ACCESSING OR USING
                  THE SERVICE, YOU AGREE TO BE BOUND AND ABIDE BY THESE TOS. IF
                  YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR
                  OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY
                  TO BIND SUCH ENTITY, ITS AFFILIATES AND ALL USERS (AS DEFINED
                  BELOW) WHO ACCESS THE SERVICE ON BEHALF OF SUCH COMPANY OR
                  ENTITY TO THESE TOS, IN WHICH CASE THE TERMS “YOU” OR “YOUR”
                  SHALL REFER TO SUCH ENTITY, ITS AFFILIATES AND USERS
                  ASSOCIATED WITH SUCH ENTITY. IF YOU DO NOT HAVE SUCH
                  AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TOS, YOU MAY NOT
                  USE THE SERVICES.
                </p>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">1. Acceptance of Terms</h3>
                <p>
                  Viralytic LLP provides its services to you through its web
                  site located at https://www.viralytic.com (the “Site”), or any
                  Viralytic LLP Application Programing Interfaces (“APIs”)
                  pursuant to these TOS. The “Service” includes (a) the Site,
                  (b) Viralytic INC’s online commerce optimization services and
                  related technologies, and (c) all software (including the
                  Software, as defined below), data, documentation, reports,
                  text, images, sounds, video, and content made available
                  through any of the foregoing (collectively referred to as the
                  “Content”). By entering into a Service order form, or other
                  ordering document, web-based or email-based ordering mechanism
                  or registration process (each an “Order”) or otherwise
                  downloading, accessing or using the Service, Licensee
                  unconditionally accepts and agrees to all of the terms of
                  these TOS. Licensee represents that it has the authority to
                  bind itself and its affiliates to the terms of these TOS, and,
                  accordingly, the term “Licensee” shall refer to such entity.
                  If Licensee is an individual using the Service, the terms
                  “Licensee” and “User” shall each apply to such individual
                  using the Service for the purposes of these TOS. Capitalized
                  terms not defined herein shall be given the meaning set forth
                  in the applicable Order. These TOS shall apply to all use by
                  Licensee and Users (as defined below) of the Service. “User”
                  means an individual who is authorized by Licensee to use the
                  Service, for whom Licensee (or Viralytic LLP at Licensee’s
                  request) has supplied a user identification and password
                  either manually or using a Non-Viralytic LLP Application (as
                  defined below). Users may include, for example, Licensee’s
                  employees, consultants, contractors and agents, and third
                  parties that Licensee transacts business on behalf of
                  Licensee. Viralytic LLP may change these TOS from time to time
                  by providing Licensee and Users at least thirty (30) days
                  notice either by emailing the email address associated with
                  Licensee’s or User’s account or by posting a notice on the
                  Service. The revised TOS shall become effective thirty (30)
                  days after Viralytic LLP posts the updated TOS on the Service
                  or e-mail’s Licensee and/or Users notice of a change to these
                  TOS. If any change to these TOS is not acceptable to a
                  Licensee or a User, such User shall stop using the Service or,
                  in the case of Licensee, shall send a cancellation e-mail to
                  support@viralytic.com
                </p>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">2. Evaluations</h3>
                <p>
                  From time to time, Licensee may be invited to try certain
                  products at no charge for a free trial or evaluation period or
                  if such products are not generally available to licensees
                  (collectively, “Evaluation Services”). Evaluation Services
                  shall be designated or identified as beta, pilot, evaluation,
                  trial or the like. Notwithstanding anything to the contrary,
                  Evaluation Services are licensed for Licensee’s internal
                  evaluation purposes only (and not for production use), are
                  provided “as is” without warranty or indemnity of any kind,
                  and may be subject to additional terms. Notwithstanding the
                  foregoing, Viralytic LLP may discontinue Evaluation Services
                  at any time at its sole discretion and may never make any
                  Evaluation Service generally available. Viralytic LLP shall
                  have no liability for any harm or damage arising out of or in
                  connection with any Evaluation Service.
                </p>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">
                  3. License and Restrictions; Licensee and User Obligations
                  with Regard to Use of the Service
                </h3>
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item">
                    License. Any software that may be made available by
                    Viralytic LLP in connection with the Service (“Software”)
                    contains proprietary and confidential information that is
                    protected by applicable intellectual property and other
                    laws. Subject to all the terms of these TOS and payment of
                    all fees described in an Order, during the Term (as defined
                    below) Viralytic LLP grants Licensee and each User a
                    non-sublicensable, non-exclusive, non-transferable license
                    to use the object code of any Software and Content solely in
                    connection with the Service and any terms and procedures
                    Viralytic LLP may prescribe from time to time.
                  </li>
                  <li className="list-group-item">
                    Restrictions. Subject to these TOS, Licensee and Users may
                    access and use the Service and Content only for lawful
                    purposes. All rights, title and interest in and to the
                    Service and its components, Content and all related
                    intellectual property rights shall remain with and belong
                    exclusively to Viralytic LLP. Licensee shall maintain the
                    copyright notice and any other notices that appear on the
                    Service on any copies and any media. Neither Licensee nor
                    any User shall (or allow any third party to) (i) modify,
                    reverse engineer or attempt to hack or otherwise discover
                    any source code or underlying ideas or algorithms of the
                    Service (except to the extent that applicable law prohibits
                    reverse engineering restrictions), (ii) sell, resell,
                    license, sublicense, provide, lease, lend, use for
                    timesharing or service bureau purposes or otherwise use or
                    allow others to use the Service or Content for the benefit
                    of any third party, (iii) use the Service or Content, or
                    allow the transfer, transmission, export, or re-export of
                    the Service or Content or portion thereof, in violation of
                    any export control laws or regulations administered by the
                    U.S. Commerce Department, OFAC, or any other government
                    agency, (iv) use the Service to store or transmit
                    infringing, libelous, or otherwise unlawful or tortious
                    material, or to store or transmit material in violation of
                    third-party privacy or intellectual property rights, (v) use
                    the Service to store or transmit Malicious Code (as defined
                    below), (vi) interfere with or disrupt the integrity or
                    performance of the Service or its components, (vii) attempt
                    to gain unauthorized access to the Service or its related
                    systems or networks, (viii) permit direct or indirect access
                    to or use of any Service or Content in a way that
                    circumvents a contractual usage limit, (ix) copy the Service
                    or any part, feature, function or user interface thereof,
                    access the Service in order to build a competitive product
                    or service or (x) use the Service for any purpose other than
                    as expressly licensed herein. “Malicious Code” means code,
                    files, scripts, agents or programs intended to do harm,
                    including, for example, viruses, worms, time bombs and
                    Trojan horses.
                  </li>
                  <li className="list-group-item">
                    Licensee Obligations with Regard to Use of the Service. Any
                    User of the Service must be thirteen (13) years old or older
                    to use the Service. Licensee shall (i) be responsible for
                    Users’ compliance with these TOS, (ii) be responsible for
                    the quality and legality of Licensee Content (as defined
                    below) and the means by which Licensee acquired Licensee
                    Content, (iii) use commercially reasonable efforts to
                    prevent unauthorized access to or use of the Service, and
                    notify Viralytic LLP promptly of any such unauthorized
                    access or use, (iv) use the Service only in accordance with
                    the Viralytic INC’ Service documentation and applicable laws
                    and government regulations, (v) comply with terms of service
                    of Non-Viralytic LLP Applications (as defined below) with
                    which Licensee uses the Service. Licensee and Users are
                    responsible for maintaining the security of User’s accounts
                    and passwords. Viralytic LLP cannot and shall not be liable
                    for any loss or damage from Licensee’s or any User’s failure
                    to comply with this security obligation. Licensee and Users
                    may not access the Service, if they are Viralytic INC’
                    direct competitor, except with Viralytic INC’s prior written
                    consent. In addition, Licensee and Users may not access the
                    Service for purposes of monitoring its availability,
                    performance or functionality, or for any other benchmarking
                    or competitive purposes. “Licensee Content” means all data,
                    software, information, text, audio files, graphic files,
                    content and other materials that you upload, post, deliver,
                    provide or otherwise transmit or store (hereafter
                    “post(ing)”) in connection with or relating to the Service
                    submitted by or for Licensee to the Service or collected and
                    processed by or for Licensee using the Service, excluding
                    Content and Non-Viralytic LLP Applications. “Non-Viralytic
                    LLP Applications” means a web-based or offline software
                    application that is provided by Licensee or a third party
                    and interoperates with the Service.
                  </li>
                  <li className="list-group-item">
                    Enforcement. Licensee shall ensure that all Users comply
                    with the terms and conditions of these TOS, including,
                    without limitation, with Licensee’s obligations set forth in
                    Sections 3(b) and 3(c). Licensee shall promptly notify
                    Viralytic LLP in writing of any suspected or alleged
                    violation of these TOS and shall cooperate with Viralytic
                    LLP with respect to: (i) investigation by Viralytic LLP of
                    any suspected or alleged violation of these TOS and (ii) any
                    action by Viralytic LLP to enforce these TOS. Viralytic LLP
                    may, in its sole discretion, suspend or terminate any User’s
                    access to the Service with or without written notice
                    (including, but not limited to e-mail) to Licensee in the
                    event that Viralytic LLP reasonably determines that a User
                    has violated these TOS. Licensee shall be liable for any
                    violation of these TOS by any User.
                  </li>
                </ul>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">
                  4. Provision of the Service; Support; APIs
                </h3>
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item">
                    As part of the registration process, each User shall
                    generate a user name and password for its Viralytic LLP
                    account (“Account”) either manually or through a
                    Non-Viralytic LLP Application (e.g., OAuth). Each User is
                    responsible for maintaining the confidentiality of their
                    login, password and Account and for all activities that
                    occur under any such logins or the Account. Viralytic LLP
                    reserves the right to access Licensee’s and any User’s
                    Account in order to respond to Licensee’s and Users’
                    requests for technical support. Viralytic LLP has the right,
                    but not the obligation, to monitor the Service, Content, or
                    Licensee Content. Licensee further agrees that Viralytic LLP
                    may remove or disable any Content at any time for any reason
                    (including, but not limited to, upon receipt of claims or
                    allegations from third parties or authorities relating to
                    such Content), or for no reason at all.
                  </li>
                  <li className="list-group-item">
                    Viralytic LLP shall (a) make the Service available to
                    Licensee and its Users pursuant to these TOS and the
                    applicable Order, (b) provide community-based support via
                    Viralytic INC’s support site located at
                    https://www.viralytic.com (or a successor URL) for the
                    Service to Licensee and Users at no additional charge,
                    and/or upgraded support if purchased pursuant to an Order,
                    and (c) use commercially reasonable efforts to make the
                    Service available 24 hours a day, 7 days a week, except for:
                    (i) planned downtime (of which Viralytic LLP shall give at
                    least eight (8) hours electronic notice and which Viralytic
                    LLP shall schedule to the extent practicable during the
                    weekend hours between 6:00 p.m. Friday and 3:00 a.m. Monday
                    Pacific time), and (ii) any unavailability caused by
                    circumstances beyond Viralytic INC’s reasonable control,
                    including, for example, an act of God, act of government,
                    flood, fire, earthquake, civil unrest, act of terror, strike
                    or other labor problem (other than one involving Viralytic
                    INC’s employees), Internet service provider failure or
                    delay, Non-Viralytic LLP Application, or denial of service
                    attack.
                  </li>
                  <li className="list-group-item">
                    Licensee understands that the operation of the Service,
                    including Licensee Content, may be unencrypted and involve
                    (i) transmissions over various networks; (ii) changes to
                    conform and adapt to technical requirements of connecting
                    networks or devices and (iii) transmission to Viralytic
                    INC’s third-party vendors and hosting partners solely to
                    provide the necessary hardware, software, networking,
                    storage, and related technology required to operate and
                    maintain the Service. Accordingly, Licensee acknowledges
                    that Licensee bears sole responsibility for adequate backup
                    of Licensee Content. Viralytic LLP shall have no liability
                    to Licensee for any unauthorized access or use of any of
                    Licensee Content, or any corruption, deletion, destruction
                    or loss of any of Licensee Content.
                  </li>
                  <li className="list-group-item">
                    Licensee and its Users may enable or log in to the Service
                    via various Non-Viralytic LLP Applications, such as GitHub.
                    By logging in or directly integrating these Non-Viralytic
                    LLP Applications into the Service, Viralytic LLP make Users’
                    online experiences richer and more personalized. To take
                    advantage of this feature and capabilities, Viralytic LLP
                    may ask Users to authenticate, register for or log into
                    Non-Viralytic LLP Applications on the websites of their
                    respective providers. As part of such integration, the
                    Non-Viralytic LLP Applications shall provide Viralytic LLP
                    with access to certain information that Users have provided
                    to such Non-Viralytic LLP Applications, and Viralytic LLP
                    shall use, store and disclose such information in accordance
                    with Viralytic INC’s Privacy Policy located at
                    https://www.viralytic.com/privacy-policy . The manner in
                    which Non-Viralytic LLP Applications use, store and disclose
                    Licensee and User information is governed solely by the
                    policies of the third parties operating the Non-Viralytic
                    LLP Applications, and Viralytic LLP shall have no liability
                    or responsibility for the privacy practices or other actions
                    of any third party site or service that may be enabled
                    within the Service. In addition, Viralytic LLP is not
                    responsible for the accuracy, availability or reliability of
                    any information, content, goods, data, opinions, advice or
                    statements made available in connection with Non-Viralytic
                    LLP Applications. As such, Viralytic LLP shall not be liable
                    for any damage or loss caused or alleged to be caused by or
                    in connection with use of or reliance on any such
                    Non-Viralytic LLP Applications. Viralytic LLP enables these
                    features merely as a convenience and the integration or
                    inclusion of such features does not imply an endorsement or
                    recommendation.
                  </li>
                  <li className="list-group-item">
                    Licensee shall be responsible for obtaining and maintaining
                    any equipment and ancillary services needed to connect to,
                    access or otherwise use the Service, including, without
                    limitation, modems, hardware, server, software, operating
                    system, networking, web servers, long distance and local
                    telephone service (collectively, “Equipment”). Licensee
                    shall be responsible for ensuring that such Equipment is
                    compatible with the Service (and, to the extent applicable,
                    the Software) and complies with all configurations and
                    specifications set forth in Viralytic INC’s published
                    policies then in effect. Licensee shall also be responsible
                    for maintaining the security of the Equipment, Licensee’s
                    Account, all passwords (including but not limited to
                    administrative and User passwords) and files, and for all
                    uses of Licensee Account or the Equipment with or without
                    Licensee’s knowledge or consent.
                  </li>
                  <li className="list-group-item">
                    In the event that Licensee shall desire to use any Viralytic
                    LLP APIs, Licensee’s use of any Viralytic INC’s APIs shall
                    be governed by the Viralytic LLP API License & Terms of Use
                    located at https://www.viralytic.com/terms-conditions-api.
                  </li>
                  <li className="list-group-item">
                    Licensee shall be solely responsible for the use of the
                    Viralytic LLP services on its web sites or other in any
                    other applications.
                  </li>
                </ul>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">5. Fees and Payment</h3>
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item">
                    Licensee shall pay Viralytic LLP the fees set forth in an
                    Order in accordance with the terms set forth therein;
                    provided that Viralytic LLP may change any applicable fees
                    upon thirty (30) days’ notice at any time and such new fees
                    shall become effective for any subsequent renewal Term. All
                    payments shall be made in U.S. dollars. Any payments more
                    than thirty (30) days overdue shall bear a late payment fee
                    of one and one-half percent (1.5%) per month, or, if lower,
                    the maximum rate allowed by law. In addition, Licensee
                    shall, except for tax on Viralytic INC’s property or net
                    income, if any, imposed by taxation authorities on amounts
                    payable to Viralytic LLP hereunder, pay all national,
                    federal, state, local or other taxes and assessments of any
                    jurisdiction, including but not limited to sales or use
                    taxes, value-added taxes, duties, customs or other import or
                    export taxes or duties, other governmental assessments, and
                    amounts levied in lieu thereof based on charges set,
                    services performed or payments made hereunder, as are now or
                    hereafter may be imposed under the authority of any
                    national, federal, state, local or other taxing
                    jurisdiction., as well as all pre-approved out of pocket
                    expenses incurred by Viralytic LLP in connection with any
                    consulting and/or support services, promptly upon invoice.
                    If Licensee is paying any fees by credit card, Licensee
                    shall provide Viralytic LLP complete and accurate
                    information regarding the applicable credit card. Licensee
                    represents and warrants that all such information is correct
                    and that Licensee is authorized to use such credit card.
                    Licensee shall promptly update its account information with
                    any changes (for example, a change in billing address or
                    credit card expiration date) that may occur. Licensee hereby
                    authorizes Viralytic LLP to bill such credit card in advance
                    on a periodic basis in accordance with the terms ofthese TOS
                    and each Order, and Licensee further agrees to pay any
                    charges so incurred. Licensee shall maintain, and Viralytic
                    LLP shall be entitled to audit, any records relevant to
                    Licensee’s use of the Service hereunder. Viralytic LLP may
                    audit such records on reasonable notice at Viralytic INC’s
                    cost (or if the audits reveal material non-compliance with
                    these TOS, at Licensee’s cost).
                  </li>
                  <li className="list-group-item">
                    For any upgrade in a subscription level for a month-to-month
                    service plan, Viralytic LLP shall automatically charge
                    Licensee the new subscription fee, effective as of the date
                    the service upgrade is requested and for each subsequent
                    one-month recurring cycle pursuant to the billing method
                    applicable to Licensee. If Viralytic LLP is providing
                    Licensee the Service pursuant to a yearly service plan,
                    Viralytic LLP shall immediately charge Licensee any increase
                    in subscription level plan cost pursuant to the billing
                    method applicable to Licensee, prorated for the remaining
                    Term of Licensee’s yearly billing cycle; provided, however,
                    any decrease in a subscription level plan cost shall only
                    take effect upon the renewal date of the then current yearly
                    service plan. Licensee’s downgrading its subscription level
                    may cause the loss of features or capacity of Licensee’s
                    Account. Viralytic LLP does not accept any liability for
                    such loss.
                  </li>
                  <li className="list-group-item">
                    If any amount owing by Licensee under these TOS for the
                    Service is thirty (30) or more days overdue (or ten (10) or
                    more days overdue in the case of amounts Licensee has
                    authorized Viralytic LLP to charge to Licensee’s credit
                    card), Viralytic LLP may, in its sole discretion and without
                    limiting its other rights and remedies, suspend Licensee’s
                    and any User’s access to the Service and/or otherwise limit
                    the functionality of the Service until such amounts are paid
                    in full.
                  </li>
                  <li className="list-group-item">
                    Licensee agrees that its purchases are not contingent on the
                    delivery of any future functionality or features, or
                    dependent on any oral or written public comments made by
                    Viralytic LLP regarding future functionality or features.
                  </li>
                  <li className="list-group-item">
                    Viralytic LLP. offers a full money-back guarantee for all
                    purchases made on its website. If you are not satisfied with
                    the product that you have purchased, you can get your money
                    back no questions asked. You are eligible for a full refund
                    within 7 calendar days of your paid subscription.
                  </li>
                  <li className="list-group-item">
                    After the 7-day period, you will no longer be eligible and
                    won't be able to receive a refund. Viralytic LLP. encourages
                    customers to try the software in the first 7 days after
                    their purchase to ensure it fits their needs.
                  </li>
                  <li className="list-group-item">
                    If you would like to request a refund, feel free to contact
                    support@viralytic.com. All refunds are processed into the
                    original payment method. it may take 5 to 7 business days
                    for the refund to show on your payment card statement.
                  </li>
                </ul>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">6. Term Expiration and Termination</h3>
                <p>
                  These TOS shall continue in effect for the term and any
                  renewal term as specified in an Order (collectively, the
                  “Term”). If either party materially breaches these TOS, the
                  other party shall have the right to terminate the applicable
                  Order and in the case where no Order exists, these TOS (and,
                  in each case, all licenses granted herein) upon thirty (30)
                  days (ten (10) days in the case of non-payment and immediately
                  in the case of a breach of Sections 3(b)) written notice of
                  any such breach, unless such breach is cured during such
                  notice period. In the case of a free trial or Viralytic LLP
                  otherwise providing the Service at no cost to a Licensee,
                  Viralytic LLP shall have, upon Licensee or any Users failing
                  to use the Service for more than six (6) consecutive months,
                  the right, in its sole discretion, to terminate all User
                  Accounts of Licensee and terminate Licensee’s and all
                  Licensee’s Users’ access to and use of the Service without
                  notice. Upon expiration or termination of an Order or these
                  TOS, Licensee shall immediately be unable access and use the
                  Service, all Licensee Content may be deleted from the Service
                  at Viralytic INC’s sole discretion (such information cannot be
                  recovered once Licensee’s Account or any User Account is
                  terminated) and Licensee shall return or destroy all copies of
                  all Content and all portions thereof in Licensee’s possession
                  and so certify to Viralytic LLP, if such certification is
                  requested by Viralytic LLP. Sections 3(b) and 5 through 14 of
                  these TOS, shall survive termination or expiration of these
                  TOS. Termination is not an exclusive remedy and all other
                  remedies shall be available whether or not termination occurs.
                </p>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">7. Indemnification</h3>
                <p>
                  Licensee and each User shall defend, indemnify and hold
                  harmless Viralytic LLP from all damages, settlements,
                  attorneys’ fees and expenses related to any third-party claim,
                  suite or demand (i) arising from Licensee or any User’s use of
                  the Service or Content in breach of these TOS or in violation
                  of applicable law, or (ii) alleging that the Licensee Content
                  infringes or misappropriates such third party’s intellectual
                  property rights or violates applicable law; provided Licensee
                  is promptly notified of any and all threats, suits, claims and
                  proceedings related thereto and given reasonable assistance by
                  Viralytic LLP (at Licensee’s cost). Viralytic LLP reserves the
                  right to assume the exclusive defense and control of any
                  matter that is subject to indemnification under this Section
                  7. In such case, Licensee and Users agree to cooperate with
                  any reasonable requests in assisting Viralytic INC’s defense
                  of such matter.
                </p>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">8. DISCLAIMER OF WARRANTIES</h3>
                <p>
                  THE SERVICE MAY BE TEMPORARILY UNAVAILABLE FOR SCHEDULED
                  MAINTENANCE OR FOR UNSCHEDULED EMERGENCY MAINTENANCE, EITHER
                  BY Viralytic LLP OR BY THIRD-PARTY PROVIDERS, OR BECAUSE OF
                  OTHER CAUSES BEYOND Viralytic INC’S REASONABLE CONTROL, BUT
                  Viralytic LLP SHALL USE REASONABLE EFFORTS TO PROVIDE ADVANCE
                  NOTICE IN WRITING OR BY EMAIL OF ANY SCHEDULED SERVICE
                  DISRUPTION. HOWEVER, THE SERVICE, INCLUDING THE SITE AND
                  CONTENT, AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON
                  AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF
                  ANY KIND, AND Viralytic LLP EXPRESSLY DISCLAIMS ANY AND ALL
                  WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                  LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE,
                  FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.
                  LICENSEE AND USERS ACKNOWLEDGE THAT Viralytic LLP DOES NOT
                  WARRANT THAT THE SERVICE SHALL BE UNINTERRUPTED, TIMELY,
                  SECURE, ERROR-FREE OR VIRUS-FREE, NOR DOES Viralytic LLP MAKE
                  ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE
                  OF THE SERVICE, AND NO INFORMATION, ADVICE OR SERVICES
                  OBTAINED BY LICENSEE OR USERS FROM Viralytic LLP OR THROUGH
                  THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
                  THESE TOS.
                </p>
                <p>
                  VIRALYTIC LLP. CANNOT GUARANTEE THE VALIDITY OF THE SALES AND
                  DATA GENERATED BY THE SOFTWARE AS THESE ARE BASED ON AN
                  ALGORITHM. WHILE WE USE REASONABLE EFFORTS TO INCLUDE ACCURATE
                  AND UP-TO-DATE INFORMATION, WE MAKE NO WARRANTIES AS TO THE
                  ACCURACY OF THE CONTENT AND ASSUME NO LIABILITY OR
                  RESPONSIBILITY FOR THE ACCURACY OF THE SALES AND DATA
                  GENERATED BY THE SOFTWARE.
                </p>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">9. LIMITATION OF LIABILITY</h3>
                <p>
                  UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
                  CONTRACT, TORT OR OTHERWISE) SHALL Viralytic LLP BE LIABLE TO
                  LICENSEE, ANY USER OR ANY THIRD-PARTY FOR (A) ANY INDIRECT,
                  INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE
                  DAMAGES. INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST
                  DATA, GOODWILL, OR OTHER INTANGIBLE LOSSES OR (B) FOR ANY
                  DIRECT DAMAGES, COST, LOSSES OR LIABILITIES IN EXCESS OF THE
                  FEES ACTUALLY PAID BY LICENSEE IN THE SIX (6) MONTHS PRECEDING
                  THE EVENT GIVING RISE TO LICENSEE’S OR USER’S CLAIM OR, IF NO
                  FEES APPLY, ONE HUNDRED DOLLARS (US$100). THE PROVISIONS OF
                  THIS SECTION ALLOCATE THE RISK UNDER THESE TOS BETWEEN THE
                  PARTIES AND PARTIES HAVE RELIED ON THESE LIMITATIONS IN
                  DETERMINING WHETHER TO ENTER INTO THESE TOS. SOME STATES DO
                  NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTEES OR LIMITATION OF
                  LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, WHICH MEANS
                  THAT SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO LICENSEE
                  OR SOME USERS. IN SUCH STATES, Viralytic INC’S LIABILITY SHALL
                  BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
                </p>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">
                  10. Proprietary Rights; Limited License to Viralytic LLP;
                  Feedback
                </h3>
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item">
                    Reservation of Rights. Subject to the limited rights
                    expressly granted hereunder, Viralytic LLP and its licensors
                    reserve all of Viralytic INC’s and its licensors right,
                    title and interest in and to the Service, including all of
                    Viralytic INC’s and its licensors related intellectual
                    property rights. No rights are granted to Licensee hereunder
                    other than as expressly set forth herein.
                  </li>
                  <li className="list-group-item">
                    License to Viralytic LLP by Licensee to Host Licensee
                    Content. Licensee hereby grants Viralytic LLP a worldwide,
                    non-exclusive, royalty-free, fully paid, sublicensable,
                    limited-term license to host, copy, transmit and display
                    Licensee Content that Licensee or any User posts to the
                    Service, solely as necessary for Viralytic LLP to provide
                    the Service in accordance with these TOS. Subject to the
                    limited licenses granted herein, Viralytic LLP acquires no
                    right, title or interest from Licensee or Licensee’s
                    licensors under these TOS in or to Licensee Content.
                  </li>
                  <li className="list-group-item">
                    License by Licensee to Use Feedback. Licensee hereby grants
                    to Viralytic LLP a worldwide, perpetual, irrevocable,
                    royalty-free license to use and incorporate into the Service
                    any suggestion, enhancement request, recommendation,
                    correction or other feedback provided by Licensee or Users
                    relating to the operation of the Service.
                  </li>
                </ul>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">11. Confidentiality</h3>
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item">
                    Definition of Confidential Information. “Confidential
                    Information” means all information disclosed by a party
                    (“Disclosing Party”) to the other party (“Receiving Party”),
                    whether orally or in writing, that is designated as
                    confidential or that reasonably should be understood to be
                    confidential given the nature of the information and the
                    circumstances of disclosure. Confidential Information of
                    each party includes the terms and conditions of this
                    Agreement and all Order Forms (including pricing), as well
                    as code, inventions, know how, business and marketing plans,
                    technology and technical information, financial information,
                    product plans and designs, and business processes disclosed
                    by such party. Confidential Information shall not include
                    any information that (i) is or becomes generally known to
                    the public without breach of any obligation owed to the
                    Disclosing Party, (ii) was known to the Receiving Party
                    prior to its disclosure by the Disclosing Party without
                    breach of any obligation owed to the Disclosing Party, (iii)
                    is received from a third party without breach of any
                    obligation owed to the Disclosing Party, or (iv) was
                    independently developed by the Receiving Party without
                    reference to or reliance on the Disclosing Party’s
                    Confidential Information, as demonstrated by documents or
                    files in existence at the time of the confidential
                    disclosure.
                  </li>
                  <li className="list-group-item">
                    Protection of Confidential Information. The Receiving Party
                    shall use the same degree of care that it uses to protect
                    the confidentiality of its own confidential information of
                    like kind (but not less than reasonable care) (i) not to use
                    any Confidential Information of the Disclosing Party for any
                    purpose outside the scope of this Agreement, and (ii) except
                    as otherwise authorized by the Disclosing Party in writing,
                    to limit access to Confidential Information of the
                    Disclosing Party to those of its and its employees and
                    contractors who need that access for purposes consistent
                    with this Agreement and who have signed confidentiality
                    agreements with the Receiving Party containing protections
                    no less restrictive than those herein. Neither party shall
                    disclose the terms of any Order Form to any third party
                    other than its legal counsel and accountants without the
                    other party’s prior written consent, provided that a party
                    that makes any such disclosure to its legal counsel or
                    accountants shall remain responsible for such legal
                    counsel’s or accountant’s compliance with this Section
                    11(b).
                  </li>
                  <li className="list-group-item">
                    Compelled Disclosure. The Receiving Party may disclose
                    Confidential Information of the Disclosing Party to the
                    extent compelled by law to do so, provided the Receiving
                    Party gives the Disclosing Party prior notice of the
                    compelled disclosure (to the extent legally permitted) and
                    reasonable assistance, at the Disclosing Party’s cost, if
                    the Disclosing Party wishes to contest the disclosure. If
                    the Receiving Party is compelled by law to disclose the
                    Disclosing Party’s Confidential Information as part of a
                    civil proceeding to which the Disclosing Party is a party,
                    and the Disclosing Party is not contesting the disclosure,
                    the Disclosing Party shall reimburse the Receiving Party for
                    its reasonable cost of compiling and providing secure access
                    to that Confidential Information. In the event that such
                    protective order or other remedy is not obtained, the
                    Receiving Party shall furnish only that portion of the
                    Confidential Information that is legally required and use
                    commercially reasonable efforts to obtain assurance that
                    confidential treatment shall be accorded the Confidential
                    Information.
                  </li>
                </ul>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">12. DMCA Copyright Policy</h3>
                <p>
                  Viralytic LLP has adopted the following policy toward
                  copyright infringement on the Service in accordance with the
                  Digital Millennium Copyright Act (the “DMCA”). The address of
                  Viralytic INC’s Designated Agent for copyright takedown
                  notices (“Designated Agent”) is listed below.
                </p>
                <p>Reporting Instances of Copyright Infringement:</p>
                <p>
                  If you believe that Content residing or accessible on or
                  through the Service infringes a copyright, please send a
                  notice of copyright infringement containing the following
                  information to the Designated Agent at the address below:
                </p>
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item">
                    Identification of the work or material being infringed
                  </li>
                  <li className="list-group-item">
                    Identification of the material that is claimed to be
                    infringing, including its location, with sufficient detail
                    so that Viralytic LLP is capable of finding it and verifying
                    its existence
                  </li>
                  <li className="list-group-item">
                    Contact information for the notifying party (the “Notifying
                    Party”), including name, address, telephone number, and
                    email address.
                  </li>
                  <li className="list-group-item">
                    A statement that the Notifying Party has a good faith belief
                    that the material is not authorized by the copyright owner,
                    its agent or law.
                  </li>
                  <li className="list-group-item">
                    A statement made under penalty of perjury that the
                    information provided in the notice is accurate and that the
                    Notifying Party is authorized to make the complaint on
                    behalf of the copyright owner.
                  </li>
                  <li className="list-group-item">
                    A physical or electronic signature of a person authorized to
                    act on behalf of the owner of the copyright that has been
                    allegedly infringed.
                  </li>
                </ul>
                <p>
                  Please also note that the information provided in a notice of
                  copyright infringement may be forwarded to the Subscriber who
                  posted the allegedly infringing content. After removing
                  material pursuant to a valid DMCA notice, Viralytic LLP shall
                  immediately notify the Subscriber responsible for the
                  allegedly infringing material that it has removed or disabled
                  access to the material. Viralytic LLP shall terminate, under
                  appropriate circumstances, the Accounts of Subscribers who are
                  repeat copyright infringers, and reserves the right, in its
                  sole discretion, to terminate any Subscriber for actual or
                  apparent copyright infringement.
                </p>
                <p>Submitting a DMCA Counter-Notification:</p>
                <p>
                  If you believe you are the wrongful subject of a DMCA
                  notification, you may file a counter-notification with
                  Viralytic LLP by providing the following information to the
                  Designated Agent at the address below:
                </p>
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item">
                    The specific URLs of material that Viralytic LLP has removed
                    or to which Viralytic LLP has disabled access.
                  </li>
                  <li className="list-group-item">
                    Your name, address, telephone number, and email address.
                  </li>
                  <li className="list-group-item">
                    A statement that you consent to the exclusive jurisdiction
                    of the United States District Court for the District of
                    Delaware, and that you shall accept service of process from
                    the person who provided the original DMCA notification or an
                    agent of such person.
                  </li>
                  <li className="list-group-item">
                    The following statement: “I swear, under penalty of perjury,
                    that I have a good faith belief that the material was
                    removed or disabled as a result of a mistake or
                    misidentification of the material to be removed or
                    disabled.”
                  </li>
                  <li className="list-group-item">Your signature.</li>
                </ul>
                <p>
                  Upon receipt of a valid counter-notification, Viralytic LLP
                  shall forward it to Notifying Party who submitted the original
                  DMCA notification. The original Notifying Party (or the
                  copyright holder he or she represents) shall then have ten
                  (10) days to notify us that he or she has filed legal action
                  relating to the allegedly infringing material. If Viralytic
                  LLP does not receive any such notification within ten (10)
                  days, Viralytic LLP may restore the material to the Service.
                </p>
                <p>
                  In accordance with the DMCA and other applicable law,
                  Viralytic LLP has adopted a policy of terminating access to
                  the Service, and in appropriate circumstances and at Viralytic
                  INC’s sole discretion, for Users who are deemed to be repeat
                  infringers. Viralytic LLP may also and at its sole discretion
                  limit access to the Site and/or terminate the access of Users
                  who infringe any intellectual property rights of others,
                  whether or not there is any repeat infringement.
                </p>
              </section>
              <br />

              <section className="mb-4">
                <h3 className="mb-3">13. Miscellaneous</h3>
                <ul className="list-group list-group-flush mb-3">
                  <li className="list-group-item">
                    Assignment. Neither the TOS nor the licenses granted
                    hereunder are assignable or transferable by either party
                    without the prior written consent of the other party (which
                    consent shall not be unreasonably withheld, conditioned or
                    delayed); provided, however, that either party may assign
                    and transfer these TOS without consent to a successor to all
                    or substantially all of its assets or business; any attempt
                    to assign in violation of the foregoing shall be void. There
                    are no third-party beneficiaries under these TOS.
                  </li>
                  <li className="list-group-item">
                    Independent Contractors. The parties are independent
                    contractors. These TOS does not create a partnership,
                    franchise, joint venture, agency, fiduciary or employment
                    relationship between the parties.
                  </li>
                  <li className="list-group-item">
                    Waivers. No failure or delay in exercising any right
                    hereunder shall operate as a waiver thereof, nor shall any
                    partial exercise of any right or power hereunder preclude
                    further exercise. If any provision of these TOS shall be
                    adjudged by any court of competent jurisdiction to be
                    unenforceable or invalid, that provision shall be limited or
                    eliminated to the minimum extent necessary so that these TOS
                    shall otherwise remain in full force and effect and
                    enforceable.
                  </li>
                  <li className="list-group-item">
                    THIS SECTION CONTAINS A BINDING CLASS ACTION WAIVER. IT
                    AFFECTS YOUR RIGHTS ABOUT HOW TO RESOLVE ANY DISPUTE WITH
                    US.
                  </li>
                  <li className="list-group-item">
                    Informal Process First. You agree that in the event of any
                    dispute between you and the Company, you will first contact
                    the Company and make a good faith sustained effort to
                    resolve the dispute before resorting to more formal means of
                    resolution, including without limitation, any court action.
                  </li>
                  <li className="list-group-item">
                    The laws of the State of Delaware, without regard to
                    conflicts of law principles thereof, will apply to all
                    matters related to the Services and this Agreement. The
                    parties submit to the exclusive legal jurisdiction and
                    venues of the Courts of Lewes County, Delaware.
                  </li>
                  <li className="list-group-item">
                    You may not assign your rights or obligations hereunder, and
                    any attempt by you to sublicense, assign or transfer any of
                    the rights, duties or obligations hereunder or to exceed the
                    scope of this Agreement is void. In the event that Company
                    is sold to a third party, such a sale will not be deemed a
                    transfer of personal information so long as that third party
                    agrees to assume Company’s obligations under this Agreement.
                  </li>
                  <li className="list-group-item">
                    If you are a California resident, in accordance with Cal.
                    Civ. Code § 1789.3, you may report complaints to the
                    Complaint Assistance Unit of the Division of Consumer
                    Services of the California Department of Consumer Affairs by
                    contacting them in writing at 1625 North Market Blvd., Suite
                    N 112 Sacramento, CA 95834, or by telephone at (800)
                    952-5210.
                  </li>
                  <li className="list-group-item">
                    This is the entire agreement between you and Company with
                    regard to the matters described herein and govern your use
                    of the Services, superseding any prior agreements between
                    you and Company with respect thereto. The failure of Company
                    to exercise or enforce any right or provision of this
                    Agreement shall not constitute a waiver of such right or
                    provision. If any provision of this Agreement is found by a
                    court of competent jurisdiction to be invalid, the parties
                    nevertheless agree that the court should endeavor to give
                    effect to the parties’ intentions as reflected in the
                    provision, and the other provisions hereof shall remain in
                    full force and effect. You agree that regardless of any
                    statute or law to the contrary, any claim or cause of action
                    arising out of this Agreement or related to use of the
                    Services must be filed within six (6) months after such
                    claim or cause of action arose or be forever barred.
                  </li>
                  <li className="list-group-item">
                    Data Processed in the United States. The Service is
                    controlled and offered by Viralytic LLP from its facilities
                    in the United States, and the Viralytic LLP servers and data
                    centers are located in the United States. If Licensee
                    chooses to access and use the Service from outside the
                    United States, then Licensee and all Users acknowledge that
                    Licensee and Users are transferring Licensee Content into
                    the United States for storage and processing and that
                    Licensee is responsible for compliance with all local laws
                    applicable to such transfer, storage and processing.
                  </li>
                  <li className="list-group-item">
                    Amendments; Waivers; Complete Agreement. Any waivers or
                    amendments shall be effective only if made in writing. These
                    TOS and each Order is the complete and exclusive statement
                    of the mutual understanding of the parties and supersedes
                    and cancels all previous written and oral agreements and
                    communications relating to the subject matter of these TOS.
                    Notwithstanding any language to the contrary therein, no
                    terms or conditions stated in a Licensee purchase order or
                    in any other Licensee order documentation shall be
                    incorporated into or form any part of this Agreement, and
                    all such terms or conditions shall be null and void.
                  </li>
                  <li className="list-group-item">
                    Government Licensee Provisions. Government technical data
                    and software rights related to the Services include only
                    those rights customarily provided to the public as set forth
                    in this Agreement. The Service is “commercial computer
                    software” and “commercial computer software documentation as
                    such terms are used in FAR 12.212, DFARS 252.227-7014 and
                    DFARS 227.7202.
                  </li>
                  <li className="list-group-item">
                    We shall not be liable for any circumstances arising out of
                    causes beyond our reasonable control or without our fault or
                    negligence, including, but not limited to, Acts of God, acts
                    of civil or military authority, pandemic, epidemic, fires,
                    riots, wars, embargoes, Internet disruptions, hacker
                    attacks, or communications failures, or other force majeure.
                  </li>
                  <li className="list-group-item">
                    Notices. Except as otherwise specified in these TOS, all
                    notices, permissions and approvals hereunder shall be in
                    writing and shall be deemed to have been given upon: (i)
                    personal delivery, the second business day after mailing,
                    (iii) the second business day after sending by confirmed
                    facsimile, or (iv) the first business day after sending by
                    email (provided email shall not be sufficient for notices of
                    termination or an indemnifiable claim). Billing-related
                    notices to Licensee shall be addressed to the relevant
                    billing contact designated by Licensee on the applicable
                    Order. All other notices to Licensee shall be addressed to
                    the relevant contact designated by Licensee on the
                    applicable Order. All notices to Viralytic LLP shall be sent
                    to Viralytic LLP to the applicable Viralytic LLP contact at
                    the address set forth on the applicable Order.
                  </li>
                </ul>
              </section>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
