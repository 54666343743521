import "bootstrap-daterangepicker/daterangepicker.css";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Button, Card, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Select from "react-select";
import '../../../../config';
import { Context } from '../../../../MasterLayout';
import Axios from "../../../plugins/axios";
import Conversion from "../../CommonComponents/Conversion";
import Export from "../../CommonComponents/export";
import InfiniteScrollWrapper from "../InfiniteScrollWrapper";
import RangeSlider from "../RangeSlider";

const RelevantInfluencerstab = forwardRef((props, ref) => {
   const { t } = useTranslation();

   const { dispatch } = useContext(Context);
   const params = useParams();
   const [page, setPage] = useState(0);
   const [onLoad, setOnLoad] = useState(false);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [salesRange, setSalesRange] = useState('');
   const [followersRange, setFollowersRange] = useState('');
   const [contactInfo, setContactInfo] = useState('');
   const [favourite, setFavourite] = useState([]);
   const [sorting, setSorting] = useState({ sort: 18, sort_type: 'DESC' });
   const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
   const [payload, setPayload] = useState({});

   const tableHead = [
      {
         title: '#',
         className: 'text-right',
      },
      {
         title: t('common.influencers'),
      },
      {
         title: t('common.revenue'),
         className: 'text-right sorting',
         sort: 18,
      },
      {
         title: t('common.sales'),
         className: 'text-right sorting',
         sort: 19,
      },
      {
         title: t('common.atv'),
         className: 'text-right sorting',
         sort: 20,
      },
      {
         title: t('common.relevant_br_live'),
         className: 'text-right sorting',
         sort: 59,
      },
      {
         title: t('common.videos'),
         className: 'text-right sorting',
         sort: 55,
      },
      {
         title: t('product_research.product_details.average_br_views'),
         className: 'text-right sorting',
         sort: 16,
      },
      {
         title: t('common.followers'),
         className: 'text-right sorting',
         sort: 1,
      },
      {
         title: t('common.favorite'),
         className: 'text-right',
         sort: 26,
      }
   ];

   useImperativeHandle(ref, () => ({
      async relevantInfluencersTab() {
         if (onLoad == false) {
            setHasMore(true);
            setPage(0)
            relevantInfluencers(0);
         }
         setOnLoad(true);
      }
   }));

   useEffect(() => {
      if (onLoad == true) {
         setHasMore(true);
         setPage(0);
         relevantInfluencers(0);
      }
   }, [sorting, salesRange, followersRange, contactInfo]);

   const relevantInfluencers = async (page) => {
      try {
         let data = {
            sort: sorting.sort,
            page: page,
            sort_type: sorting.sort_type,
            product_id: params.productId,
            country_code: props.product.country.country_code || ''
         }
         if (salesRange.from) {
            data['sold_count_start'] = salesRange.from;
         };
         if (salesRange.to && salesRange.to != '') {
            data['sold_count_end'] = salesRange.to;
         };
         if (followersRange.from) {
            data['fan_start'] = followersRange.from;
         };
         if (followersRange.to && followersRange.to != '') {
            data['fan_end'] = followersRange.to;
         };
         if (contactInfo) {
            data['contact_type'] = contactInfo;
         };
         setPayload(data);
         let response = await Axios.post('/api/relevant-influencers', data);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.influencer_id) : favourite;
            if (page == 0) {
               setItems(response.data.data);
               setFavourite(array);
            } else {
               setItems(items.concat(response.data.data));
               setFavourite(favourite.concat(array));
            }
            setHasMore(response?.data?.data?.length === 20);
         } else {
            setHasMore(false);
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }
   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      }
   };
   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         relevantInfluencers(number);
      }
   }

   const contactArray = [
      { value: 0, label: 'Email' },
      { value: 1, label: 'Zalo' },
      { value: 2, label: 'Whatsapp' },
      { value: 3, label: 'Instagram' },
      { value: 4, label: 'Twitter' },
      { value: 5, label: 'Youtube' }
   ];

   const handleContactInfo = async (e) => {
      let contacts = [];
      if (e.length != 0) {
         e.map((v) => { contacts.push(v.value) })
         setContactInfo(contacts.join(','))
      } else {
         setContactInfo('')
      };
   };

   const CustomClearText = () => "clear all";
   const ClearIndicator = (props) => {
      const {
         children = <CustomClearText />,
         getStyles,
         innerProps: { ref, ...restInnerProps },
      } = props;
      return (
         <div
            {...restInnerProps}
            ref={ref}
            style={getStyles("clearIndicator", props)}
         >
            <div style={{ padding: "0px 5px" }}>{children}</div>
         </div>
      );
   };

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-influencer', data);
      const index = favourite.indexOf(data.influencer_id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.influencer_id);
      }

      setFavourite(favourite.concat([]));
   };

   return (
      <Card className='rounded-0'>
         <Card.Body>
            <div className='row'>
               <div className="col-lg-12">
                  <h3>{t('product_research.product_details.influencers_list')}</h3>
               </div>
               <div className='col-lg-12'>
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('common.sales')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'sales'} step={10} handleUpdate={(e) => { setSalesRange(e) }} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('common.followers')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'followers'} step={10} handleUpdate={(e) => { setFollowersRange(e) }} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu btn-xs">
                        <Select
                           closeMenuOnSelect={false}
                           placeholder="Contact Infomation"
                           components={{ ClearIndicator }}
                           isMulti
                           options={contactArray}
                           onChange={(e) => { handleContactInfo(e) }}
                        />
                     </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mx-2 my-3">
                     <Export payload={payload} data_source="Relevant Influencers" type="Product" />
                  </div>
               </div>
            </div>
            <Table responsive className="w-100">
               <div id="example_wrapper" className="dataTables_wrapper">
                  <InfiniteScrollWrapper
                     isInfiniteScrollOn={true}
                     lengthData={items.length}
                     functionNext={functionNext}
                     hasMore={hasMore}
                  >
                     <table id="example" className="display w-100 dataTable">
                        <thead>
                           <tr role="row">
                              {tableHead.map((value, index) => (
                                 <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {items.map((value, i) => (
                              <tr key={i}>
                                 <td className="text-right">{value.rank}</td>
                                 <td>
                                    <div className="d-flex">
                                       <div className="prd-img mr-3">
                                          <OverlayTrigger
                                             key="right"
                                             trigger={["hover", "focus"]}
                                             placement="right"
                                             rootClose
                                             overlay={
                                                <Popover>
                                                   <Popover.Body>
                                                      {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={500} />}
                                                   </Popover.Body>
                                                </Popover>
                                             }
                                          >
                                             <Button variant="link" className='p-0' size="xs">
                                                <Link to={'/influencers/top-sales-influencers/video-details/' + value.author_id + '/video-creators'} target="_blank">
                                                   {value.avatar_larger_privatization && <img src={'https://t-img.picturehaven.net/tikmeta/' + value.avatar_larger_privatization + '?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1'} alt="" width={80} />}
                                                </Link>
                                             </Button>
                                          </OverlayTrigger>
                                       </div>
                                       <div className="prd-title">
                                          <p className='mb-2'>
                                             <Link to={'/influencers/details/' + value.author_id + '/breakout'} target="_blank">
                                                {value.nickname || ''}
                                             </Link>
                                          </p>
                                          <div className="d-flex align-items-center">
                                             <div className="mr-2">
                                                <span className={value?.sys_country?.country_code.toLowerCase() ? `fi fi-${value?.sys_country?.country_code.toLowerCase()} mr-1` : ''}></span>
                                             </div>
                                             <div>
                                                <Link to={'/influencers/details/' + value.author_id + '/breakout'} target="_blank">@{value.unique_id}</Link>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </td>
                                 <td className="text-right">
                                    {value.estimated_sales_amount_by_usd}<br />
                                    <span className="text-muted">({value.estimated_sales_amount})</span>
                                 </td>
                                 <td className="text-right">{value.estimated_sales_volume}</td>
                                 <td className="text-right">
                                    {value.estimated_avg_price_by_usd}<br />
                                    <span className="text-muted">({value.estimated_avg_price})</span>
                                 </td>
                                 <td className="text-right"><Conversion value={value.related_live_count || 0} /></td>
                                 <td className="text-right"><Conversion value={value.related_video_count || 0} /></td>
                                 <td className="text-right"><Conversion value={parseFloat(value.avg_play_count || 0) * 1000} /></td>
                                 <td className="text-right"><Conversion value={parseFloat(value.follower_count || 0) * 1000} /></td>
                                 <td className="text-right">
                                    <Button
                                       variant="outline-dark" size="xs"
                                       className="px-2 py-1"
                                       onClick={() => { markFavourite({ influencer_id: value.author_id, country_code: value.sys_country?.country_code }); }}>
                                       <i className={"fa " + (favourite.indexOf(value.author_id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                    </Button>
                                 </td>
                              </tr >
                           ))}
                        </tbody >
                     </table >
                  </InfiniteScrollWrapper >
               </div>
            </Table>
         </Card.Body>
      </Card>
   );
});

export default RelevantInfluencerstab;