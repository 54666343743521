import React, { Fragment, useEffect } from "react";

/// Components
import Markup from "./jsx";

/// Style
import "./css/custom.css";
import "./css/style.css";

import i18n from 'i18next';
import ReactGA from "react-ga4";
import { initReactI18next } from 'react-i18next';
import { withResizeDetector } from "react-resize-detector";
import EnglishTrans from './jsx/components/CommonComponents/Translations/English';
import SpanishTrans from './jsx/components/CommonComponents/Translations/Spanish';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_GA_MEASUREMENT_ID) ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_GA_MEASUREMENT_ID);

const App = ({ width }) => {
  let defaultLanguage = localStorage.getItem("language") && localStorage.getItem("language") != '' && localStorage.getItem("language") != 'undefined' ? localStorage.getItem("language") : 'en';

  useEffect(() => {
    if (localStorage.getItem("language") === "es") {
      i18n.changeLanguage("es");
    } else if (localStorage.getItem("language") === "en") {
      i18n.changeLanguage("en");
    }
  }, [defaultLanguage]);


  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: EnglishTrans },
      es: { translation: SpanishTrans },
    },
    lng: defaultLanguage, // default language
    fallbackLng: defaultLanguage, // fallback language
    interpolation: { escapeValue: false },
  });

  const body = document.querySelector("body");

  width >= 1300
    ? body.setAttribute("data-sidebar-style", "full")
    : width <= 1299 && width >= 767
      ? body.setAttribute("data-sidebar-style", "full")
      : body.setAttribute("data-sidebar-style", "full");

  useEffect(() => {
    const handleFocus = (e) => {
      if (e.target) {
        e.target.addEventListener('wheel', preventDefault, { passive: false });
      }
    };

    const handleBlur = (e) => {
      if (e.target) {
        e.target.removeEventListener('wheel', preventDefault);
      }
    };

    const preventDefault = (e) => {
      e.preventDefault();
    };

    document.addEventListener('focus', handleFocus, true);
    document.addEventListener('blur', handleBlur, true);

    // Cleanup
    return () => {
      document.removeEventListener('focus', handleFocus, true);
      document.removeEventListener('blur', handleBlur, true);
    };
  }, []);

  return (
    <Fragment>
      <Markup />
    </Fragment>
  );
};

export default withResizeDetector(App);
