import { React, useEffect, useState } from 'react';
import { Button, Card, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Axios from '../../../plugins/axios';
import InfiniteScrollWrapper from "../../Products/InfiniteScrollWrapper";

const FavoriteInfluencer = () => {
   const [page, setPage] = useState(0);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [favourite, setFavourite] = useState([]);
   const { t } = useTranslation();

   const tableHead = [
      {
         title: t('common.influencers'),
      },
      {
         title: t('influencers.previous_sales'),
         className: 'text-right',
      },
      {
         title: t('influencers.current_sales'),
         className: 'text-right',
      },
      {
         title: t('influencers.sales_growth_ratio'),
         className: 'text-right',
         sort: 10,
      },
      {
         title: t('influencers.atv'),
         className: 'text-right',
         sort: 12,
      },
      {
         title: t('common.revenue'),
         className: 'text-right',
         sort: 13,
      },
      {
         title: t('common.followers'),
         className: 'text-right',
         sort: 13,
      },
      {
         title: t('common.favorite'),
         className: 'text-right',
      }
   ];

   const getFavoriteList = async (page) => {
      let params = {
         page: page,
         limit: 20
      }
      let response = await Axios.get('/api/favorite-influencer', { params });
      let array = await response.data.result ? response.data.result.map((value, index) => value.influencer_id) : favourite;
      if (page === 0) {
         setItems(response.data.result);
         setFavourite(array);
      } else {
         setItems(items.concat(response.data.result));
         setFavourite(favourite.concat(array));
      }
      setHasMore(response?.data?.result?.length === 20);
   };
   
   useEffect(() => {
      setHasMore(true);
      getFavoriteList(page);
   }, []);

   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         getFavoriteList(number)
      }
   }

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-influencer', data);
      const index = favourite.indexOf(data.influencer_id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.influencer_id);
      }

      setFavourite(favourite.concat([]));
   };

   return (
      <>
         <Card>
            <Card.Body className='p-2'>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <InfiniteScrollWrapper
                        isInfiniteScrollOn={true}
                        lengthData={items.length}
                        functionNext={functionNext}
                        hasMore={hasMore}
                     >
                        <table id="example" className="display w-100 dataTable">
                           <thead>
                              <tr role="row">
                                 {tableHead.map((value, index) => (
                                    <th className={value.className || ''} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {items.map((influencer, index) => (
                                 <tr key={index}>
                                    <td>
                                       <div className='d-flex align-items-center'>
                                          <div className="prd-img mr-3">
                                             <OverlayTrigger
                                                key="right"
                                                trigger={["hover", "focus"]}
                                                placement="right"
                                                rootClose
                                                overlay={
                                                   <Popover>
                                                      <Popover.Body>
                                                         {influencer.avatar_larger_privatization &&
                                                            <img alt="" width={500} src={`https://t-img.picturehaven.net/tikmeta/${influencer.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                         }
                                                      </Popover.Body>
                                                   </Popover>
                                                }
                                             >
                                                <Button variant="link" className='p-0' size="xs">
                                                   <Link to={'/influencers/details/' + influencer.influencer_id + '/breakout'} target="_blank">
                                                      {influencer.avatar_larger_privatization &&
                                                         <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${influencer.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                      }
                                                   </Link>
                                                </Button>
                                             </OverlayTrigger>
                                          </div>
                                          <div className='prd-title'>
                                             <Link to={'/influencers/details/' + influencer.influencer_id + '/breakout'} target="_blank"
                                             >
                                                <p>{influencer?.nickname || '--'}</p>
                                             </Link>

                                             <p className='small'>
                                                {influencer?.sys_country?.country_code && <span className={`fi fi-${influencer?.sys_country?.country_code.toLowerCase()} mr-1`}></span>}
                                                <span className={`fi  mr-1`}></span>{influencer?.sys_country?.country || '--'}</p>
                                             {influencer?.categories && influencer?.categories.length != 0 && <div className="mb-1">
                                                <span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{influencer?.categories[0]?.name}</span> <br />
                                                {influencer?.categories[1]?.name && <><span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{influencer?.categories[1]?.name}</span> <br /></>}
                                                {influencer?.categories.length > 2 && <OverlayTrigger
                                                   key="right"
                                                   trigger="click"
                                                   placement="bottom"
                                                   rootClose
                                                   overlay={
                                                      <Popover className="variants-tooltip">
                                                         <Popover.Body>
                                                            <div className="m-2">
                                                               {influencer.categories && influencer.categories.slice(2).map((v, i) => (
                                                                  <span key={i + 2} className="btn btn-outline-dark btn-xs px-2 py-1 mr-1">{v.name}</span>
                                                               ))}
                                                            </div>
                                                         </Popover.Body>
                                                      </Popover>
                                                   }
                                                >
                                                   <Button variant="outline-dark" size="xs" className='px-2 py-1'>{influencer.categories.length > 1 ? ('+' + (influencer.categories.length - 2)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                                </OverlayTrigger>}
                                             </div>}

                                          </div>
                                       </div>
                                    </td>
                                    <td className='text-right'>
                                       {influencer?.last_sold_count ? influencer?.last_sold_count : '--'}
                                    </td>
                                    <td className='text-right'>{influencer?.estimated_sales_volume ? influencer?.estimated_sales_volume : '--'}</td>
                                    <td className='text-right'>{influencer?.sold_count_incr_rate ? `${((influencer?.sold_count_incr_rate * 1000) / 10).toFixed(2)}%` : '--'}</td>
                                    <td className='text-right'>
                                       <div>
                                          {influencer?.estimated_avg_price_usd || '--'} <br />
                                          <span className='text-muted'>{influencer?.estimated_avg_price ? `(${influencer?.estimated_avg_price})` : '--'}</span>
                                       </div>
                                    </td>
                                    <td className='text-right'>
                                       <div>
                                          {influencer?.estimated_sales_amount_usd || '--'}<br />
                                          <span className='text-muted'>{influencer?.estimated_sales_amount ? `(${influencer?.estimated_sales_amount})` : '--'}</span>
                                       </div>
                                    </td>
                                    <td className='text-right'>{influencer?.follower_count_str || '--'}</td>
                                    <td className="text-right">
                                       <div className="d-flex justify-content-end">
                                          <div className="mr-1">
                                             {<Button
                                                variant="outline-dark" size="xs"
                                                className="px-2 py-1"
                                                onClick={() => { markFavourite({ influencer_id: influencer.influencer_id, nickname: influencer?.nickname }); }}>
                                                <i className={"fa " + (favourite.indexOf(influencer.influencer_id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                             </Button>}
                                          </div>
                                       </div>
                                    </td>
                                 </tr>

                              ))}
                           </tbody>
                        </table>
                     </InfiniteScrollWrapper>
                  </div>
               </Table>
            </Card.Body>
         </Card>
      </>
   );
};

export default FavoriteInfluencer;