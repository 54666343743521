import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { Context } from '../../../MasterLayout.js';

const Header = ({ onNote, toggle, onProfile, onActivity, onNotification }) => {
   const { dispatch } = useContext(Context);
   const { t } = useTranslation();
   const { i18n } = useTranslation();
   const [Language, setLanguage] = useState("")
   const navigate = useNavigate();

   const user_type = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user_type : '';
   const searchList = [
      { value: '/products/products-search', label: t('common.products') },
      { value: '/influencers/influencers-search', label: t('common.influencers') },
      // { value: '/influencers/influencers-search', label: t('common.videos') },
      { value: '/shops/shop-search', label: t('common.shops') },
   ]
   const [searchShow, setSearchShow] = useState(true);
   const [search, setSearch] = useState('');
   const [searchType, setSearchType] = useState(searchList[0]);
   const [reinit, setReinit] = useState(0);

   const Logout = () => {
      localStorage.clear();
      window.location.href = '/login';
   };

   const changeLanguage = (lng) => {
      if (lng === "en") {
         localStorage.setItem('language', "en");
         i18n.changeLanguage("en");
         setLanguage("en")
      }
      else if (lng === "es") {
         localStorage.setItem('language', "es");
         i18n.changeLanguage("es");
         setLanguage("es")
      }
   };

   const showSearchTab = () => {
      const findIndex = searchList.findIndex(v => v.value == window.location.pathname);
      if (findIndex == -1) {
         setSearchShow(true);
      } else {
         setSearchShow(false);
      };
   };

   useEffect(() => {
      showSearchTab();
      const language = localStorage?.getItem("language")
      if (language === "en") {
         setLanguage("en")
         i18n.changeLanguage(language);
      } else if (language === "es") {
         setLanguage("es")
         i18n.changeLanguage(language);
      }
      if (reinit != 0) {
         dispatch({ type: 'keyword_searched', response: search });
         setReinit(0);
         setSearch('');
         return navigate(searchType?.value);
      }
   }, [reinit, window.location.pathname]);

   var path = window.location.pathname.split("/");
   var page_name = path[path.length - 1].replace(/-/g, "_");

   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar"
                        style={{ textTransform: "capitalize" }}
                     >
                        {t('PageName.' + page_name)}
                     </div>
                  </div>

                  <ul className="navbar-nav header-right">
                     <li className="nav-item header-search mr-3 d-none d-md-flex">
                        <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                           {searchShow && <div className="input-group mr-2 flex-nowrap">
                              <div className="input-group-prepend">
                                 <Form.Select className="form-control-sm pe-5" key={2} defaultValue={searchType} onChange={(e) => { setSearchType(e.target.value); }}>
                                    {searchList.map((v, i) => { return <option key={i} value={v.value}>{v.label}</option> })}
                                 </Form.Select>
                              </div>
                              <input
                                 type="text"
                                 className={`form-control form-control-sm`}
                                 placeholder={t('main_header.search_here')}
                                 value={search}
                                 onChange={(e) => setSearch(e.target.value)}
                                 required
                              />
                              <div className="input-group-prepend">
                                 <Link className="btn btn-primary btn-xs px-3 text-white" onClick={() => setReinit(reinit + 1)}>
                                    <i className="flaticon-381-search-2"></i>
                                 </Link>
                              </div>
                           </div>}
                           {user_type && user_type == 'user' && <Link to='/affiliate' target="_blank" className="btn btn-link text-primary text-nowrap btn-sm py-2 px-0 ml-3">{t('main_header.become_a_partner')}</Link>}
                        </div>
                     </li>
                  </ul>

                  <ul className="navbar-nav header-right">
                     <li className="nav-item mr-0">
                        <div className="btn-group mr-3 text-nowrap">
                           <button type="button" className={`btn  btn-xs py-1 px-2 ${Language === 'en' || Language == '' ? 'btn-primary' : 'btn-outline-dark'}`} onClick={() => changeLanguage('en')}><span className="fi fi-us"></span> EN</button>
                           <button type="button" className={`btn  btn-xs py-1 px-2 ${Language === 'es' ? 'btn-primary' : 'btn-outline-dark'}`} onClick={() => changeLanguage('es')}><span className="fi fi-es"></span>  ES</button>
                        </div>
                     </li>

                     <li
                        className={`nav-item dropdown header-profile ${toggle === "profile" ? "show" : ""
                           }`}
                        onClick={() => onProfile()}
                     >
                        <div className="dropdown">
                           <button className="btn btn-link dropdown-toggle btn-sm p-0" type="button" data-toggle="dropdown" aria-expanded="false">
                              <svg
                                 id="icon-user1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-primary"
                                 width="30"
                                 height="30"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                 <circle cx="12" cy="7" r="4"></circle>
                              </svg>

                           </button>
                           <div className="dropdown-menu dropdown-menu-right">
                              <Link
                                 to="/profile"
                                 className="dropdown-item ai-icon"
                              >
                                 <svg
                                    id="icon-user1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-primary"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                 >
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                 </svg>
                                 <span className="ml-2">{t('main_header.profile')} </span>
                              </Link>
                              {user_type && user_type == 'admin' && <Link to="/admin-dashboard" className="dropdown-item ai-icon">
                                 <i className="flaticon-381-networking text-primary"></i>
                                 <span className="ml-2">{t('main_header.admin_dashboard')} </span>
                              </Link>}
                              <Link
                                 to="/login"
                                 className="dropdown-item ai-icon"
                                 onClick={Logout}
                              >
                                 <svg
                                    id="icon-logout"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-danger"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                 >
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                    <polyline points="16 17 21 12 16 7"></polyline>
                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                 </svg>
                                 <span className="ml-2">{t('main_header.logout')} </span>
                              </Link>
                           </div>
                        </div>
                     </li>
                  </ul>
               </div>
            </nav>
         </div >
      </div >
   );
};

export default Header;
