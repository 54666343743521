import React from "react";
import { createRoot } from 'react-dom/client';
import 'swiper/css';
import 'swiper/css/bundle';
import App from "./App";

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
   <App />
);