import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Button, Card, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Context } from '../../../../MasterLayout';
import Axios from "../../../plugins/axios";
import InfiniteScrollWrapper from "../../Products/InfiniteScrollWrapper";
import RangeSlider from "../../Products/RangeSlider";
import Category from "./Category";

const RelevantProductsTab = forwardRef((props, ref) => {
   const { t } = useTranslation();
   const { dispatch } = useContext(Context);
   const params = useParams();
   const [page, setPage] = useState(0);
   const [onLoad, setOnLoad] = useState(false);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [sorting, setSorting] = useState({ sort: 35, sort_type: 'DESC' });
   const [favourite, setFavourite] = useState([]);
   const [category1, setCategory1] = useState('');
   const [category2, setCategory2] = useState('');
   const [categoryId1, setCategoryId1] = useState('');
   const [categoryId2, setCategoryId2] = useState('');
   const [salesRange, setSalesRange] = useState([]);
   const [keyword, setKeyword] = useState('');
   const [searchClick, setSearchClick] = useState(0);
   const [influencerRange, setInfluencerRange] = useState([]);
   const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
   const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const salesRangeOption = {
      '0-10000': '<10K',
      '10000-100000': '10K-100K',
      '100000-1000000': '100K-1M',
      '1000000-10000000': '1M-10M',
      '10000000-': '10M+',
   }, influencerRangeOption = {
      '0-5': '≤5',
      '5-10': '5-10',
      '10-20': '10-20',
      '20-50': '20-50',
      '50-100': '50-100',
      '-100': '>100',
   }

   const tableHead = [
      {
         title: t('common.rank'),
      },
      {
         title: t('common.products'),
      },
      {
         title: t('common.revenue'),
         className: 'text-left sorting',
         sort: 35
      },
      {
         title: t('common.sales'),
         className: 'text-right sorting',
         sort: 14
      },
      {
         title: t('common.price'),
         className: 'text-left sorting',
      },
      {
         title: t('common.commission'),
         className: 'text-right sorting',
         sort: 21,
      },
      {
         title: t('common.videos'),
         className: 'text-right sorting',
         sort: 12,
      },
      {
         title: t('common.relevant_influencers'),
         className: 'text-right sorting',
         sort: 13
      },
      {
         title: t('common.favorite'),
         className: 'text-right',
      },
   ];

   const handleSalesRange = async (value) => {
      setSalesRange(value);
   };
   const handleInfluencerRange = async (value) => {
      setInfluencerRange(value);
   };

   useImperativeHandle(ref, () => ({
      async relevantProductsTab() {
         if (onLoad == false) {
            setHasMore(true);
            setPage(0)
            relevantProducts(0);
         }
         setOnLoad(true);
      }
   }));

   useEffect(() => {
      if (onLoad == true) {
         setHasMore(true);
         setPage(0);
         relevantProducts(0);
      }
   }, [sorting, salesRange, influencerRange, category1, category2, searchClick]);

   const relevantProducts = async (page) => {
      try {
         let data = {
            sort: sorting.sort,
            page: page,
            sort_type: sorting.sort_type,
            seller_id: params.shop_id,
         }

         if (salesRange.from) {
            data['sold_count_start'] = salesRange.from;
         }
         if (salesRange.to && salesRange.to != '') {
            data['sold_count_end'] = salesRange.to;
         }

         if (influencerRange.from) {
            data['start_related_author'] = influencerRange.from;
         }
         if (influencerRange.to && influencerRange.to != '') {
            data['end_related_author'] = influencerRange.to;
         }

         if (category1 != '') {
            data['category1'] = category1;
            data['category_id1'] = categoryId1;
         }
         if (category2 != '') {
            data['category2'] = category2;
            data['category_id2'] = categoryId2;
         }
         if (keyword) {
            data['keyword'] = keyword;
         }

         let response = await Axios.post('/api/shop/relevant-products', data);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });
         if (response && response.status === 200) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.product_id) : favourite;

            if (page == 0) {
               setItems(response.data.data);
               setFavourite(array);
            } else {
               setItems(items.concat(response.data.data));
               setFavourite(favourite.concat(array));
            }
            setHasMore(response?.data?.data?.length === 20);
         } else {
            setHasMore(false);
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }
   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      }
   };
   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         relevantProducts(number);
      }
   }

   const handleCategory = async (categoryIds, categoryNames) => {
      setCategory1(categoryNames[0] ? categoryNames[0] : '');
      setCategory2(categoryNames[1] ? categoryNames[1] : '');
      setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
      setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
   };

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product', data);
      const index = favourite.indexOf(data.id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.id);
      }

      setFavourite(favourite.concat([]));
   };

   return (
      <>
         <Card className='rounded-0'>
            <Card.Body>
               <div className='topfilter'>
                  <h3>{t('common.product_list')}</h3>
                  <div className="d-flex align-items-center mb-3 justify-content-end">
                     <div className="input-group mb-3 w-auto">
                        <input type="text" className="form-control form-control-sm" placeholder={t("product_research.products_search.search_for_products")} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                        <div className="input-group-append">
                           <button className="btn btn-primary btn-xs" type="button" onClick={() => { setHasMore(true); setItems([]); setSearchClick(searchClick + 1); }}>
                              {t('product_research.products_search.search')}
                           </button>
                        </div>
                     </div>
                  </div>
                  <Category category={props.category} showCategory={props.shop?.category_list} handleCategory={handleCategory} categoryId1={categoryId1} />
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('common.sales')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={1000000} rangeOption={salesRangeOption} rangeOptionName={'sales-range'} step={10} handleUpdate={handleSalesRange} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('common.followers')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={100} defaultMin={0} defaultMax={5000} rangeOption={influencerRangeOption} rangeOptionName={'influencer'} step={10} handleUpdate={handleInfluencerRange} />
                        </div>
                     </div>
                  </div>
               </div>
            </Card.Body>
         </Card>
         <Card>
            <Card.Body className='p-2'>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <InfiniteScrollWrapper
                        isInfiniteScrollOn={true}
                        lengthData={items.length}
                        functionNext={functionNext}
                        hasMore={hasMore}
                     >
                        <table id="example" className="display w-100 dataTable">
                           <thead>
                              <tr role="row">
                                 {tableHead.map((value, index) => (
                                    <th className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? sortClass : '')} onClick={(e) => { value.sort && handleSort(value.sort, sorting.sort == value.sort ? sortType : 'DESC') }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {items.map((product, index) => (
                                 <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                       <div className='d-flex'>
                                          <div className="prd-img mr-3">
                                             <OverlayTrigger
                                                key="right"
                                                trigger={["hover", "focus"]}
                                                placement="right"
                                                rootClose
                                                overlay={
                                                   <Popover>
                                                      <Popover.Body>
                                                         {product.images_privatization[0] &&
                                                            <img alt="" width={500} src={`
                                                               https://t-img.picturehaven.net/product/${product.images_privatization[0]}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1
                                                               `} />
                                                         }
                                                      </Popover.Body>
                                                   </Popover>
                                                }
                                             >
                                                <Button variant="link" className='p-0' size="xs">
                                                   <Link to={'/products/' + product.id + '/details'} target="_blank">
                                                      {product.images_privatization[0] &&
                                                         <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${product.images_privatization[0]}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />}
                                                   </Link>
                                                </Button>
                                             </OverlayTrigger>
                                          </div>
                                          <div className='prd-title'>
                                             <p>
                                                <OverlayTrigger overlay={<Tooltip>{product.title}</Tooltip>}>
                                                   <Link to={'/products/' + product.id + '/details'} target="_blank">
                                                      <span>{product.title.length > 40 ? product.title.substring(0, 40) + '...' : product.title}</span>
                                                   </Link>
                                                </OverlayTrigger>
                                             </p>
                                             {/* </Link> */}
                                             <p>{product.country_code && <span className={`fi fi-${product.country_code.toLowerCase()} mr-1`}></span>}
                                                {product.country_code && global.config.region[product.country_code.toUpperCase()]}</p>
                                          </div>
                                       </div>
                                    </td>
                                    <td>
                                       {product?.total_sales_usd} <br />
                                       <span className='text-muted'>({product?.total_sales})</span>
                                    </td>
                                    <td className='text-right'>
                                       {product.sold_count_str || '-'}
                                    </td>
                                    <td>
                                       {product.sale_props ?
                                          <div className="d-flex justify-content-between">
                                             <div className="mr-3">
                                                {product.price_usd}<br />
                                                <span className="text-muted">({product.price})</span>
                                             </div>
                                             <div className='d-flex p-variants'>
                                                <div className='v-btn'>
                                                   <OverlayTrigger
                                                      key="right"
                                                      trigger="click"
                                                      placement="right"
                                                      rootClose
                                                      overlay={
                                                         <Popover id={`popover-positioned-$"right"`} className="variants-tooltip">
                                                            <Popover.Header as="h3">Variants</Popover.Header>
                                                            <Popover.Body>
                                                               <div className="d-flex mb-3">
                                                                  <div className="mr-3">
                                                                     {product.images_privatization[0] && <img src={"https://t-img.picturehaven.net/product/" + product.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/140x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={200} />}
                                                                  </div>
                                                                  <div>
                                                                     <p className="mb-0">{product.title}</p>
                                                                     <p className="mb-0">{product.price_usd} <span className="text-muted">({product.price})</span></p>
                                                                  </div>
                                                               </div>
                                                               {product.sale_props.map((variant, index1) => (
                                                                  <div className="mb-3" key={index1}>
                                                                     <p className="mb-0"><strong>{variant.prop_name}</strong></p>
                                                                     <div className="variant-box">
                                                                        {variant.sale_prop_values.map((value, index2) => (
                                                                           <Button key={index2} variant="outline-dark" size="xs" className="mb-1 mr-1">{value.prop_value}</Button>
                                                                        ))}
                                                                     </div>
                                                                  </div>
                                                               ))}
                                                            </Popover.Body>
                                                         </Popover>
                                                      }
                                                   >
                                                      <Button variant="outline-primary" size="xs">Variants</Button>
                                                   </OverlayTrigger>
                                                </div>
                                                <div className='v-truck'>
                                                   {product.free_shipping &&
                                                      <OverlayTrigger overlay={<Tooltip>Free Shipping</Tooltip>}>
                                                         <span><i className="fa fa-truck mt-2 ml-2 text-muted" aria-hidden="true"></i></span>
                                                      </OverlayTrigger>
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                          : ''}
                                    </td>
                                    <td>{product.commission_rate || 0}%</td>
                                    <td>{product.related_videos || '-'}</td>
                                    <td>{product.related_author || '-'}</td>
                                    <td className="text-right">
                                       <div className="d-flex justify-content-end">
                                          <div className="mr-1">
                                             {<Button
                                                variant="outline-dark" size="xs"
                                                className="px-2 py-1"
                                                onClick={() => { markFavourite({ id: product.id, country_code: product.country_code }); }}>
                                                <i className={"fa " + (favourite.indexOf(product.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                             </Button>}
                                          </div>
                                       </div>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </InfiniteScrollWrapper>
                  </div>
               </Table>
            </Card.Body>
         </Card>
      </>
   )
});

export default RelevantProductsTab;