import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Collapse, Form, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TikTokEmbed } from 'react-social-media-embed';
import { Context } from '../../../../MasterLayout';
import Axios from '../../../plugins/axios';
import Export from '../../CommonComponents/export';
import PlanModal from '../../CommonComponents/PlanModal';
import RangeSlider from '../../Products/RangeSlider';
import LiveStreamersOnly from './LiveStreamersOnly';
import NormalInfluencerSearch from './NormalInfluencerSearch';
import VideoCreatorsOnly from './VideoCreatorsOnly';

function InfluencersSearch() {
    const { t } = useTranslation();
    const [rankType, setrankType] = useState("SALES_AUTHOR_RISING")

    const { state, dispatch } = useContext(Context);
    const defaultCycle = 'W1'
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [countryCode, setCountryCode] = useState('');
    const [cycle, setCycle] = useState(defaultCycle);
    const [search, setsearch] = useState('');
    const [version, setVersion] = useState('W1');
    const [versionList, setVersionList] = useState([]);
    const typeList = [
        { name: "Art", namecn: "文学艺术" },
        { name: "Beauty Care", namecn: "美妆护理" },
        { name: "Brand", namecn: "品牌" },
        { name: "Celebrities", namecn: "网红名人" },
        { name: "Entertainment", namecn: "娱乐&特效" },
        { name: "Effects & Montage", namecn: "网络" },
        { name: "Food", namecn: "美食菜谱" },
        { name: "Fashion", namecn: "时尚&穿搭" },
        { name: "Finance", namecn: "金融经济" },
        { name: "Games", namecn: "游戏" },
        { name: "Government", namecn: "政府" },
        { name: "Health", namecn: "健康" },
        { name: "Key Option Leader", namecn: "权威顾问" },
        { name: "Life Style", namecn: "生活" },
        { name: "Media", namecn: "渠道" },
        { name: "NGO", namecn: "非政府组织" },
        { name: "Pets", namecn: "宠物" },
        { name: "Programming", namecn: "IT" },
        { name: "Sports", namecn: "运动" },
        { name: "Science & Education", namecn: "科学教育" },
        { name: "Service", namecn: "专业服务" },
        { name: "Talent", namecn: "唱歌跳舞" },
        { name: "Travel", namecn: "旅游" },
        { name: "Technology", namecn: "科技&电子" },
        { name: "Vlog", namecn: "Vlog" },
        { name: "Other", namecn: "其他" },

    ];
    const [typeValue, settypeValue] = useState("")
    const [favourite, setFavourite] = useState([]);
    const AtvRange = [];
    const salesRange = [];
    const [followersRange, setFollowersRange] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCommerce, setIsCommerce] = useState(false);
    const [Videocreators, setVideocreators] = useState(false);
    const [livestreamers, setlivestreamers] = useState(false);
    const [influencersearch, setinfluencerSearch] = useState(true);
    const [sortType, setsortType] = useState('');
    const [VersionForVideo, setVersionForVideo] = useState('');
    const [SearchValue, setSearchValue] = useState(state && state.keyword ? state.keyword : '');
    const [searchkeyword, setsearchkeyword] = useState(state && state.keyword ? state.keyword : '');
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    const [contentModal, setContentModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [payload, setPayload] = useState({});
    const [limitMsg, setLimitMsg] = useState('');


    let sortbytab
    if (Videocreators) {
        sortbytab = 23;
    } else if (livestreamers) {
        sortbytab = 1;
    } else {
        sortbytab = 1;
    }
    const [sorting, setSorting] = useState({ sort: sortbytab });
    const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';
    const handleSort = async (sort) => {
        if (sort != sorting.sort) {
            setSorting({ sort });
        }
    };
    const getVersion = async (cycle) => {
        try {
            let params = {
                cycle
            }
            if (rankType) {
                params['rank_type'] = rankType;
            }
            let response = await Axios.get('/api/version', { params });
            if (response.data.data) {
                setVersion(response.data.data[0].version);
            }
            setVersionList(response.data.data ? response.data.data : []);
        } catch (error) {
            console.error(error);
        }
    };

    const influencerSearch = async (page) => {
        try {
            let params = {
                page,
                sort: sorting.sort,
            };
            if (countryCode != '') {
                params['country_code'] = countryCode;
            }
            params['is_commerce'] = isCommerce;
            if (sortType != '') {
                params['sort_type'] = sortType;
            }
            if (VersionForVideo != '') {
                params['version'] = VersionForVideo;
            }
            if (cycle != '') {
                params['cycle'] = cycle;
            }
            if (search != '') {
                params['search'] = search;
            }
            if (Videocreators) {
                params['viewVideoCreators'] = Videocreators;
            }
            if (livestreamers) {
                params['viewlivestreamers'] = livestreamers;
            }
            if (searchkeyword != '') {
                params['keyword'] = searchkeyword;
                dispatch({ type: 'keyword_searched', response: '' });
            }
            if (typeValue) {
                params['category'] = typeList.find(e => e.name === typeValue)?.namecn;
            }
            if (selectedOptions.length) {
                let contactType = selectedOptions.join(',');
                params['contact_type'] = contactType;
            }
            //Followers Range
            if (followersRange.to || followersRange.from) {
                params['fan_start'] = followersRange.from;
            }
            if (followersRange.to && followersRange.to != '') {
                params['fan_end'] = followersRange.to;
            }
            setPayload(params)
            let response = await Axios.post(window?.location?.pathname?.includes('v2') ? '/api/v2/influencer-search' : '/api/influencer-search', params);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            setLimitMsg(response?.data?.msg || '');
            if (response?.data?.statusCode === 402) setModalShow(true);

            if (response && response.status === 200 && response.data.data) {
                let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.influencer_id) : favourite;
                if (page == 0) {
                    setItems(response.data.data);
                    setFavourite(array);
                } else {
                    setItems(items.concat(response.data.data));
                    setFavourite(favourite.concat(array));
                }
                setHasMore(response?.data?.data?.length === 20);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    useEffect(() => {
        async function fetchData() {
            setItems([]);
            setHasMore(true)
            setPage(0);
            if (version == 'W1') {
                getVersion(cycle);
            } else {
                influencerSearch(0);
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting, countryCode, versionList, isCommerce, Videocreators, searchkeyword, VersionForVideo, typeValue, followersRange, selectedOptions, version]);

    const markFavourite = async (data) => {
        await Axios.post('/api/favorite-influencer', data);
        const index = favourite.indexOf(data.influencer_id);
        if (index > -1) {
            favourite.splice(index, 1);
        } else {
            favourite.push(data.influencer_id);
        }
        setFavourite(favourite.concat([]));
    };

    const functionNext = async () => {
        let number = page + 1;
        setPage(number);
        influencerSearch(number)
    }
    const InfluencerSearchCountries = { ...global.config.region, ...global.config.countries };
    const regionList = Object.keys(InfluencerSearchCountries).slice(0, 8).map(function (key) {
        return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => setCountryCode(key)}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{InfluencerSearchCountries[key]}</a>
    });


    const options = [
        { id: 1, contactValue: 0, label: 'Email' },
        { id: 2, contactValue: 3, label: 'Instagram' },
        { id: 3, contactValue: 4, label: 'Twitter' },
        { id: 4, contactValue: 2, label: 'WhatsApp' },
        { id: 5, contactValue: 5, label: 'Youtube' },
        { id: 6, contactValue: 1, label: 'Zalo' }
    ];

    const handleOptionChange = (event) => {
        const optionId = parseInt(event.target.value);
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedOptions([...selectedOptions, optionId]);
        } else {
            setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
        }
    };

    const toggleSwitchVideocreators = () => {
        setVideocreators(!Videocreators)
        setinfluencerSearch(false)
        setlivestreamers(false)
        if (!Videocreators) {
            setrankType("SALES_AUTHOR_RISING")
            setIsCommerce(true)
            setVersion("W1");
            setCycle("W1");
            setsearch(1);
            setSorting({ sort: 23 });
            setsortType('DESC');
            setVersionForVideo(version);
        } else {
            setinfluencerSearch(true)
            setIsCommerce(false)
            setCycle('');
            setsearch(0);
            setSorting({ sort: 1 });
            setsortType('');
            setVersionForVideo('');
        }
    }
    const toggleSwitchlivestreamers = () => {
        setlivestreamers(!livestreamers)
        setVideocreators(false)
        setinfluencerSearch(false)
        if (!livestreamers) {
            setrankType("AUTHOR_LIVE_RISING")
            setIsCommerce(false);
            setVersion("W1");
            setCycle("W1");
            setsearch(1);
            setSorting({ sort: 1 });
            setsortType('DESC');
            setVersionForVideo(version);
        } else {
            setinfluencerSearch(true)
            setIsCommerce(false)
            setCycle('');
            setsearch(0);
            setSorting({ sort: 1 });
            setsortType('');
            setVersionForVideo('');
        }
    }

    const handleEnterKeyPress = async (e) => {
        if (e.key === 'Enter') {
            setsearchkeyword(e.target.value);
        }
    };

    return (
        <div>
            <Card className='rounded-0'>
                <Card.Body>
                    {limitMsg && <div className='d-flex flex-row justify-content-center'>
                        <h4 className='flex my-2 text-center mx-2'><strong>{limitMsg}</strong></h4>
                        <button type='button' className="btn btn-outline-primary btn-xs" onClick={() => setModalShow(true)}>Upgrade</button>
                    </div>}
                    <div className='topfilter'>
                        <div className='d-flex'>
                            <p className='mr-2'><small>{t('common.long_text_1')}</small></p>
                            <Form.Control onKeyPress={handleEnterKeyPress} value={SearchValue} onChange={(e) => setSearchValue(e.target.value)} className="w-25 h-25" placeholder={t('influencers.search_influencers')} />
                        </div>
                        <div className="d-flex align-items-center flex-wrap mb-2">
                            <p className="m-0 mr-3"><strong>{t('common.country')}</strong></p>
                            <a className={"btn " + (countryCode == '' ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 mb-2 my-1"}
                                onClick={() => setCountryCode("")}>{t('common.all')}</a>
                            {regionList}
                            <a
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                                className='btn btn-outline-dark btn-xs show-sub mb-2 mb-2 my-1'><i className="fa fa-chevron-down" aria-hidden="true"></i></a>
                            <Collapse in={open}>
                                <div id="example-collapse-text" className='pl-5 mx-4'>
                                    <div className='d-flex align-items-center flex-wrap '>
                                        {
                                            Object.keys(InfluencerSearchCountries).slice(8).map((key) => (
                                                (
                                                    InfluencerSearchCountries &&
                                                    (
                                                        <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => setCountryCode(key)}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>{InfluencerSearchCountries[key]}</a>
                                                    )

                                                )

                                            ))
                                        }
                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        <div className="d-flex align-items-center flex-wrap mb-3">
                            <p className="m-0 mr-3"><strong>{t('common.filters')}</strong></p>
                            <div className="dropdown product-rating-menu">
                                <div className="form-group mb-0 mr-3">
                                    <select className="form-control form-control-sm" onChange={(e) => { settypeValue(e.target.value); }}>
                                        {typeList.map((value, index) => (
                                            <option key={index} value={value.name}>{value.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="dropdown product-rating-menu">
                                <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('common.followers')}
                                </a>
                                <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                                    <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'views'} step={10} handleUpdate={(e) => { setFollowersRange(e) }} />
                                </div>
                            </div>

                            <div className={`dropdown ${isOpen ? 'show' : ''}`}>
                                <button
                                    className="btn btn-outline-dark btn-xs dropdown-toggle d-flex align-items-center"
                                    type="button"
                                    id="multiSelectDropdown"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    {t('common.contact_information')}
                                </button>
                                <div className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="multiSelectDropdown">
                                    {options.map((option) => (
                                        <Form.Check
                                            style={{ marginLeft: "10%" }}
                                            key={option.id}
                                            type="checkbox"
                                            id={`option_${option.id}`}
                                            label={option.label}
                                            checked={selectedOptions.includes(option.contactValue)}
                                            onChange={handleOptionChange}
                                            value={option.contactValue}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className='d-flex'>
                            <div>
                                <Form.Check
                                    type="switch"
                                    id={`custom-switch-1`}
                                    checked={Videocreators}
                                    onChange={(e) => toggleSwitchVideocreators()}
                                />
                            </div>
                            <p className='mr-3'>{t('influencers.video_creators_only')}</p>
                            <div>
                                <Form.Check
                                    type="switch"
                                    id={`custom-switch-1`}
                                    checked={livestreamers}
                                    onChange={(e) => toggleSwitchlivestreamers()}
                                />
                            </div>
                            <p>{t('influencers.video_live_streamers_only')}</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mx-2 my-3">
                            <Export payload={payload} data_source="Influencers Search" type="Influencers Search" />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body className='p-2'>
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            {influencersearch && <NormalInfluencerSearch tikTokEmbedID={tikTokEmbedID} setTikTokEmbedID={setTikTokEmbedID} contentModal={contentModal} setContentModal={setContentModal} handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                            {Videocreators && <VideoCreatorsOnly handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}
                            {livestreamers && <LiveStreamersOnly handleSort={handleSort} sortClass={sortClass} sorting={sorting} functionNext={functionNext} items={items} favourite={favourite} markFavourite={markFavourite} hasMore={hasMore} />}

                        </div>
                    </Table>
                </Card.Body>
            </Card>

            {/* <!-- Video Modal --> */}
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PlanModal setModalShow={setModalShow} modalShow={modalShow} />
        </div>
    )
}

export default InfluencersSearch