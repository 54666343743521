import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  Collapse,
  OverlayTrigger,
  Popover,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Context } from "../../../MasterLayout";
import Axios from "../../plugins/axios";
import formattedNumber from "../CommonComponents/FormatNumberUS";

const todaysDate = moment(new Date()).format("YYYY-MM-DD");

function StorePerformance({
  currentStore,
  propsData,
  skuArr,
  reinit,
  setSearchDisabled
}) {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFee, setOpenFee] = useState(false);
  const [openReimbursement, setOpenReimbursement] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [openPPC, setOpenPPC] = useState(false);
  const [openCOGs, setOpenCOGs] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [dateStrings, setDateStrings] = useState(
    `start_date=${todaysDate}&end_date=${todaysDate}`
  );

  useEffect(() => {
    getSummaryReport();
    setDateStrings(
      `start_date=${moment(propsData.start_date).format(
        "YYYY-MM-DD"
      )}&end_date=${moment(propsData.end_date).format("YYYY-MM-DD")}`
    );
  }, [currentStore, propsData, skuArr, reinit]);

  const getSummaryReport = async () => {
    try {
      setSpinner(true);
      let response = await Axios.post("/api/dashboard/store-performance", {
        store_id: currentStore._id,
        start_date: propsData.start_date,
        end_date: propsData.end_date,
        sku_id: skuArr.map((v) => v?.sku_id),
      }).catch((e) => console.error("Axios-Catch: " + e.message));
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200) {
        setSummaryData(response.data.data);
        setSearchDisabled((prev) => prev - 1)
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    setSpinner(false);
  };

  return (
    <Card className="rounded-0 mw-100">
      {spinner && (
        <div className="loader-warp">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Card.Body className="p-0">
        <div
          className={`d-flex flex-column align-items-center card-head p-3 text-white card-head ${propsData?.className || ""
            }`}
        >
          <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
            <p className={`text-white m-0 p-0 lh-base`}>
              <strong>{propsData.name}</strong>
            </p>
          </Link>
          <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
            <p className="text-white m-0 p-0 lh-base">
              {moment(propsData.start_date).format("MM/DD/YYYY")} -{" "}
              {moment(propsData.end_date).format("MM/DD/YYYY")}
            </p>
          </Link>
        </div>
        <div className="card-bd p-3">
          <div className="mb-2">
            <p className="me-2 mb-0 dashboard-h-text">
              {t("dashboard.gross_revenue")}
            </p>
            <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
              <span className="lh-base fs-4 dashboard-number">
                ${" "}
                {formattedNumber(
                  parseFloat(summaryData?.current?.total_payment || 0).toFixed(
                    2
                  )
                )}
              </span>
            </Link>
          </div>
          <hr className="dashboard-hr" />
          <div className="d-flex justify-content-between mb-2">
            <p className="me-2 mb-0 dashboard-h-text">
              {t("dashboard.orders_units")}
              <br />
              <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
                <span className="dashboard-h-text text-black lh-base fs-4">
                  {formattedNumber(
                    parseFloat(summaryData?.current?.order_count || 0).toFixed(
                      2
                    )
                  )}
                  /
                  {formattedNumber(
                    parseFloat(
                      summaryData?.current?.total_quantity_sold || 0
                    ).toFixed(2)
                  )}
                </span>
              </Link>
            </p>
            {/* <p className="me-2 mb-0 dashboard-h-text"><small><b>Margin</b></small><br />
              <Link to={"#"} className='dashboard-h-text text-black'>
                {summaryData?.current?.total_payment && summaryData?.current?.total_payment != 0 ? formattedNumber(parseFloat(((parseFloat(summaryData?.current?.profit_loss) / parseFloat(summaryData.current.total_payment)) * 100)).toFixed(2)) + ' %' : '-'}
              </Link>
            </p> */}
            <p className="me-2 mb-0 dashboard-h-text">
              {t("dashboard.units_sold")}
              <br />
              <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
                <span className="dashboard-h-text text-black lh-base fs-4">
                  {formattedNumber(
                    parseFloat(
                      summaryData?.current?.total_quantity_sold || 0
                    ).toFixed(2)
                  )}
                </span>
              </Link>
            </p>
          </div>
          <hr className="dashboard-hr" />
          <div className="d-flex justify-content-between mb-2">
            <p className="me-2 mb-0 dashboard-h-text">
              {t("dashboard.cogs")}
              <br />
              <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
                <span className="dashboard-h-text text-black lh-base fs-4">
                  ${" "}
                  {formattedNumber(
                    parseFloat(summaryData?.current?.total_cost || 0).toFixed(2)
                  )}
                </span>
              </Link>
            </p>
            <p className="me-2 mb-0 dashboard-h-text">
              {t("dashboard.margin")}
              <br />
              <span className="dashboard-h-text text-black lh-base fs-4">
                {summaryData?.current?.total_payment &&
                  summaryData?.current?.total_payment != 0
                  ? formattedNumber(
                    parseFloat(
                      (parseFloat(summaryData?.current?.profit_loss) /
                        parseFloat(summaryData.current.total_payment)) *
                      100
                    ).toFixed(2)
                  ) + " %"
                  : "-"}
              </span>
            </p>
          </div>
          <hr className="dashboard-hr" />
          <div className="mb-0">
            <p className="me-2 mb-0 dashboard-h-text">
              {t("dashboard.net_profit")}
            </p>
            <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
              <span className="text-primary dashboard-number lh-base fs-4">
                ${" "}
                {formattedNumber(
                  parseFloat(summaryData?.current?.net_profit || 0).toFixed(2)
                )}
              </span>
            </Link>
          </div>
          <div className="text-end ">
            <OverlayTrigger
              key="right"
              trigger={["click"]}
              placement="auto"
              rootClose
              overlay={
                <Popover
                  style={{
                    maxHeight: "600px",
                    maxWidth: "300px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Popover.Body>
                    <div className="row">
                      <Col lg="6">
                        <p className="mb-0">{t("dashboard.gross_revenue")}:</p>
                      </Col>
                      <Col lg="6" className="text-right">
                        <p className="mb-0">
                          ${" "}
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.total_payment || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                      <Col lg="12" className="text-right">
                        <hr className="m-0" />
                      </Col>

                      {summaryData?.current?.total_discount ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">{t("dashboard.discount")}:</p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current?.total_discount || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                        </>
                      ) : null}

                      {summaryData?.current?.total_shipping_price ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">
                              {t("dashboard.shipping")}:{" "}
                              <i
                                className="fa fa-arrow-right"
                                style={{
                                  transform: open
                                    ? "rotate(90deg)"
                                    : "rotate(0deg)",
                                }}
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                                aria-hidden="true"
                              ></i>
                            </p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current?.total_shipping_price ||
                                  0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                          <Collapse in={open}>
                            <div id="example-collapse-text">
                              <div className="row ml-1">
                                {summaryData?.current
                                  ?.total_customer_paid_shipping_fee_amount ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.customer_paid")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_customer_paid_shipping_fee_amount ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_platform_shipping_fee_discount_amount ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.platform")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_platform_shipping_fee_discount_amount ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_customer_shipping_fee_offset_amount ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.customer_offset")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_customer_shipping_fee_offset_amount ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_fbt_fulfillment_fee_amount ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.fbt_fulfillment")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_fbt_fulfillment_fee_amount ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_shipping_fee_subsidy_amount ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.subsidy")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_shipping_fee_subsidy_amount ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_customer_estimated_paid_shipping ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t(
                                          "dashboard.total_customer_estimated_paid_shipping"
                                        )}
                                        :
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_customer_estimated_paid_shipping ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </Collapse>
                        </>
                      ) : null}

                      {summaryData?.current?.total_item_fees ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">
                              {t("dashboard.fee")}:{" "}
                              <i
                                className="fa fa-arrow-right"
                                style={{
                                  transform: openFee
                                    ? "rotate(90deg)"
                                    : "rotate(0deg)",
                                }}
                                onClick={() => setOpenFee(!openFee)}
                                aria-controls="example-collapse-text"
                                aria-expanded={openFee}
                                aria-hidden="true"
                              ></i>
                            </p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current?.total_item_fees || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                          <Collapse in={openFee}>
                            <div id="example-collapse-text">
                              <div className="row ml-1">
                                {summaryData?.current
                                  ?.total_referral_fee_amount ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.referral")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_referral_fee_amount || 0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_affiliate_commission_amount ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.affiliate_commission")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_affiliate_commission_amount ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_affiliate_ads_commission_amount ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t(
                                          "dashboard.affiliate_ads_commission"
                                        )}
                                        :
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_affiliate_ads_commission_amount ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </Collapse>
                        </>
                      ) : null}

                      {summaryData?.current?.total_reimbursement ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">
                              {t("dashboard.total_reimbursement")}:{" "}
                              <i
                                className="fa fa-arrow-right"
                                style={{
                                  transform: openReimbursement
                                    ? "rotate(90deg)"
                                    : "rotate(0deg)",
                                }}
                                onClick={() =>
                                  setOpenReimbursement(!openReimbursement)
                                }
                                aria-controls="example-collapse-text"
                                aria-expanded={openReimbursement}
                                aria-hidden="true"
                              ></i>
                            </p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current?.total_reimbursement || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                          <Collapse in={openReimbursement}>
                            <div id="example-collapse-text">
                              <div className="row ml-1">
                                {summaryData?.current
                                  ?.total_platform_reimbursement ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t(
                                          "dashboard.total_platform_reimbursement"
                                        )}
                                        :
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_platform_reimbursement ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_logistics_reimbursement ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t(
                                          "dashboard.total_logistics_reimbursement"
                                        )}
                                        :
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_logistics_reimbursement ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </Collapse>
                        </>
                      ) : null}

                      <Col lg="6">
                        <p className="mb-0">
                          COGS:{" "}
                          <i
                            className="fa fa-arrow-right"
                            style={{
                              transform: openCOGs
                                ? "rotate(90deg)"
                                : "rotate(0deg)",
                            }}
                            onClick={() => setOpenCOGs(!openCOGs)}
                            aria-controls="example-collapse-text"
                            aria-expanded={openCOGs}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </Col>
                      <Col lg="6" className="text-right">
                        <p className="mb-0">
                          ${" "}
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.total_cost || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                      <Col lg="12" className="text-right">
                        <hr className="m-0" />
                      </Col>
                      <Collapse in={openCOGs}>
                        <div id="example-collapse-text">
                          <div className="row ml-1">
                            <Col lg="6">
                              <p className="mb-0">COGS:</p>
                            </Col>
                            <Col lg="6" className="text-right">
                              <p className="mb-0">
                                ${" "}
                                {formattedNumber(
                                  parseFloat(
                                    summaryData?.current?.order_cogs || 0
                                  ).toFixed(2)
                                )}
                              </p>
                            </Col>
                            <Col lg="12" className="text-right">
                              <hr className="m-0" />
                            </Col>
                            <Col lg="6">
                              <p className="mb-0">Sample Order COGS:</p>
                            </Col>
                            <Col lg="6" className="text-right">
                              <p className="mb-0">
                                ${" "}
                                {formattedNumber(
                                  parseFloat(
                                    summaryData?.current?.sample_cogs || 0
                                  ).toFixed(2)
                                )}
                              </p>
                            </Col>
                            <Col lg="12" className="text-right">
                              <hr className="m-0" />
                            </Col>
                          </div>
                        </div>
                      </Collapse>

                      <Col lg="6">
                        <p className="mb-0 rotate-90">
                          PPC Spend:{" "}
                          <i
                            className="fa fa-arrow-right"
                            style={{
                              transform: openPPC
                                ? "rotate(90deg)"
                                : "rotate(0deg)",
                            }}
                            onClick={() => setOpenPPC(!openPPC)}
                            aria-controls="example-collapse-text"
                            aria-expanded={openPPC}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </Col>
                      <Col lg="6" className="text-right">
                        <p className="mb-0">
                          $ {summaryData?.current?.ppc_cost ? "-" : ""}
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.ppc_cost || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                      <Col lg="12" className="text-right">
                        <hr className="m-0" />
                      </Col>
                      <Collapse in={openPPC}>
                        <div id="example-collapse-text">
                          <div className="row ml-1">
                            {summaryData?.current?.ads_spend?.map((v, i) => (
                              <>
                                <Col lg="6">
                                  <p className="mb-0">{v?._id}:</p>
                                </Col>
                                <Col lg="6" className="text-right">
                                  <p className="mb-0">
                                    $ {v?.ads_spend ? "-" : ""}
                                    {formattedNumber(
                                      parseFloat(v?.ads_spend || 0).toFixed(2)
                                    )}
                                  </p>
                                </Col>
                                <Col lg="12" className="text-right">
                                  <hr className="m-0" />
                                </Col>
                              </>
                            ))}
                          </div>
                        </div>
                      </Collapse>

                      {summaryData?.current?.total_refund ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">
                              {t("dashboard.refund")}:{" "}
                              <i
                                className="fa fa-arrow-right"
                                style={{
                                  transform: openRefund
                                    ? "rotate(90deg)"
                                    : "rotate(0deg)",
                                }}
                                onClick={() => setOpenRefund(!openRefund)}
                                aria-controls="example-collapse-text"
                                aria-expanded={openRefund}
                                aria-hidden="true"
                              ></i>
                            </p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current?.total_refund || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                          <Collapse in={openRefund}>
                            <div id="example-collapse-text">
                              <div className="row ml-1">
                                {summaryData?.current?.total_payment_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.gross_sales")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_payment_refund || 0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current?.total_discount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.seller_discount")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_discount_refund || 0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_customer_paid_shipping_fee_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.customer_paid")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_customer_paid_shipping_fee_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_platform_shipping_fee_discount_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.platform")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_platform_shipping_fee_discount_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_customer_shipping_fee_offset_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.customer_offset")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_customer_shipping_fee_offset_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_fbt_fulfillment_fee_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.fbt_fulfillment")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_fbt_fulfillment_fee_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_shipping_fee_subsidy_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.subsidy")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_shipping_fee_subsidy_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_referral_fee_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.referral")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_referral_fee_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_affiliate_commission_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.affiliate_commission")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_affiliate_commission_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_affiliate_ads_commission_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t(
                                          "dashboard.affiliate_ads_commission"
                                        )}
                                        :
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_affiliate_ads_commission_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                                {summaryData?.current
                                  ?.total_administration_fee_amount_refund ? (
                                  <>
                                    <Col lg="6">
                                      <p className="mb-0">
                                        {t("dashboard.administration")}:
                                      </p>
                                    </Col>
                                    <Col lg="6" className="text-right">
                                      <p className="mb-0">
                                        ${" "}
                                        {formattedNumber(
                                          parseFloat(
                                            summaryData?.current
                                              ?.total_administration_fee_amount_refund ||
                                            0
                                          ).toFixed(2)
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg="12" className="text-right">
                                      <hr className="m-0" />
                                    </Col>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </Collapse>
                        </>
                      ) : null}

                      {summaryData?.current?.other_expenses ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">Other Expenses:</p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current?.other_expenses || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                        </>
                      ) : null}

                      {summaryData?.current?.cancelled_orders ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">
                              {t("dashboard.cancelled_orders")}:{" "}
                            </p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current?.cancelled_orders || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                        </>
                      ) : null}

                      {summaryData?.current?.total_our_shipping_charges ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">
                              {t("dashboard.seller_shipping")}:
                            </p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData.current
                                    .total_our_shipping_charges || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                        </>
                      ) : null}

                      {summaryData?.current?.total_fbt_warehouse_service_fee ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">Monthly Storage Fee:</p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current
                                    ?.total_fbt_warehouse_service_fee || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                        </>
                      ) : null}

                      {summaryData?.current?.total_reserve ? (
                        <>
                          <Col lg="6">
                            <p className="mb-0">Reserve:</p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              ${" "}
                              {formattedNumber(
                                parseFloat(
                                  summaryData?.current?.total_reserve || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                        </>
                      ) : null}

                      <Col lg="6">
                        <p className="mb-0">{t("dashboard.net_profit")}:</p>
                      </Col>
                      <Col lg="6" className="text-right">
                        <p className="mb-0">
                          ${" "}
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.net_profit || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <span className="text-primary text-decoration-underline cursor-pointer">
                Details
              </span>
            </OverlayTrigger>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default StorePerformance;
