import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, OverlayTrigger, Popover, Row, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from '../../../MasterLayout';
import Axios from "../../plugins/axios";
import ProfitLanding from "./ProfitLanding";
import StoreFetch from "./StoreFetch";

const ProductMasterList = () => {
   const { t } = useTranslation();

   let coloumns = [t('product_list.name'), t('product_list.brand'), t('product_list.qty'), t('product_list.cog'), t('product_list.shipping_cost'), t('product_list.actions')]
   const [page, setPage] = useState(1);
   const [limit, setlimit] = useState(10);
   const [totalEntries, setTotalEntries] = useState(0);
   const [loadedOnce, setLoadedOnce] = useState(false);
   const [productCount, setProductCount] = useState(0);
   const [StoreData, setStoreData] = useState([]);
   const [searchValue, setSearchValue] = useState('');
   const [search, setSearch] = useState('');
   const [sort, setsort] = useState({ product_title: 0, brand: 0, cog: 0, shipping_cost: 0 })
   const [pageCount, setPageCount] = useState(0);
   const { dispatch } = useContext(Context);
   const [stores, setStores] = useState([]);
   const [currentStore, setCurrentStore] = useState("");
   const [oldData, setOldData] = useState([]);

   useEffect(() => {
      getStores();
   }, []);

   const getStores = async () => {
      try {
         dispatch({ type: "loader_show", response: true });
         let response = await Axios.get("/api/dashboard/stores").catch((e) =>
            console.error("Axios-Catch: " + e.message)
         );
         dispatch({
            type: "subscription_expired",
            response: response.data.statusCode,
         });

         if (response && response.status === 200) {
            setLoadedOnce(true);
            response.data.data.stores.map((v) => {
               v.value = v._id;
               v.label = v.name;
               return v;
            });
            setStores(response.data.data.stores);
            let storeIndex = 0;
            if (response.data?.data?.default_store) {
               storeIndex = response.data.data.stores.findIndex(
                  (n) => n._id == response.data.data.default_store
               );
            }
            setCurrentStore(response.data.data.stores[storeIndex]);
            setProductCount(response.data?.data?.productCount);
         }
      } catch (error) {
         console.error("Error-Catch: " + error.message);
      }
      dispatch({ type: "loader_hide", response: true });
   };

   // COMMON FETCH STORE DATA 
   const fetchData = async (sortid = 'createdAt', sortascdsc = 1, search) => {
      try {
         dispatch({ type: "loader_show", response: true });
         let response = await Axios.get(`/api/tiktok/product?search=${search}&page=${page}&limit=${limit}&sortby=${sortid}&sort=${sortascdsc}&store_id=${currentStore?._id ? currentStore?._id : ""}`);
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.data && response.data.result) {
            const { results, totalCount, searchResults } = response?.data?.result
            setStoreData(search ? searchResults ? searchResults : [] : results ? results : [])
            setTotalEntries(totalCount ? totalCount : 0);
            setPageCount(Math.ceil(totalCount / limit));
         } else {
            setStoreData([])
            setTotalEntries(0);
            setPageCount(0);
            console.error("Failed to fetch data");
         }
      } catch (error) {
         console.error("Error fetching data:", error);
      }
      dispatch({ type: "loader_hide", response: true });
   };

   //COMMON CURRENT SORTBY AND SORT VALUES
   function currentSortValue() {
      let soryBy
      let sortValue
      if (sort.product_title) {
         soryBy = "product_title"
         sortValue = sort.product_title
      } else if (sort.brand) {
         soryBy = "brand"
         sortValue = sort.brand
      } else if (sort.cog) {
         soryBy = "cost_of_good"
         sortValue = sort.cog
      } else if (sort.shipping_cost) {
         soryBy = "shipping_cost"
         sortValue = sort.shipping_cost
      } else {
         soryBy = "stock_qty"
         sortValue = sort.stock_qty
      }
      return { soryBy, sortValue }
   }

   // INITIAL RENDERING STARTS FROM HERE (useEffect)
   useEffect(() => {
      const { soryBy, sortValue } = currentSortValue()
      if (currentStore) fetchData(soryBy, sortValue, search);
   }, [page, limit, currentStore, search]);


   //SEARCHING OPERATION STARTS HERE
   const handlsearchvalue = (e) => {
      const value = e.target.value
      setSearchValue(value)
   }
   const handleEnterKeyPress = async (e) => {
      setPage(1);
      setSearch(searchValue);
   };


   //PAGINATION AND LIMIT 
   const handlePageClick = (event) => {
      setPage(event.selected + 1);
   };
   const onClickSetLimit = (e) => {
      if (e.target.value != limit) {
         setlimit(e.target.value);
         setPage(1);
      }
   };

   //SOERTING FUNCTIONALITY
   const handleSort = (e) => {
      const sortid = e.target.id;
      setPage(1);
      if (sortid === "product_title") {
         if (sort.product_title === 1) {
            setsort({ product_title: -1, brand: 0, cog: 0, shipping_cost: 0, stock_qty: 0 })
         } else {
            setsort({ product_title: 1, brand: 0, cog: 0, shipping_cost: 0, stock_qty: 0 })
         }
         fetchData(sortid, sort.product_title === 1 ? -1 : 1, search)
      } else if (sortid === "brand") {
         if (sort.brand === 1) {
            setsort({ product_title: 0, brand: -1, cog: 0, shipping_cost: 0, stock_qty: 0 })
         } else {
            setsort({ product_title: 0, brand: 1, cog: 0, shipping_cost: 0, stock_qty: 0 })
         }
         fetchData(sortid, sort.brand === 1 ? -1 : 1, search)
      } else if (sortid === "cost_of_good") {
         if (sort.cog === 1) {
            setsort({ product_title: 0, brand: 0, cog: -1, shipping_cost: 0, stock_qty: 0 })
         } else {
            setsort({ product_title: 0, brand: 0, cog: 1, shipping_cost: 0, stock_qty: 0 })
         }
         fetchData(sortid, sort.cog === 1 ? -1 : 1, search)
      } else if (sortid === "shipping_cost") {

         if (sort.shipping_cost === 1) {
            setsort({ product_title: 0, brand: 0, cog: 0, shipping_cost: -1, stock_qty: 0 })
         } else {
            setsort({ product_title: 0, brand: 0, cog: 0, shipping_cost: 1, stock_qty: 0 })
         }
         fetchData(sortid, sort.shipping_cost === 1 ? -1 : 1, search)
      } else if (sortid === "stock_qty") {
         if (sort.stock_qty === 1) {
            setsort({ product_title: 0, brand: 0, cog: 0, shipping_cost: 0, stock_qty: -1 })
         } else {
            setsort({ product_title: 0, brand: 0, cog: 0, shipping_cost: 0, stock_qty: 1 })
         }
         fetchData(sortid, sort.stock_qty === 1 ? -1 : 1, search)
      }
   }

   const handleSaveEdit = async (data) => {
      const editdata = {
         _id: data._id,
         cost_of_good: data?.cost_of_good ? data?.cost_of_good : 0,
         shipping_cost: data.shipping_cost ? data.shipping_cost : 0
      };

      const response = await Axios.patch('/api/tiktok/product', editdata);
      if (response.data.statusCode === 200) {
         let oldProducts = [...oldData];
         setOldData([...oldProducts.filter(v => v?._id !== data?._id)])
         toast.success(t("product_list.toast_message_1"), {
            position: "top-center",
            autoClose: 3000,
         });
      } else {
         toast.error(t("product_list.toast_message_2"), {
            position: "top-center",
            autoClose: 3000,
         })
      }
   }

   const handleDeleteEdit = (data, i) => {
      let oldProducts = [...oldData], products = [...StoreData];
      let old = [...oldProducts.filter(v => v?._id === data?._id)];

      products[i] = old[0];

      setOldData([...oldProducts.filter(v => v?._id !== data?._id)]);
      setStoreData(products);
   }

   const handleEditShippingCost = (e, i) => {
      //const text = e.target.value ? parseFloat(parseFloat(e.target.value).toFixed(2)) : 0;
      let products = [...StoreData];
      let text = products[i].shipping_cost;
      if (e.target.value.indexOf(".") == -1) {
         text = parseFloat(parseFloat(e.target.value).toFixed(2));
      } else {
         let array = e.target.value.split(".");
         console.log(array[1]);
         if (array[1].length > 2) {
            return;
         } else {
            text = e.target.value;
         }
      }

      products[i].shipping_cost = text
      setStoreData(products)
   };

   const handleEditCog = (e, i) => {
      let products = [...StoreData];
      let text = products[i].cost_of_good;
      if (e.target.value.indexOf(".") == -1) {
         text = parseFloat(parseFloat(e.target.value).toFixed(2));
      } else {
         let array = e.target.value.split(".");
         console.log(array[1]);
         if (array[1].length > 2) {
            return;
         } else {
            text = e.target.value;
         }
      }
      //const text = e.target.value ? parseFloat(parseFloat(e.target.value).toFixed(2)) : 0;

      products[i].cost_of_good = text
      setStoreData(products)
   };

   return (

      <>
         {stores.length != 0 && (
            <>
               <div className="revenue-section">
                  <Row className="align-items-center mb-3">
                     <Col md="9" className="text-center text-sm-left"></Col>
                     <Col md="3" className="">
                        <div className="dropdown product-rating-menu mr-2">
                           <Select
                              closeMenuOnSelect={true}
                              defaultValue={currentStore}
                              options={stores}
                              onChange={(e) => { setCurrentStore(e); setPage(1); }}
                           />
                        </div>
                     </Col>
                  </Row>
               </div>
               {productCount != 0 && (
                  <Card>
                     <Card.Body>
                        <div className="row mb-2">
                           <div className="d-flex align-items-center justify-content-end">
                              <div className="dropdown">
                                 <button className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">{limit}</button>
                                 <div className="dropdown-menu">
                                    <div className="dropdown-item"><option className="limit" value='10' onClick={onClickSetLimit}>10</option></div>
                                    <div className="dropdown-item"><option className="limit" value='25' onClick={onClickSetLimit}>25</option></div>
                                    <div className="dropdown-item"><option className="limit" value='50' onClick={onClickSetLimit}>50</option></div>
                                    <div className="dropdown-item"><option className="limit" value='100' onClick={onClickSetLimit}>100</option></div>
                                 </div>
                              </div>
                              <Form.Control onKeyDown={(e) => { if (e.key === 'Enter') handleEnterKeyPress(e) }} onBlur={handleEnterKeyPress} value={searchValue} onChange={(e) => handlsearchvalue(e)} className="w-auto form-control-sm" placeholder={t("product_list.search_products")} />
                           </div>
                        </div>
                        <Table responsive className="display w-100 dataTable">
                           <thead>
                              <tr role="row">
                                 <th onClick={(e) => { handleSort(e) }} id="product_title" className={`sorting ${(sort.product_title === 1 ? "sorting-asc" : '') || (sort.product_title === -1 ? "sorting-desc" : '')}`}>{coloumns[0]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="product_title" /></th>
                                 <th onClick={(e) => { handleSort(e) }} id="brand" className={`text-right sorting ${(sort.brand === 1 ? "sorting-asc" : '') || (sort.brand === -1 ? "sorting-desc" : '')}`}>{coloumns[1]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="brand" /></th>
                                 <th onClick={(e) => { handleSort(e) }} id="stock_qty" className={`text-right sorting ${(sort.stock_qty === 1 ? "sorting-asc" : '') || (sort.stock_qty === -1 ? "sorting-desc" : '')}`}>{coloumns[2]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="stock_qty" /></th>
                                 <th onClick={(e) => { handleSort(e) }} id="cost_of_good" className={`text-right sorting ${(sort.cog === 1 ? "sorting-asc" : '') || (sort.cog === -1 ? "sorting-desc" : '')}`}>{coloumns[3]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="cost_of_good" /></th>
                                 <th onClick={(e) => { handleSort(e) }} id="shipping_cost" className={`text-right sorting ${(sort.shipping_cost === 1 ? "sorting-asc" : '') || (sort.shipping_cost === -1 ? "sorting-desc" : '')}`}>{coloumns[4]} &nbsp;<span onClick={(e) => { handleSort(e) }} id="shipping_cost" /></th>
                                 <th className="text-right">{coloumns[5]}</th>
                              </tr>
                           </thead>
                           <tbody>
                              {StoreData?.length ? StoreData?.map((data, i) => (<tr key={i}>
                                 <td className="text-left">
                                    <div className="d-flex">
                                       <div className="prd-img mr-3">
                                          <OverlayTrigger
                                             key="right"
                                             trigger={["hover", "focus"]}
                                             placement="right"
                                             rootClose
                                             overlay={
                                                <Popover>
                                                   <Popover.Body>
                                                      <img width={500} src={data?.main_image || data?.main_images?.[0]?.urls?.[0]} alt="" />
                                                   </Popover.Body>
                                                </Popover>
                                             }
                                          >
                                             <Button variant="link" className='p-0' size="xs">
                                                <img width={70} src={data?.main_image || data?.main_images?.[0]?.urls?.[0]} alt="" />
                                             </Button>
                                          </OverlayTrigger>
                                       </div>
                                       <div className="prd-title-list">
                                          <p>
                                             <OverlayTrigger overlay={<Tooltip>{data?.product_title || data.title}</Tooltip>}>
                                                <span>{data?.product_title ? data?.product_title?.length > 90 ? data?.product_title?.substring(0, 90) + '...' : data?.product_title : data?.title > 90 ? data?.title?.substring(0, 90) + '...' : data?.title}</span>
                                             </OverlayTrigger>
                                          </p>
                                          <p>{t("product_list.sku_id")}:&nbsp;{data?.sku_id}</p>
                                          <p>{t("product_list.sku")}:&nbsp;{data?.seller_sku}</p>
                                       </div>
                                    </div>
                                 </td>
                                 <td className="text-right">{data?.brand?.name ? data?.brand?.name : "--"}</td>
                                 <td className="text-right">{data?.stock_qty ? data?.stock_qty : "0"}</td>
                                 <td className="text-right">
                                    {
                                       oldData.filter(v => v?._id === data?._id).length > 0
                                          ?
                                          <div className="d-flex justify-content-end">
                                             <Form.Control min="0" type='Number' value={data.cost_of_good} onChange={(e) => handleEditCog(e, i)} className="w-25 h-25" />
                                          </div>
                                          : (data?.cost_of_good ? data?.cost_of_good : "0")
                                    }
                                 </td>
                                 <td className="text-right">
                                    {
                                       oldData.filter(v => v?._id === data?._id).length > 0
                                          ?
                                          <div className="d-flex justify-content-end">
                                             <Form.Control min="0" type='Number' value={data.shipping_cost} onChange={(e) => handleEditShippingCost(e, i)} className="w-25 h-25" />
                                          </div>
                                          : (data?.shipping_cost ? data?.shipping_cost : "0")
                                    }
                                 </td>
                                 <td className="text-right">
                                    <div className="d-flex justify-content-end">
                                       {oldData.filter(v => v?._id === data?._id).length > 0 ?
                                          <div className="d-flex">
                                             <Link onClick={() => handleSaveEdit(data)}
                                                className="btn btn-outline-primary btn-xs mr-1">

                                                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                             </Link>
                                             <Link
                                                onClick={() => handleDeleteEdit(data, i)}
                                                className="btn btn-outline-danger btn-xs"
                                             >
                                                <i className="fa fa-trash"></i>
                                             </Link>
                                          </div>
                                          : <Link
                                             onClick={() => setOldData([...oldData, { ...data }])}
                                             className="btn btn-outline-primary btn-xs mr-1"
                                          >
                                             <i className="fa fa-pencil"></i>
                                          </Link>}
                                    </div>
                                 </td>
                              </tr>)) : t('common.no_data_found')}
                           </tbody>
                        </Table>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                           <div className="dataTables_info">
                              {t('common.showing')} {totalEntries ? (((page - 1) * limit) + 1) : 0} {t('common.to')}{" "}
                              {totalEntries ? Math.min(page * limit, totalEntries) : 0} {t('common.of')} {totalEntries} {t('common.entries')}
                           </div>
                           <div className="dataTables_paginate paging_simple_numbers">
                              <ReactPaginate
                                 nextLabel="Next >"
                                 onPageChange={handlePageClick}
                                 pageRangeDisplayed={1}
                                 marginPagesDisplayed={1}
                                 pageCount={pageCount}
                                 previousLabel="< Previous"
                                 pageClassName="page-item"
                                 pageLinkClassName="page-link"
                                 previousClassName="page-item"
                                 previousLinkClassName="page-link"
                                 nextClassName="page-item"
                                 nextLinkClassName="page-link"
                                 breakLabel="..."
                                 breakClassName="page-item"
                                 breakLinkClassName="page-link"
                                 containerClassName="pagination"
                                 activeClassName="active"
                                 renderOnZeroPageCount={null}
                                 forcePage={page - 1}
                              />
                           </div>
                        </div>
                        <ToastContainer />
                     </Card.Body>
                  </Card>
               )}
               {productCount == 0 && loadedOnce && <StoreFetch />}
            </>
         )}
         {stores.length == 0 && loadedOnce && <ProfitLanding />}
      </>
   );
};

export default ProductMasterList;