import React from "react";
import { Card } from "react-bootstrap";


const Tutorials = () => {

   return (

      <div className="row">
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls controlsList="nodownload" loop muted>
                     <source src={`https://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/tutorials/Viralytic-Profit-Dashboard.mp4`} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Profit Dashboard</h4>
               </Card.Body>
            </Card>
         </div>
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls controlsList="nodownload" loop muted>
                     <source src={`https://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/tutorials/Viralytic-Product-Profitability-Calculator.mp4`} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Product Profitability Calculator</h4>
               </Card.Body>
            </Card>
         </div>
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls controlsList="nodownload" loop muted>
                     <source src={`https://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/tutorials/Viralytic-Influencer-Research-Tool.mp4`} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Influencer Research Tool</h4>
               </Card.Body>
            </Card>
         </div>
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls controlsList="nodownload" loop muted>
                     <source src={`https://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/tutorials/Viralytic-Product-Research-Tool.mp4`} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Product Research Tool</h4>
               </Card.Body>
            </Card>
         </div>
         <div className="col-lg-4">
            <Card>
               <Card.Body>
                  <video className="embed-responsive-item w-100 mb-3" controls controlsList="nodownload" loop muted>
                     <source src={`https://${process.env.REACT_APP_S3BUCKET_NAME}.s3.amazonaws.com/tutorials/Viralytic-Store-Research-Tool.mp4`} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
                  <h4 className="mb-0">Store Research Tool</h4>
               </Card.Body>
            </Card>
         </div>
      </div>


   );
};

export default Tutorials;