import getSymbolFromCurrency from 'currency-symbol-map';
import { React, useContext, useEffect, useState } from 'react';
import { Button, Card, OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../../config';
import { Context } from '../../../MasterLayout';
import Axios from "../../plugins/axios";
import Conversion from "../CommonComponents/Conversion";
import InfiniteScrollWrapper from "./InfiniteScrollWrapper";


const FavoriteProduct = () => {
   const { t } = useTranslation();

   const { dispatch } = useContext(Context);
   const [page, setPage] = useState(0);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [favourite, setFavourite] = useState([]);

   const tableHead = [
      {
         title: t('product_research.top_sales.favorites.products'),
      },
      {
         title: t('product_research.top_sales.favorites.price'),
         className: 'text-left',
      },
      {
         title: t('product_research.top_sales.favorites.commission'),
         className: 'text-right',
      },
      {
         title: t('product_research.top_sales.favorites.sales'),
         className: 'text-right',
      },
      {
         title: `${t('product_research.top_sales.favorites.relevant')} <br/> ${t('product_research.top_sales.favorites.videos')}`,
         className: 'text-right',
      },
      {
         title: `${t('product_research.top_sales.favorites.relevant')} <br/> ${t('product_research.top_sales.favorites.influencers')}`,
         className: 'text-right',
      },
      {
         title: t('product_research.top_sales.favorites.favourite'),
         className: 'text-right',
      }
   ];

   const getFavoriteList = async (page) => {
      let params = {
         page: page,
         limit: 20
      }
      let response = await Axios.get('/api/favorite-products', { params });
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200 && response.data.result) {
         let array = await response.data.result ? response.data.result.map((value, index) => value.product_id) : favourite;
         if (page == 0) {
            setItems(response.data.result);
            setFavourite(array);
         } else {
            setItems(items.concat(response.data.result));
            setFavourite(favourite.concat(array));
         }
         setHasMore(response?.data?.result?.length === 20);
      }
   };

   useEffect(() => {
      //do operation on state change
      setItems([]);
      setHasMore(true);
      setPage(0);
      getFavoriteList(0);
   }, []);

   const functionNext = async () => {
      if (items.length > 0) {
         let number = page + 1;
         setPage(number);
         getFavoriteList(number)
      }
   }

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product', data);
      const index = favourite.indexOf(data.id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.id);
      }

      setFavourite(favourite.concat([]));
   };

   return (
      <Card>
         <Card.Body className='p-2'>
            <Table responsive className="w-100">
               <div id="example_wrapper" className="dataTables_wrapper">
                  <InfiniteScrollWrapper
                     isInfiniteScrollOn={true}
                     lengthData={items.length}
                     functionNext={functionNext}
                     hasMore={hasMore}
                  >
                     <table id="example" className="display w-100 dataTable">
                        <thead>
                           <tr role="row">
                              {tableHead.map((value, index) => (
                                 <th key={index} className={value.className || ''} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {items.length > 0 && items.map((product, index) => (
                              <tr key={index}>
                                 <td>
                                    <div className="d-flex">
                                       <div className="prd-img mr-3">
                                          <OverlayTrigger
                                             key="right"
                                             trigger={["hover", "focus"]}
                                             placement="right"
                                             rootClose
                                             overlay={
                                                <Popover>
                                                   <Popover.Body>
                                                      {product.images_privatizatione[0] && <img src={"https://t-img.picturehaven.net/product/" + product.images_privatizatione[0] + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={500} />}
                                                   </Popover.Body>
                                                </Popover>
                                             }
                                          >
                                             <Button variant="link" className='p-0' size="xs" to={'/products/' + product.product_id + '/details'}>
                                                <Link to={'/products/' + product.product_id + '/details'} target="_blank">
                                                   {product.images_privatizatione[0] && <img src={"https://t-img.picturehaven.net/product/" + product.images_privatizatione[0] + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                                </Link>
                                             </Button>

                                          </OverlayTrigger>
                                       </div>
                                       <div className="prd-title">
                                          <p>
                                             <OverlayTrigger overlay={<Tooltip>{product.title}</Tooltip>}>
                                                <Link to={'/products/' + product.product_id + '/details'} target="_blank">
                                                   <span>{product.title.length > 40 ? product.title.substring(0, 40) + '...' : product.title}</span>
                                                </Link>
                                             </OverlayTrigger>
                                          </p>
                                          {!product.shop ? <span>Closed</span> :
                                             <div className="d-flex align-items-center">
                                                <div className="mr-2">
                                                   {product.country_code && <span className={`fi fi-${product.country_code.toLowerCase()} mr-1`}></span>}
                                                </div>
                                                <div>
                                                   {product.country_code && global.config.region[product.country_code.toUpperCase()]}
                                                </div>
                                             </div>}
                                       </div>
                                    </div>
                                 </td>
                                 <td>
                                    {product.sale_prop ?
                                       <div className="d-flex justify-content-between">
                                          <div className="mr-3">
                                             ${parseFloat(Math.round((product.price_usd || 0) * 100) / 100).toFixed(2)}<br />
                                             <span className="text-muted">({getSymbolFromCurrency(product.currency ? product.currency.toUpperCase() : 'USD')}{parseFloat(Math.round((product.price || 0) * 100) / 100).toFixed(2)})</span>
                                          </div>
                                          <div className='d-flex p-variants'>
                                             <div className='v-btn'>
                                                <OverlayTrigger
                                                   key="right"
                                                   trigger="click"
                                                   placement="right"
                                                   rootClose
                                                   overlay={
                                                      <Popover id={`popover-positioned-$"right"`} className="variants-tooltip">
                                                         <Popover.Header as="h3">Variants</Popover.Header>
                                                         <Popover.Body>
                                                            <div className="d-flex mb-3">
                                                               <div className="mr-3">
                                                                  {product.images_privatizatione[0] && <img src={"https://t-img.picturehaven.net/product/" + product.images_privatizatione[0] + "?imageMogr2/auto-orient/thumbnail/140x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={200} />}
                                                               </div>
                                                               <div>
                                                                  <p className="mb-0">{product.title}</p>
                                                                  <p className="mb-0">{product.price_usd} <span className="text-muted">({product.price})</span></p>
                                                               </div>
                                                            </div>
                                                            {product.sale_prop.map((variant, index1) => (
                                                               <div className="mb-3" key={index1}>
                                                                  <p className="mb-0"><strong>{variant.prop_name}</strong></p>
                                                                  <div className="variant-box">
                                                                     {variant.sale_prop_values.map((value, index2) => (
                                                                        <Button key={index2} variant="outline-dark" size="xs" className="mb-1 mr-1">{value.prop_value}</Button>
                                                                     ))}
                                                                  </div>
                                                               </div>
                                                            ))}
                                                         </Popover.Body>
                                                      </Popover>
                                                   }
                                                >
                                                   <Button variant="outline-primary" size="xs">Variants</Button>
                                                </OverlayTrigger>
                                             </div>
                                             <div className='v-truck'>
                                                {product.free_shipping &&
                                                   <OverlayTrigger overlay={<Tooltip>Free Shipping</Tooltip>}>
                                                      <span><i className="fa fa-truck mt-2 ml-2 text-muted" aria-hidden="true"></i></span>
                                                   </OverlayTrigger>
                                                }
                                             </div>
                                          </div>
                                       </div>
                                       : ''}
                                 </td>
                                 <td className="text-right">
                                    {product.commission_rate || '-'}
                                 </td>
                                 <td className="text-right">
                                    {product.sales_incr_usd}<br />
                                    <span className="text-muted">({product.sales_incr})</span>
                                 </td>
                                 <td className="text-right">
                                    {product.related_videos ? <Conversion value={product.related_videos} /> : '-'}

                                 </td>
                                 <td className="text-right">
                                    {product.related_author ? <Conversion value={product.related_author} /> : '-'}

                                 </td>
                                 <td className="text-right">
                                    <div className="d-flex justify-content-end">
                                       { /*<div className="mr-1">
                                          <Button variant="outline-dark" size="xs" className="px-2 py-1"><i className="fa fa-line-chart" aria-hidden="true"></i></Button>
                                       </div>*/ }
                                       <div className="mr-1">
                                          {<Button
                                             variant="outline-dark" size="xs"
                                             className="px-2 py-1"
                                             onClick={() => { markFavourite({ id: product.product_id, country_code: product.country_code }); }}>
                                             <i className={"fa " + (favourite.indexOf(product.product_id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                          </Button>}
                                       </div>
                                       { /*<div>
                                          <Button variant="outline-dark" size="xs" className="px-2 py-1"><i className="fa fa-download" aria-hidden="true"></i></Button>
                                       </div>*/ }
                                    </div>
                                 </td>
                              </tr>

                           ))}
                        </tbody>
                     </table>
                  </InfiniteScrollWrapper>
               </div>
            </Table>
         </Card.Body>
      </Card>
   );
};

export default FavoriteProduct;