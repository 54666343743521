import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Table,
  Tooltip,
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import noImage from "../../../images/no-image.jpg";
import { Context } from "../../../MasterLayout";
import Axios from "../../plugins/axios";
import formattedNumber from "../CommonComponents/FormatNumberUS.js";
import InfiniteScrollWrapper from "../Products/InfiniteScrollWrapper.js";
import NetProfitChart from "../TiktokStore/NetProfitChart";
import OrdersTable from "../TiktokStore/OrdersTable";
import ProfitLanding from "../TiktokStore/ProfitLanding";
import StoreFetch from "../TiktokStore/StoreFetch";
import UnitSoldChart from "../TiktokStore/UnitSoldChart";
import StorePerformance from "./StorePerformance";

const todaysDate = moment(new Date()).format("YYYY-MM-DD");
const filterArr = [
  {
    key: "Today / Yesterday / Month To Date / Last Month",
    value: [
      {
        key: "today",
        name: "Today",
        start_date: moment().startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
        className: "",
      },
      {
        key: "yesterday",
        name: "Yesterday",
        start_date: moment().subtract(1, "day").startOf("day").toDate(),
        end_date: moment().subtract(1, "day").endOf("day").toDate(),
        className: "card-head-5",
      },
      {
        key: "month_to_date",
        name: "Month To Date",
        start_date: moment().startOf("month").toDate(),
        end_date: moment().endOf("day").toDate(),
        className: "",
      },
      {
        key: "last_month",
        name: "Last Month",
        start_date: moment().subtract(1, "month").startOf("month").toDate(),
        end_date: moment().subtract(1, "month").endOf("month").toDate(),
        className: "card-head-5",
      },
    ],
  },
  {
    key: "Today / Yesterday / 7 Days / 14 Days / 30 Days",
    value: [
      {
        key: "today",
        name: "Today",
        start_date: moment().startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
        className: "card-head",
      },
      {
        key: "yesterday",
        name: "Yesterday",
        start_date: moment().subtract(1, "day").startOf("day").toDate(),
        end_date: moment().subtract(1, "day").endOf("day").toDate(),
        className: "card-head-5",
      },
      {
        key: "last_7_days",
        name: "Last 7 Days",
        start_date: moment().subtract(7, "days").startOf("day").toDate(),
        end_date: moment().subtract(1, "day").endOf("day").toDate(),
        className: "card-head",
      },
      {
        key: "last_14_days",
        name: "Last 14 Days",
        start_date: moment().subtract(14, "days").startOf("day").toDate(),
        end_date: moment().subtract(1, "day").endOf("day").toDate(),
        className: "card-head-5",
      },
      {
        key: "last_30_days",
        name: "Last 30 Days",
        start_date: moment().subtract(30, "days").startOf("day").toDate(),
        end_date: moment().subtract(1, "day").endOf("day").toDate(),
        className: "card-head",
      },
    ],
  },
  {
    key: "This Week / Last Week / 2 Week Ago / 3 Week Ago",
    value: [
      {
        key: "this_week",
        name: "This Week",
        start_date: moment().startOf("week").toDate(),
        end_date: moment().endOf("week").toDate(),
        className: "card-head",
      },
      {
        key: "last_week",
        name: "Last Week",
        start_date: moment().startOf(1, "week").toDate(),
        end_date: moment().endOf(1, "week").toDate(),
        className: "card-head-5",
      },
      {
        key: "2_weeks_ago",
        name: "2 Weeks Ago",
        start_date: moment().startOf(2, "week").toDate(),
        end_date: moment().endOf(2, "week").toDate(),
        className: "card-head",
      },
      {
        key: "3_weeks_ago",
        name: "3 Weeks Ago",
        start_date: moment().startOf(3, "week").toDate(),
        end_date: moment().endOf(3, "week").toDate(),
        className: "card-head-5",
      },
    ],
  },
  {
    key: "Today / Yesterday / 2 Days Ago / 3 Days Ago",
    value: [
      {
        key: "today",
        name: "Today",
        start_date: moment().startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
        className: "card-head",
      },
      {
        key: "yesterday",
        name: "Yesterday",
        start_date: moment().subtract(1, "day").startOf("day").toDate(),
        end_date: moment().subtract(1, "day").endOf("day").toDate(),
        className: "card-head-5",
      },
      {
        key: "2_days_ago",
        name: "2 Days Ago",
        start_date: moment().subtract(2, "day").startOf("day").toDate(),
        end_date: moment().subtract(2, "day").endOf("day").toDate(),
        className: "card-head",
      },
      {
        key: "3_days_ago",
        name: "3 Days Ago",
        start_date: moment().subtract(3, "day").startOf("day").toDate(),
        end_date: moment().subtract(3, "day").endOf("day").toDate(),
        className: "card-head-5",
      },
    ],
  },
  {
    key: "Today / Yesterday / 7 Days Ago / 8 Days Ago",
    value: [
      {
        key: "today",
        name: "Today",
        start_date: moment().startOf("day").toDate(),
        end_date: moment().endOf("day").toDate(),
        className: "card-head",
      },
      {
        key: "yesterday",
        name: "Yesterday",
        start_date: moment().subtract(1, "day").startOf("day").toDate(),
        end_date: moment().subtract(1, "day").endOf("day").toDate(),
        className: "card-head-5",
      },
      {
        key: "7_days_ago",
        name: "7 Days Ago",
        start_date: moment().subtract(7, "days").startOf("day").toDate(),
        end_date: moment().subtract(7, "day").endOf("day").toDate(),
        className: "card-head",
      },
      {
        key: "8_days_ago",
        name: "8 Days Ago",
        start_date: moment().subtract(8, "days").startOf("day").toDate(),
        end_date: moment().subtract(8, "day").endOf("day").toDate(),
        className: "card-head-5",
      },
    ],
  },
  {
    key: "This Quater / Last Quater / 2 Quaters Ago / 3 Quaters Ago",
    value: [
      {
        key: "this_quater",
        name: "This Quater",
        start_date: moment().startOf("quarter").toDate(),
        end_date: moment().endOf("quarter").toDate(),
        className: "card-head",
      },
      {
        key: "last_quater",
        name: "Last Quater",
        start_date: moment().subtract(1, "quarter").startOf("quarter").toDate(),
        end_date: moment().subtract(1, "quarter").endOf("quarter").toDate(),
        className: "card-head-5",
      },
      {
        key: "2_quaters_ago",
        name: "2 Quaters Ago",
        start_date: moment().subtract(2, "quarter").startOf("quarter").toDate(),
        end_date: moment().subtract(2, "quarter").endOf("quarter").toDate(),
        className: "card-head",
      },
      {
        key: "3_quaters_ago",
        name: "3 Quaters Ago",
        start_date: moment().subtract(3, "quarter").startOf("quarter").toDate(),
        end_date: moment().subtract(3, "quarter").endOf("quarter").toDate(),
        className: "card-head-5",
      },
    ],
  },
];

const chartFilter = [
  {
    key: moment().startOf("month").format("MMMM"),
    value: "current_month",
  },
  {
    key: moment().subtract(1, "month").startOf("month").format("MMMM"),
    value: "last_month",
  },
  {
    key: moment().subtract(2, "month").startOf("month").format("MMMM"),
    value: "2_months_ago",
  },
];

const dateRangeOptions = {
  Today: [moment().toDate(), moment().toDate()],
  Yesterday: [
    moment().subtract(1, "days").toDate(),
    moment().subtract(1, "days").toDate(),
  ],
  "Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
  "Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
  "This Month": [moment().startOf("month").toDate(), moment().toDate()],
  "Last Month": [
    moment().subtract(1, "month").startOf("month").toDate(),
    moment().subtract(1, "month").endOf("month").toDate(),
  ],
  "Last 3 Month": [
    moment().subtract(3, "month").startOf("month").toDate(),
    moment().subtract(1, "month").endOf("month").toDate(),
  ],
  "Last 6 Month": [
    moment().subtract(6, "month").startOf("month").toDate(),
    moment().subtract(1, "month").endOf("month").toDate(),
  ],
};

const ProductMasterList = () => {
  const { t } = useTranslation();
  const search = useLocation().search;
  const tab = new URLSearchParams(search).get("tab") || "tiles";
  const { dispatch } = useContext(Context);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(filterArr[0]);
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    start_date: moment().subtract(30, "days").startOf("day").toDate(),
    end_date: moment().subtract(1, "day").endOf("day").toDate(),
  });
  const [searchDisabled, setSearchDisabled] = useState(4);
  const [passSelectedFilter, setPassSelectedFilter] = useState(filterArr[0]);
  const [productCount, setProductCount] = useState(0);
  const [currentStore, setCurrentStore] = useState("");
  const [summaryData, setSummaryData] = useState(null);
  const [items, setItems] = useState([]);
  const [orderPagination, setOrderPagination] = useState({
    limit: 10,
    page: 1,
    recordsCount: 0,
    pageCount: 0,
  });
  const [showPrftRight, setShowPrftRight] = useState(false);
  const [dropdownClass, setDropdownClass] = useState("dropdown-menu-right");

  const [storeTrendMonth, setStoreTrendMonth] = useState(chartFilter?.[0]);
  const [reinit, setReinit] = useState(0);
  const [showShippingBreakdown, setShowShippingBreakdown] = useState(false);
  const [showFeeBreakdown, setShowFeeBreakdown] = useState(false);
  const [showReimbursementBreakdown, setShowReimbursementBreakdown] =
    useState(false);
  const [showRefundBreakdown, setShowRefundBreakdown] = useState(false);
  const [showCOGsBreakdown, setShowCOGsBreakdown] = useState(false);
  const [colClass, setColClass] = useState("prft-left");

  const [bestSellerDateRange, setBestSellerDateRange] = useState({
    start_date: moment().subtract(30, "days").startOf("day").toDate(),
    end_date: moment().subtract(1, "day").endOf("day").toDate(),
  });

  const periods = [
    { key: "yesterday", label: t("dashboard.yesterday") },
    { key: "last_7_days", label: "7 day" },
    { key: "last_30_days", label: "30 day" },
    { key: "last_60_days", label: "60 day" },
    { key: "last_90_days", label: "90 day" },
    { key: "month_to_date", label: t("dashboard.month_to_date") },
    { key: "year_to_date", label: t("dashboard.year_to_date") },
  ];

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const latestRef = useRef(0);
  const [searchPage, setSearchPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemsSearched, setItemsSearched] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    getStores();
  }, []);

  useEffect(() => {
    if (currentStore) getSummaryReport();
  }, [currentStore]);

  useEffect(() => {
    if (currentStore) todaysOrders();
  }, [orderPagination.page, orderPagination.limit, currentStore]);

  async function getStores() {
    try {
      dispatch({ type: "loader_show", response: true });
      let response = await Axios.get("/api/dashboard/stores").catch((e) =>
        console.error("Axios-Catch: " + e.message)
      );
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200) {
        setLoadedOnce(true);
        response.data.data.stores.map((v) => {
          v.value = v._id;
          v.label = v.name;
          return v;
        });
        setStores(response.data.data.stores);
        let storeIndex = 0;
        if (response.data?.data?.default_store) {
          storeIndex = response.data.data.stores.findIndex(
            (n) => n._id == response.data.data.default_store
          );
        }
        setCurrentStore(response.data.data.stores[storeIndex]);
        setProductCount(response.data?.data?.productCount);
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  }

  async function getSummaryReport() {
    try {
      dispatch({ type: "loader_show", response: true });
      let response = await Axios.post("/api/dashboard/summary", {
        store_id: currentStore._id,
        store_trend_key: "current_month",
      }).catch((e) => console.error("Axios-Catch: " + e.message));
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200) {
        response.data?.data?.order_detail && response.data?.data?.best_seller
          ? setSummaryData(response.data?.data)
          : setSummaryData([]);
        setProductCount(
          response.data?.data?.productCount
            ? response.data.data.productCount
            : 0
        );
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  }

  async function todaysOrders() {
    try {
      dispatch({ type: "loader_show", response: true });
      let response = await Axios.get(
        `/api/orders?store_id=${currentStore._id}&page=${orderPagination.page}&limit=${orderPagination.limit}`
      ).catch((e) => console.error("Axios-Catch: " + e.message));
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200 && response.data?.data) {
        setOrderPagination((pre) => ({
          ...pre,
          page: response.data.data.currentPage,
          pageCount: response.data.data.totalPages,
          recordsCount: response.data.data.recordsCount,
        }));
        setItems(response.data.data.results);
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  }

  async function getBestSeller(obj) {
    try {
      dispatch({ type: "loader_show", response: true });
      let response = await Axios.post("/api/dashboard/best-sellers", {
        store_id: currentStore._id,
        ...obj,
      }).catch((e) => console.error("Axios-Catch: " + e.message));
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200) {
        let summaryObj = { ...summaryData };
        summaryObj.best_seller.value.last_30_days = response.data.data;
        setSummaryData(summaryObj);
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  }

  async function getStoreTrend(store_trend_key) {
    try {
      dispatch({ type: "loader_show", response: true });
      let response = await Axios.post("/api/dashboard/store-trand", {
        store_id: currentStore._id,
        store_trend_key: store_trend_key,
      }).catch((e) => console.error("Axios-Catch: " + e.message));

      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200) {
        let summaryObj = { ...summaryData };
        summaryObj.store_trend = response.data.data;
        setSummaryData(summaryObj);
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  }

  const handlePageClick = (event) => {
    setOrderPagination((pre) => ({
      ...pre,
      page: event.selected + 1,
    }));
  };

  const bestSellerDateChange = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
    setBestSellerDateRange({
      start_date: moment(picker.startDate).utc().format(),
      end_date: moment(picker.endDate).utc().format(),
    });
    getBestSeller({
      start_date: moment(picker.startDate).utc().format(),
      end_date: moment(picker.endDate).utc().format(),
    });
  };

  const selectedDateChange = (event, picker) => {
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
    setSelectedDateFilter({
      start_date: moment(picker.startDate).utc().format(),
      end_date: moment(picker.endDate).utc().format(),
    });
    setSelectedFilter({
      key: "custom",
      value: [
        {
          name: "Custom Range",
          start_date: moment(picker.startDate).utc().format(),
          end_date: moment(picker.endDate).utc().format(),
        },
      ],
    });
  };

  async function handleStoreTrendFilter(e) {
    setStoreTrendMonth({ key: e.target.key, value: e.target.value });
    getStoreTrend(e.target.value);
  }

  const handleCustomClick = (boolean) => {
    setShowPrftRight(boolean);
    setDropdownClass(
      !boolean ? "dropdown-menu-right" : "dropdown-menu-right date-active"
    );
    setColClass(!boolean ? "col-lg-12" : "col-lg-5");
  };

  const renderPeriodData = (key, property, formatter = (val) => val) => {
    let showMinus = ["ppc_cost"];
    return summaryData?.order_detail?.value?.[key]?.current?.[property]
      ? showMinus.includes(property)
        ? (summaryData?.order_detail?.value?.[key]?.current?.[property]
          ? "-"
          : "") +
        formatter(parseFloat(summaryData.order_detail.value[key].current[property]).toFixed(2))
        : formatter(parseFloat(summaryData.order_detail.value[key].current[property]).toFixed(2))
      : "0";
  };

  async function loadProducts(currentPage) {
    const requestId = ++latestRef.current;
    try {
      let response = await Axios.post(`/api/dashboard/search-products`, {
        search: searchKey,
        store_id: currentStore._id,
        page: currentPage,
        sku_id: itemsSearched.map((v) => v?.sku_id),
        limit: 10,
      }).catch((e) => console.error("Axios-Catch: " + e.message));

      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });
      setSearchPage(currentPage);
      if (
        response &&
        response.status === 200 &&
        Array.isArray(response?.data?.data?.records) &&
        requestId === latestRef.current
      ) {
        let filteredArr = [],
          dataArray =
            currentPage === 1
              ? [...itemsSearched, ...response?.data?.data?.records]
              : [...searchItems, ...response?.data?.data?.records];
        dataArray.map((v) => {
          if (filteredArr.filter((n) => n?._id === v?._id).length === 0) {
            filteredArr.push(v);
          }
        });
        setSearchItems(filteredArr);
        setHasMore(response?.data?.data?.records?.length >= 10);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setHasMore(false);
      console.error("Error-Catch: " + error.message);
    }
  }

  useEffect(() => {
    setHasMore(true);
    if (currentStore) loadProducts(1);
  }, [searchKey, currentStore]);

  const handleOptionChange = (product) => {
    setItemsSearched((prev) => {
      if (prev.find((v) => v?._id === product?._id)) {
        return prev.filter((opt) => opt?._id !== product?._id);
      } else {
        return [...prev, product];
      }
    });
  };

  const handleDropdownToggle = () => {
    if (itemsSearched.length > 0 && !isDropdownVisible) {
      let selectedItems = [],
        notSelectedItems = [];
      for (let i = 0; i < searchItems.length; i++) {
        const element = searchItems[i];
        if (itemsSearched.find((n) => n?._id === element?._id)) {
          selectedItems.push(element);
        } else {
          notSelectedItems.push(element);
        }
      }

      setSearchItems([...selectedItems, ...notSelectedItems]);
    }
    setIsDropdownVisible(true);
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest(".multi-select-dropdown")) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (e) => {
    if (!e.target.closest(".dropdowna")) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  const dropdownMenuRef = useRef(null);

  const handleOpen = () => {
    // Delay to allow the DOM to fully render
    setTimeout(() => {
      const daterangepickerEl = document.querySelector(".daterangepicker");
      if (daterangepickerEl && dropdownMenuRef.current) {
        dropdownMenuRef.current.style.height = `410px`;
      }
    }, 0); // Delay of 0ms ensures it runs after the current event loop
  };

  const handleClose = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.style.height = "auto"; // Reset height
    }
  };

  async function resetStates() {
    setReinit(0);
    setSelectedFilter(filterArr[0]);
    setPassSelectedFilter(filterArr[0]);
    setSearchDisabled(4)
    setShowPrftRight(false);
    setDropdownClass("dropdown-menu-right");
    setStoreTrendMonth(chartFilter?.[0]);
    setShowCOGsBreakdown(false);
    setShowRefundBreakdown(false);
    setShowReimbursementBreakdown(false);
    setShowShippingBreakdown(false);
    setShowFeeBreakdown(false);
    setColClass("prft-left");
    setBestSellerDateRange({
      start_date: moment().subtract(30, "days").startOf("day").toDate(),
      end_date: moment().subtract(1, "day").endOf("day").toDate(),
    });
    setItemsSearched([]);
    setSelectedItems([]);
    setSearchKey(searchKey + " ");
  }

  return (
    <>
      {stores.length != 0 && (
        <>
          <div className="revenue-section">
            <Row className="align-items-center mb-3">
              <Col md="9" className="text-center text-sm-left"></Col>
              <Col md="3" className="">
                <div className="dropdown product-rating-menu mr-2">
                  <Select
                    closeMenuOnSelect={true}
                    defaultValue={currentStore}
                    options={stores}
                    onChange={(e) => setCurrentStore(e)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {productCount != 0 && (
            <div className="custom-tab-1">
              <Tab.Container defaultActiveKey={tab}>
                <Nav as="ul" className="nav-tabs mb-0 bg-white align-items-end">
                  <Nav.Item as="li" key="tiles">
                    <Nav.Link eventKey="tiles" as={Link} to="?tab=tiles">
                      <i className="flaticon-381-layer-1"></i> Tiles
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="charts">
                    <Nav.Link eventKey="charts" as={Link} to="?tab=charts">
                      <i className="fa fa-bar-chart" aria-hidden="true"></i>{" "}
                      Charts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="pnl">
                    <Nav.Link eventKey="pnl" as={Link} to="?tab=pnl">
                      <i className="fa fa-usd" aria-hidden="true"></i> P&L
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  {tab === "tiles" ? (
                    <Tab.Pane eventKey="tiles" key="tiles">
                      <div className="mt-4 mb-4">
                        <div className="d-flex">
                          <div className="multi-select-dropdown">
                            <div
                              className="search-wrap"
                              onClick={handleDropdownToggle}
                            >
                              <input
                                type="text"
                                id="search-box"
                                placeholder="Search here"
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                              />
                              <div className="selected-options">
                                {itemsSearched.length > 0 ? (
                                  <>
                                    {" "}
                                    <span>
                                      {itemsSearched.length} options selected
                                    </span>
                                    <button
                                      className="px-2 py-0 mx-2 btn "
                                      onClick={() => {
                                        setItemsSearched([]);
                                        setSearchKey(searchKey + " ");
                                      }}
                                    >
                                      X
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            {isDropdownVisible && (
                              <div
                                className="dropdown-content dataTables_wrapper"
                                id="dropdown-content"
                              >
                                <InfiniteScrollWrapper
                                  parentDivId="dropdown-content"
                                  isInfiniteScrollOn={true}
                                  lengthData={searchItems.length}
                                  functionNext={() =>
                                    loadProducts(searchPage + 1)
                                  }
                                  hasMore={hasMore}
                                >
                                  {searchItems.map((v, i) => (
                                    <label key={v?._id}>
                                      <input
                                        type="checkbox"
                                        className="multi-select-option"
                                        value={v?._id}
                                        checked={
                                          itemsSearched.find(
                                            (n) => n?._id === v?._id
                                          )?._id
                                        }
                                        onChange={() => handleOptionChange(v)}
                                      />
                                      <img
                                        src={
                                          v?.main_images?.[0]?.urls?.[0] ||
                                          noImage
                                        }
                                        alt="icon"
                                        className="icon"
                                      />
                                      {v?.title}/{v?.sku_id}
                                    </label>
                                  ))}
                                </InfiniteScrollWrapper>
                              </div>
                            )}
                          </div>
                          <div className="profit-datepicker">
                            <div className="btn-group">
                              <div className="dropdowna ms-1">
                                <button
                                  className="dropdown-togglea dropdown-toggle text-nowrap"
                                  onClick={toggleDropdown}
                                >
                                  <i
                                    className="fa fa-calendar-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Period
                                </button>
                                {isOpen && (
                                  <div
                                    ref={dropdownMenuRef}
                                    className={`dropdown-menua p-3 ${dropdownClass}`}
                                  >
                                    <div className="row">
                                      <div className={`prft-left ${colClass}`}>
                                        <ul>
                                          {filterArr.map((v, i) => (
                                            <li
                                              key={i}
                                              onClick={() => {
                                                setSelectedFilter(v);
                                                handleCustomClick(false);
                                              }}
                                              className={`dropdown-item ${selectedFilter.key === v.key ? "text-primary" : ""}`}
                                            >
                                              {v.key}
                                            </li>
                                          ))}
                                          <li
                                            className={`dropdown-item ${selectedFilter.key === "custom" ? "text-primary" : ""}`}
                                            onClick={() =>
                                              handleCustomClick(true)
                                            }
                                            id="prft-custom"
                                          >
                                            Custom
                                          </li>
                                        </ul>
                                      </div>
                                      {showPrftRight && (
                                        <div
                                          className="col-lg-7 position-relative"
                                          id="prft-right"
                                        >
                                          <div
                                            style={{ position: "relative" }}
                                            className="custom-daterangepicker"
                                          >
                                            <div>
                                              <DateRangePicker
                                                onApply={selectedDateChange}
                                                initialSettings={{
                                                  startDate: moment(
                                                    new Date(
                                                      selectedDateFilter.start_date
                                                    )
                                                  ).format("MM/DD/YYYY"),
                                                  endDate: moment(
                                                    new Date(
                                                      selectedDateFilter.end_date
                                                    )
                                                  ).format("MM/DD/YYYY"),
                                                  ranges: dateRangeOptions,
                                                }}
                                                onShow={handleOpen} // Fired when the daterangepicker is shown
                                                onHide={handleClose} // Fired when the daterangepicker is hidden
                                              >
                                                <input
                                                  type="text"
                                                  className="form-control form-control-sm input-daterange-timepicker w-100 "
                                                  placeholder="Search Date"
                                                />
                                              </DateRangePicker>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <button
                                className="btn btn-primary btn-sm ms-1 rounded-1"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => {
                                  setPassSelectedFilter(selectedFilter);
                                  setSearchDisabled(selectedFilter?.value?.length)
                                  setSelectedItems(itemsSearched);
                                  setReinit(reinit + 1);
                                }}
                                disabled={searchDisabled}
                              >
                                Search
                              </button>
                              <button
                                className="btn btn-primary btn-sm ms-1 rounded-1"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={resetStates}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-box w-100">
                        <Swiper
                          spaceBetween={8}
                          breakpoints={{
                            1200: {
                              slidesPerView:
                                passSelectedFilter?.value?.length > 4 ? 5 : 4,
                            },
                            992: { slidesPerView: 3 },
                            768: { slidesPerView: 2 },
                            0: {
                              slidesPerView: 1.4,
                              centeredSlides: true,
                              centeredSlidesBounds:
                                currentIndex === 0 ||
                                currentIndex ===
                                passSelectedFilter?.value?.length - 1,
                            },
                          }}
                          onChange={(e) => setCurrentIndex(e?.activeIndex)}
                          grabCursor
                          key={`${passSelectedFilter?.value?.length}-data`}
                          className="w-100"
                          wrapperClass="w-100"
                          style={{ zIndex: -1 }}
                        >
                          {passSelectedFilter?.value?.map((v, i) => (
                            <SwiperSlide key={i}>
                              <div className="h-100 px-2 w-100">
                                <StorePerformance
                                  reinit={reinit}
                                  currentStore={currentStore}
                                  propsData={v}
                                  skuArr={selectedItems}
                                  setSearchDisabled={setSearchDisabled}
                                />
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Tab.Pane>
                  ) : null}
                  {tab === "charts" ? (
                    <Tab.Pane eventKey="charts" key="charts">
                      <div className="mt-4">
                        <div className="row">
                          <div className="col-lg-6">
                            <Card className="border-0 rounded-0 w-100 me-4">
                              <Card.Body className="p-0">
                                <div className="card-head p-3 text-white bg-primary d-flex justify-content-between align-items-center">
                                  <p className="m-0 p-0 lh-base">
                                    <strong>Store Trends</strong>
                                  </p>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="w-auto"
                                    size="sm"
                                    value={storeTrendMonth?.value}
                                    defaultValue={storeTrendMonth?.value}
                                    onChange={handleStoreTrendFilter}
                                  >
                                    {chartFilter.map((v, i) => (
                                      <option key={v.key} value={v.value}>
                                        {v.key}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                                <div className="card-bd p-3">
                                  {summaryData?.store_trend && (
                                    <UnitSoldChart
                                      data={summaryData?.store_trend}
                                    />
                                  )}
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                          <div className="col-lg-6">
                            <Card className="border-0 rounded-0 w-100 me-4">
                              <Card.Body className="p-0">
                                <div className="card-head p-3 text-white bg-primary d-flex justify-content-between align-items-center">
                                  <p className="m-0 p-0 lh-base">
                                    <strong>Store Trends</strong>
                                  </p>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="w-auto"
                                    size="sm"
                                    value={storeTrendMonth?.value}
                                    defaultValue={storeTrendMonth?.value}
                                    onChange={handleStoreTrendFilter}
                                  >
                                    {chartFilter.map((v, i) => (
                                      <option key={v.key} value={v.value}>
                                        {v.key}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </div>
                                <div className="card-bd p-3">
                                  {summaryData?.store_trend && (
                                    <NetProfitChart
                                      data={summaryData?.store_trend}
                                    />
                                  )}
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  ) : null}
                  {tab === "pnl" ? (
                    <div className="mt-4">
                      <Tab.Pane key="pnl" eventKey="pnl">
                        {" "}
                        <div className="store-summary-section">
                          <Row>
                            <Col>
                              <Card>
                                <Card.Body className="p-2">
                                  <Table responsive className="w-100">
                                    <thead>
                                      <tr>
                                        <th className="text-center">
                                          <b>{t("dashboard.period")}</b>
                                          <br />
                                          <p className="fs-12"> </p>
                                        </th>
                                        {periods.map((period) => (
                                          <td
                                            className="text-center"
                                            key={period.key}
                                          >
                                            <b>{period.label}</b>
                                            <br />
                                            <p className="fs-12">
                                              {summaryData?.order_detail
                                                ?.value?.[period.key]
                                                ?.start_date
                                                ? moment(summaryData.order_detail.value[period.key].start_date).format("MM/DD/YYYY")
                                                : "--/--/--"}{" "}
                                              TO{" "}
                                              {summaryData?.order_detail
                                                ?.value?.[period.key]?.end_date
                                                ? moment(summaryData.order_detail.value[period.key].end_date).format("MM/DD/YYYY")
                                                : "--/--/--"}
                                            </p>
                                          </td>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_payment",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>
                                            {t("dashboard.gross_revenue")}
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_payment",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_discount",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>
                                            {t("dashboard.seller_discount")}
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_discount",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_shipping_price",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th
                                            onClick={() =>
                                              setShowShippingBreakdown(
                                                !showShippingBreakdown
                                              )
                                            }
                                          >
                                            <div className="d-flex align-items-center">
                                              <div>
                                                {t("dashboard.shipping")}
                                              </div>
                                              <div>
                                                &nbsp;&nbsp;&nbsp;
                                                {!showShippingBreakdown
                                                  ? "+"
                                                  : "-"}
                                              </div>
                                            </div>
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_shipping_price",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}
                                      {showShippingBreakdown ? (
                                        <>
                                          {[
                                            "total_customer_paid_shipping_fee_amount",
                                            "total_platform_shipping_fee_discount_amount",
                                            "total_customer_shipping_fee_offset_amount",
                                            "total_fbt_fulfillment_fee_amount",
                                            "total_shipping_fee_subsidy_amount",
                                            "total_customer_estimated_paid_shipping",
                                          ].map((field, index) =>
                                            periods.find(
                                              (period) =>
                                                !!parseFloat(
                                                  renderPeriodData(
                                                    period.key,
                                                    field,
                                                    formattedNumber
                                                  )
                                                )
                                            ) ? (
                                              <tr key={index}>
                                                <th className="pl-5">
                                                  {t(`dashboard.${field}`)}
                                                </th>
                                                {periods.map((period) => (
                                                  <td
                                                    className="text-center"
                                                    key={period.key}
                                                  >
                                                    ${" "}
                                                    {renderPeriodData(
                                                      period.key,
                                                      field,
                                                      formattedNumber
                                                    )}
                                                  </td>
                                                ))}
                                              </tr>
                                            ) : null
                                          )}
                                        </>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_item_fees",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th
                                            onClick={() =>
                                              setShowFeeBreakdown(
                                                !showFeeBreakdown
                                              )
                                            }
                                          >
                                            <div className="d-flex align-items-center">
                                              <div>{t("dashboard.fee")}</div>
                                              <div>
                                                &nbsp;&nbsp;&nbsp;
                                                {!showFeeBreakdown ? "+" : "-"}
                                              </div>
                                            </div>
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_item_fees",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}
                                      {showFeeBreakdown ? (
                                        <>
                                          {[
                                            "total_referral_fee_amount",
                                            "total_affiliate_commission_amount",
                                            "total_affiliate_ads_commission_amount",
                                          ].map((field, index) =>
                                            periods.find(
                                              (period) =>
                                                !!parseFloat(
                                                  renderPeriodData(
                                                    period.key,
                                                    field,
                                                    formattedNumber
                                                  )
                                                )
                                            ) ? (
                                              <tr key={index}>
                                                <th className="pl-5">
                                                  {t(`dashboard.${field}`)}
                                                </th>
                                                {periods.map((period) => (
                                                  <td
                                                    className="text-center"
                                                    key={period.key}
                                                  >
                                                    ${" "}
                                                    {renderPeriodData(
                                                      period.key,
                                                      field,
                                                      formattedNumber
                                                    )}
                                                  </td>
                                                ))}
                                              </tr>
                                            ) : null
                                          )}
                                        </>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_reimbursement",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th
                                            onClick={() =>
                                              setShowReimbursementBreakdown(
                                                !showReimbursementBreakdown
                                              )
                                            }
                                          >
                                            <div className="d-flex align-items-center">
                                              <div>
                                                {t(
                                                  "dashboard.total_reimbursement"
                                                )}
                                              </div>
                                              <div>
                                                &nbsp;&nbsp;&nbsp;
                                                {!showReimbursementBreakdown
                                                  ? "+"
                                                  : "-"}
                                              </div>
                                            </div>
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_reimbursement",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}
                                      {showReimbursementBreakdown ? (
                                        <>
                                          {[
                                            "total_platform_reimbursement",
                                            "total_logistics_reimbursement",
                                          ].map((field, index) =>
                                            periods.find(
                                              (period) =>
                                                !!parseFloat(
                                                  renderPeriodData(
                                                    period.key,
                                                    field,
                                                    formattedNumber
                                                  )
                                                )
                                            ) ? (
                                              <tr key={index}>
                                                <th className="pl-5">
                                                  {t(`dashboard.${field}`)}
                                                </th>
                                                {periods.map((period) => (
                                                  <td
                                                    className="text-center"
                                                    key={period.key}
                                                  >
                                                    ${" "}
                                                    {renderPeriodData(
                                                      period.key,
                                                      field,
                                                      formattedNumber
                                                    )}
                                                  </td>
                                                ))}
                                              </tr>
                                            ) : null
                                          )}
                                        </>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_cost",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th
                                            onClick={() =>
                                              setShowCOGsBreakdown(
                                                !showCOGsBreakdown
                                              )
                                            }
                                          >
                                            <div className="d-flex align-items-center">
                                              <div>{t("dashboard.cogs")}</div>
                                              <div>
                                                &nbsp;&nbsp;&nbsp;
                                                {!showCOGsBreakdown ? "+" : "-"}
                                              </div>
                                            </div>
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_cost",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}
                                      {showCOGsBreakdown ? (
                                        <>
                                          {["order_cogs", "sample_cogs"].map(
                                            (field, index) =>
                                              periods.find(
                                                (period) =>
                                                  !!parseFloat(
                                                    renderPeriodData(
                                                      period.key,
                                                      field,
                                                      formattedNumber
                                                    )
                                                  )
                                              ) ? (
                                                <tr key={index}>
                                                  <th className="pl-5">
                                                    {t(`dashboard.${field}`)}
                                                  </th>
                                                  {periods.map((period) => (
                                                    <td
                                                      className="text-center"
                                                      key={period.key}
                                                    >
                                                      ${" "}
                                                      {renderPeriodData(
                                                        period.key,
                                                        field,
                                                        formattedNumber
                                                      )}
                                                    </td>
                                                  ))}
                                                </tr>
                                              ) : null
                                          )}
                                        </>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_refund",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th
                                            onClick={() =>
                                              setShowRefundBreakdown(
                                                !showRefundBreakdown
                                              )
                                            }
                                          >
                                            <div className="d-flex align-items-center">
                                              <div>{t("dashboard.refund")}</div>
                                              <div>
                                                &nbsp;&nbsp;&nbsp;
                                                {!showRefundBreakdown
                                                  ? "+"
                                                  : "-"}
                                              </div>
                                            </div>
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_refund",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}
                                      {showRefundBreakdown ? (
                                        <>
                                          {[
                                            "total_payment_refund",
                                            "total_discount_refund",
                                            "total_customer_paid_shipping_fee_amount_refund",
                                            "total_platform_shipping_fee_discount_amount_refund",
                                            "total_customer_shipping_fee_offset_amount_refund",
                                            "total_fbt_fulfillment_fee_amount_refund",
                                            "total_shipping_fee_subsidy_amount_refund",
                                            "total_referral_fee_amount_refund",
                                            "total_affiliate_commission_amount_refund",
                                            "total_affiliate_ads_commission_amount_refund",
                                            "total_administration_fee_amount_refund",
                                          ].map((field, index) =>
                                            periods.find(
                                              (period) =>
                                                !!parseFloat(
                                                  renderPeriodData(
                                                    period.key,
                                                    field,
                                                    formattedNumber
                                                  )
                                                )
                                            ) ? (
                                              <tr key={index}>
                                                <th className="pl-5">
                                                  {t(`dashboard.${field}`)}
                                                </th>
                                                {periods.map((period) => (
                                                  <td
                                                    className="text-center"
                                                    key={period.key}
                                                  >
                                                    ${" "}
                                                    {renderPeriodData(
                                                      period.key,
                                                      field,
                                                      formattedNumber
                                                    )}
                                                  </td>
                                                ))}
                                              </tr>
                                            ) : null
                                          )}
                                        </>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "ppc_cost",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>{t("dashboard.ppc_cost")}</th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "ppc_cost",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "other_expenses",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>
                                            {t("dashboard.other_expenses")}
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "other_expenses",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "cancelled_orders",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>
                                            {t("dashboard.cancelled_orders")}
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "cancelled_orders",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_fbt_warehouse_service_fee",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>
                                            {t(
                                              "dashboard.total_fbt_warehouse_service_fee"
                                            )}
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_fbt_warehouse_service_fee",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_reserve",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>
                                            {t("dashboard.total_reserve")}
                                          </th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "total_reserve",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "net_profit",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>{t("dashboard.net_profit")}</th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              ${" "}
                                              {renderPeriodData(
                                                period.key,
                                                "net_profit",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "order_count",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>{t("dashboard.orders")}</th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              {renderPeriodData(
                                                period.key,
                                                "order_count",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      {periods.find(
                                        (period) =>
                                          !!parseFloat(
                                            renderPeriodData(
                                              period.key,
                                              "total_quantity_sold",
                                              formattedNumber
                                            )
                                          )
                                      ) ? (
                                        <tr>
                                          <th>{t("dashboard.units_sold")}</th>
                                          {periods.map((period) => (
                                            <td
                                              className="text-center"
                                              key={period.key}
                                            >
                                              {renderPeriodData(
                                                period.key,
                                                "total_quantity_sold",
                                                formattedNumber
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ) : null}

                                      <tr>
                                        <th>{t("dashboard.margin")}</th>
                                        {periods.map((period) => (
                                          <td
                                            className="text-center"
                                            key={period.key}
                                          >
                                            {summaryData?.order_detail?.value?.[period.key]?.current?.total_payment &&
                                              summaryData?.order_detail?.value?.[period.key]?.current?.total_payment != 0
                                              ? formattedNumber(((parseFloat(summaryData.order_detail.value[period.key].current.profit_loss) / parseFloat(summaryData.order_detail.value[period.key].current.total_payment)) * 100).toFixed(2)) + " %"
                                              : "-"}
                                          </td>
                                        ))}
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane>
                    </div>
                  ) : null}

                  <div className="custom-tab-1 bg-white">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="todaysorders"
                    >
                      <Nav as="ul" className="nav-tabs mb-0 bg-white">
                        <Nav.Item>
                          <Nav.Link eventKey="todaysorders" className="">
                            <i
                              className="fa fa-shopping-cart"
                              aria-hidden="true"
                            ></i>{" "}
                            Today’s Orders
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="bestsellers" className="">
                            <i className="fa fa-star-o" aria-hidden="true"></i>{" "}
                            Best Sellers
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="todaysorders">
                          <Row className="align-items-center p-2">
                            <Col
                              md="6"
                              className="text-center text-sm-left"
                            ></Col>
                            <Col md="6" className="text-center text-sm-right">
                              <Button variant="link" className="p-0" size="xs">
                                <Link
                                  className="btn btn-primary btn-xs px-3 text-white"
                                  to={`/tiktok-store/orders?start_date=${todaysDate}&end_date=${todaysDate}`}
                                  target="_blank"
                                >
                                  See More
                                </Link>
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Card>
                                <Card.Body className="p-0">
                                  <OrdersTable
                                    items={items}
                                    orderPagination={orderPagination}
                                    handlePageClick={handlePageClick}
                                  />
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="bestsellers">
                          <div className="bestsellers-section">
                            <Row className="align-items-center p-2">
                              <Col
                                md="6"
                                className="text-center text-sm-left"
                              ></Col>
                              <Col md="6" className="">
                                <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                                  <DateRangePicker
                                    onApply={bestSellerDateChange}
                                    initialSettings={{
                                      startDate: moment(
                                        new Date(bestSellerDateRange.start_date)
                                      ).format("MM/DD/YYYY"),
                                      endDate: moment(
                                        new Date(bestSellerDateRange.end_date)
                                      ).format("MM/DD/YYYY"),
                                      ranges: dateRangeOptions,
                                    }}
                                  >
                                    <input
                                      type="text"
                                      className="form-control w-auto form-control-sm input-daterange-timepicker pull-right"
                                    />
                                  </DateRangePicker>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Card>
                                  <Card.Body>
                                    <Table responsive className="w-100">
                                      <table id="example" className="w-100">
                                        <thead>
                                          <tr role="row">
                                            <th>{t("common.rank")}</th>
                                            <th>
                                              {t("dashboard.product_details")}
                                            </th>
                                            <th
                                              dangerouslySetInnerHTML={{
                                                __html: t(
                                                  "dashboard.avg_selling_price"
                                                ),
                                              }}
                                            ></th>
                                            <th
                                              dangerouslySetInnerHTML={{
                                                __html: t(
                                                  "dashboard.total_revenue"
                                                ),
                                              }}
                                            ></th>
                                            <th
                                              dangerouslySetInnerHTML={{
                                                __html: t(
                                                  "dashboard.seller_discount"
                                                ),
                                              }}
                                            ></th>
                                            <th
                                              dangerouslySetInnerHTML={{
                                                __html: t("dashboard.shipping"),
                                              }}
                                            ></th>
                                            <th
                                              dangerouslySetInnerHTML={{
                                                __html: t("dashboard.fee"),
                                              }}
                                            ></th>
                                            <th
                                              dangerouslySetInnerHTML={{
                                                __html: t("dashboard.refund"),
                                              }}
                                            ></th>
                                            <th>{t("dashboard.cogs")}</th>
                                            <th
                                              dangerouslySetInnerHTML={{
                                                __html: t(
                                                  "dashboard.net_profit_th"
                                                ),
                                              }}
                                            ></th>
                                            <th>{t("dashboard.margin")}</th>
                                            <th
                                              dangerouslySetInnerHTML={{
                                                __html: t(
                                                  "dashboard.units_sold_th"
                                                ),
                                              }}
                                            ></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {summaryData?.best_seller?.value?.last_30_days?.current?.map(
                                            (v, i) => (
                                              <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>
                                                  <div className="d-flex">
                                                    <div className="prd-img mr-3">
                                                      <OverlayTrigger
                                                        key="right"
                                                        trigger={[
                                                          "hover",
                                                          "focus",
                                                        ]}
                                                        placement="right"
                                                        rootClose
                                                        overlay={
                                                          <Popover>
                                                            <Popover.Body>
                                                              {v?.product
                                                                ?.main_images &&
                                                                v?.product
                                                                  ?.main_images
                                                                  ?.length >
                                                                0 &&
                                                                v?.product
                                                                  ?.main_images[0]
                                                                  ?.thumb_urls &&
                                                                v?.product
                                                                  ?.main_images[0]
                                                                  ?.thumb_urls
                                                                  .length >
                                                                0 && (
                                                                  <img
                                                                    src={
                                                                      v?.product
                                                                        .main_images[0]
                                                                        .thumb_urls[0]
                                                                    }
                                                                    alt=""
                                                                    width={500}
                                                                    className="prod-thum-img"
                                                                  />
                                                                )}
                                                            </Popover.Body>
                                                          </Popover>
                                                        }
                                                      >
                                                        <Button
                                                          variant="link"
                                                          className="p-0"
                                                          size="xs"
                                                        >
                                                          <Link
                                                            to={
                                                              "/products/" +
                                                              v?.product
                                                                ?.product_id +
                                                              "/details"
                                                            }
                                                            target="_blank"
                                                          >
                                                            {v?.product
                                                              ?.main_images &&
                                                              v?.product
                                                                ?.main_images
                                                                ?.length > 0 &&
                                                              v?.product
                                                                ?.main_images?.[0]
                                                                ?.thumb_urls &&
                                                              v?.product
                                                                ?.main_images?.[0]
                                                                ?.thumb_urls
                                                                ?.length >
                                                              0 && (
                                                                <img
                                                                  src={
                                                                    v?.product
                                                                      ?.main_images?.[0]
                                                                      ?.thumb_urls?.[0]
                                                                  }
                                                                  alt=""
                                                                  width={70}
                                                                  className="prod-thum-img"
                                                                />
                                                              )}
                                                          </Link>
                                                        </Button>
                                                      </OverlayTrigger>
                                                    </div>
                                                    <div className="prd-title">
                                                      <p>
                                                        <OverlayTrigger
                                                          overlay={
                                                            <Tooltip>
                                                              {
                                                                v?.product
                                                                  ?.title
                                                              }
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <Link
                                                            to={
                                                              "/products/" +
                                                              v?.product
                                                                ?.product_id +
                                                              "/details"
                                                            }
                                                            target="_blank"
                                                          >
                                                            <span>
                                                              {v?.product?.title
                                                                ? v?.product
                                                                  ?.title
                                                                  ?.length >
                                                                  50
                                                                  ? v?.product?.title.substring(
                                                                    0,
                                                                    50
                                                                  ) + "..."
                                                                  : v?.product
                                                                    ?.title
                                                                : "-"}
                                                            </span>
                                                          </Link>
                                                        </OverlayTrigger>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className="text-nowrap">
                                                  ${" "}
                                                  {parseFloat(v?.unit_sold) > 0
                                                    ? formattedNumber(
                                                      parseFloat(
                                                        parseFloat(
                                                          v?.total_payment
                                                        ) /
                                                        parseFloat(
                                                          v?.unit_sold
                                                        )
                                                      ).toFixed(2)
                                                    )
                                                    : "-"}
                                                </td>
                                                <td className="text-nowrap">
                                                  ${" "}
                                                  {formattedNumber(
                                                    parseFloat(
                                                      v?.total_payment || 0
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                                <td className="text-nowrap">
                                                  ${" "}
                                                  {formattedNumber(
                                                    parseFloat(
                                                      v?.total_discount || 0
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                                <td className="text-nowrap">
                                                  ${" "}
                                                  {formattedNumber(
                                                    parseFloat(
                                                      v?.total_shipping_price ||
                                                      0
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                                <td className="text-nowrap">
                                                  ${" "}
                                                  {formattedNumber(
                                                    parseFloat(
                                                      v?.total_item_fees || 0
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                                <td className="text-nowrap">
                                                  ${" "}
                                                  {formattedNumber(
                                                    parseFloat(
                                                      v?.total_refund || 0
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                                <td className="text-nowrap">
                                                  ${" "}
                                                  {formattedNumber(
                                                    parseFloat(
                                                      v?.total_cost || 0
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                                <td className="text-nowrap">
                                                  ${" "}
                                                  {formattedNumber(
                                                    parseFloat(
                                                      v?.net_profit || 0
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                                <td className="text-nowrap">
                                                  {v?.total_payment &&
                                                    v?.total_payment != 0
                                                    ? formattedNumber(
                                                      parseFloat(
                                                        (parseFloat(
                                                          v?.profit_loss
                                                        ) /
                                                          parseFloat(
                                                            v?.total_payment
                                                          )) *
                                                        100
                                                      ).toFixed(2)
                                                    ) + " %"
                                                    : "-"}
                                                </td>
                                                <td className="text-nowrap">
                                                  {formattedNumber(
                                                    parseFloat(
                                                      v?.unit_sold || 0
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </Table>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Content>
              </Tab.Container>
            </div>
          )}
          {productCount == 0 && loadedOnce && <StoreFetch />}
        </>
      )}
      {stores.length == 0 && loadedOnce && <ProfitLanding />}
    </>
  );
};

export default ProductMasterList;
