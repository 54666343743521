import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Context } from '../../../MasterLayout.js';
import Axios from '../../plugins/axios';
import './InvitationManagement.css';
import ProfitLanding from "./ProfitLanding.js";

const InvitationList = () => {
    const { dispatch } = useContext(Context);
    const [stores, setStores] = useState([]);
    const [currentStore, setCurrentStore] = useState("");
    const [invitations, setInvitations] = useState([]);
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [totalEntries, setTotalEntries] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedInvitation, setSelectedInvitation] = useState(null);

    const [filters, setFilters] = useState({
        search: '',
        page: 1,
        limit: 10,
        sort: { field: 'updatedAt', order: -1 }
    });

    const fetchData = useCallback(async (endpoint, params = {}) => {
        dispatch({ type: 'loader_show', response: true });
        try {
            const { data } = await Axios.get(endpoint, { params });
            dispatch({ type: 'subscription_expired', response: data?.data.statusCode });
            setLoadedOnce(true)
            dispatch({ type: 'loader_hide', response: true });
            return data?.data;
        } catch (error) {
            toast.error(error.response?.data?.message || `Error fetching data`);
            dispatch({ type: 'loader_hide', response: true });
            return null;
        }
    }, []);

    const handleDelete = async () => {
        if (!selectedInvitation) return;

        dispatch({ type: 'loader_show', response: true });
        try {
            await Axios.delete(`/api/invitation/${selectedInvitation._id}`);
            toast.success('Invitation deleted successfully');
            setInvitations(invitations.filter(inv => inv._id !== selectedInvitation._id));
            setShowDeleteModal(false);
            setSelectedInvitation(null);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error deleting invitation');
        }
        dispatch({ type: 'loader_hide', response: true });
    };

    const handleCreate = async (invitation) => {
        dispatch({ type: 'loader_show', response: true });
        try {
            await Axios.patch(`/api/invitation/${invitation._id}`, {
                status: "Create"
            });
            toast.success('Invitation created successfully');
            const updatedInvitations = invitations.map(inv =>
                inv._id === invitation._id ? { ...inv, status: "Create" } : inv
            );
            setInvitations(updatedInvitations);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error creating invitation');
        }
        dispatch({ type: 'loader_hide', response: true });
    };

    useEffect(() => {
        const getStores = async () => {
            const storesData = await fetchData("/api/dashboard/stores");
            if (storesData?.stores) {
                storesData.stores.map((v) => {
                    v.value = v._id;
                    v.label = v.name;
                    return v;
                });
                setStores(storesData.stores);
                let storeIndex = 0;
                if (storesData?.default_store) {
                    storeIndex = storesData.stores.findIndex(n => n._id == storesData.default_store)
                };
                console.log(storesData.stores[storeIndex]);

                setCurrentStore(storesData.stores[storeIndex]);
            }
        };
        getStores();
    }, [fetchData]);

    useEffect(() => {
        if (!currentStore?._id) return;

        const getInvitations = async () => {
            const data = await fetchData('/api/invitation', {
                store_id: currentStore?._id,
                ...filters,
                sort_type: filters.sort.field,
                sort: filters.sort.order
            });

            if (data) {
                setInvitations(data.records);
                setTotalEntries(data.total);
            }
        };
        getInvitations();
    }, [currentStore, filters, fetchData]);

    if (!stores.length && loadedOnce) return <ProfitLanding />;

    return (
        <Card className="invitation-management">
            <Card.Body>
                <div className="header-section">
                    <h4>Invitations</h4>
                    <div className="store-controls">
                        {stores?.length ? <div className="dropdown product-rating-menu mr-2">
                            <Select
                                closeMenuOnSelect={true}
                                defaultValue={currentStore}
                                options={stores}
                                onChange={(e) => setCurrentStore(e)}
                            />
                        </div> : null}
                        <Link to={`/tiktok-store/invitations/manage`} target="_blank">
                            <Button
                                variant="primary" className="btn-xs">
                                Create Invitation
                            </Button>
                        </Link>
                    </div>
                </div>

                <div className="search-section">
                    <Form.Control
                        className="w-auto form-control-sm"
                        type="text"
                        placeholder="Search invitations..."
                        value={filters.search}
                        onChange={e => setFilters(prev => ({
                            ...prev,
                            search: e.target.value,
                            page: 1
                        }))}
                    />
                </div>

                <div className="table-responsive">
                    <Table>
                        <thead>
                            <tr>
                                {['Name', 'Message', 'Date', 'Status', 'Products', 'Actions'].map(header => (
                                    <th key={header}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {invitations.map(invitation => (
                                <tr key={invitation._id}>
                                    <td>{invitation.name}</td>
                                    <td>{invitation.message}</td>
                                    <td>{new Date(invitation.date).toLocaleDateString()}</td>
                                    <td>
                                        <span className={`status-badge ${invitation.status.toLowerCase()}`}>
                                            {invitation.status}
                                        </span>
                                    </td>
                                    <td>{invitation.products.length}</td>
                                    <td>{invitation.status === "Draft" ?
                                        <div className="d-flex">
                                            <OverlayTrigger
                                                overlay={<Tooltip>EDIT</Tooltip>}
                                            >
                                                <Link to={`/tiktok-store/invitations/manage?id=${invitation._id}`}
                                                    className="btn btn-outline-primary btn-xs mr-1"
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </Link>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                overlay={<Tooltip>DELETE</Tooltip>}
                                            >
                                                <Button
                                                    variant='outline-danger'
                                                    onClick={() => {
                                                        setSelectedInvitation(invitation);
                                                        setShowDeleteModal(true);
                                                    }}
                                                    className="btn btn-xs mr-1"
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                overlay={<Tooltip>CREATE</Tooltip>}
                                            >
                                                <Button
                                                    variant='outline-success'
                                                    onClick={() => handleCreate(invitation)}
                                                    className="btn btn-xs mr-1">
                                                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </div> : null}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <div className="pagination-section">
                    <span>
                        Showing {((filters.page - 1) * filters.limit) + 1} to {Math.min(filters.page * filters.limit, totalEntries)} of {totalEntries}
                    </span>
                    <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageCount={Math.ceil(totalEntries / filters.limit)}
                        onPageChange={({ selected }) => setFilters(prev => ({ ...prev, page: selected + 1 }))}
                        containerClassName="pagination"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>

                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this invitation?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleDelete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Card.Body>
        </Card>
    );
};

export default InvitationList;
