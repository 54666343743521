import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Card, Modal } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TikTokEmbed } from 'react-social-media-embed';
import { Context } from '../../../../../MasterLayout';
import Axios from '../../../../plugins/axios';
import Conversion from '../../../CommonComponents/Conversion';
import RelevantVideoInfList from './RelevantVideoInfList';


function RelevantVideosInfSearch() {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const [relevantVideoFilter, setrelevantVideoFilter] = useState('7_days');
    const dateRangeRef = useRef();
    const dateFilterOption = {
        '7_days': {
            label: 'Past 7 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '15_days': {
            label: 'Past 15 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        },
        '30_days': {
            label: 'Past 30 Days',
            begin_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
            end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]
        }
    };
    const [dateRange, setDateRange] = useState(dateFilterOption[relevantVideoFilter]);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');
    const [contentModal, setContentModal] = useState(false);

    const [sorting, setSorting] = useState({ sort: 3 });
    const sortClass = 'sorting-desc-blue';
    const handleSort = async (sort) => {
        if (sort != sorting.sort) {
            setSorting({ sort });
            setpage(1);
        }
    };

    const dateFilterOptionHtml = Object.keys(dateFilterOption).map(function (key) {
        return <Button key={key} variant="outline-dark" size="xs" className={(relevantVideoFilter == key ? 'btn-primary' : '')} onClick={() => { setrelevantVideoFilter(key); setpage(1) }}>{dateFilterOption[key].label}</Button>
    });
    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
        );
        setDateRange({ begin_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
        setrelevantVideoFilter('custom');
    };
    const relevantVideoProductRef = useRef();

    const handleCancel = (event, picker) => {
        picker.element.val('');
        setDateRange([]);
    };
    const params = useParams();
    const changeDate = (startDate, endDate) => {
        dateRangeRef.current.setStartDate(moment(new Date(startDate)).format('MM/DD/YYYY'));
        dateRangeRef.current.setEndDate(moment(new Date(endDate)).format('MM/DD/YYYY'));
    };
    const [relevantVideo, setrelevantVideo] = useState();
    const [relevantVideolist, setrelevantVideolist] = useState([]);
    const [page, setpage] = useState(1)
    const relevantVideoApi = async () => {
        try {
            let bodyParams = {};
            if (relevantVideoFilter == 'custom') {
                bodyParams = {
                    begin_date: dateRange.begin_date,
                    end_date: dateRange.end_date,
                };
            } else {
                bodyParams = dateFilterOption[relevantVideoFilter];
                setDateRange(bodyParams);
            }
            if (page) {
                bodyParams.cursor = page;
            }
            bodyParams.author_id = params.influencerId;

            changeDate(bodyParams.begin_date, bodyParams.end_date);
            if (sorting.sort) {
                bodyParams.sort = sorting.sort;
            }
            let response = await Axios.post(window?.location?.pathname?.includes('v2') ? '/api/v2/influencer-relevant-videos' : '/api/influencer-relevant-videos', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });

            if (response && response.status === 200) {

                setrelevantVideo(response.data.data);
                const docs = [];
                if (response?.data?.data?.video_list_v2_list?.length) {
                    for (let i = 0; i < 10; i++) {
                        if (response?.data?.data?.video_list_v2_list[i]) {
                            docs.push(response.data.data.video_list_v2_list[i])
                        } else { break }
                    };
                    setrelevantVideolist(docs);
                }

                await relevantVideoProductRef.current.relevantVideoProduct(response.data.data);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    useEffect(() => {
        relevantVideoApi();
    }, [relevantVideoFilter, sorting, page]);

    const handlePageClick = (event) => {
        setpage(event.selected + 1);
        const startIndex = ((event.selected + 1) - 1) * 10;
        const endIndex = Math.min(startIndex + 10, relevantVideo?.video_list_v2_list?.length);
        const docs = [];
        for (let i = startIndex; i < endIndex; i++) {
            if (relevantVideo?.video_list_v2_list[i]) {
                docs.push(relevantVideo?.video_list_v2_list[i])
            } else { break; }
        };
        setrelevantVideolist(docs)
    };



    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-lg-5">
                            <h3>{t('influencers.relevant_videos')}</h3>
                        </div>
                        <div className="col-lg-7 text-right">
                            <div className='d-flex justify-content-end align-items-center'>
                                <ButtonGroup className='mr-4'>
                                    {dateFilterOptionHtml}
                                </ButtonGroup>
                                <DateRangePicker
                                    ref={dateRangeRef}
                                    initialSettings={{ startDate: moment(new Date(dateRange.begin_date)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_date)).format('MM/DD/YYYY') }}
                                    onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                                >
                                    <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={dateRange.begin_date + " - " + dateRange.end_date} />
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mb-4'>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.views')}</strong></p>
                                    <h3>{relevantVideo?.total_play_count ? '+' : ''}  {relevantVideo?.total_play_count ? <Conversion value={relevantVideo?.total_play_count} /> : "0"}</h3>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('common.likes')}</strong></p>
                                    <div className='d-flex'>
                                        <h3>{relevantVideo?.total_digg_count ? '+' : ''}  {relevantVideo?.total_digg_count ? <Conversion value={relevantVideo?.total_digg_count} /> : "0"}</h3>
                                    </div>

                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('influencers.shares')}</strong></p>
                                    <h3>{relevantVideo?.total_share_count ? '+' : ''}  {relevantVideo?.total_share_count ? <Conversion value={relevantVideo?.total_share_count} /> : "0"}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="bg-light mr-3">
                            <Card.Body className=" mb-0">
                                <Card.Text>
                                    <p className='mb-0'><strong>{t('influencers.comments')}</strong></p>
                                    <h3>{relevantVideo?.total_comment_count ? '+' : ''}  {relevantVideo?.total_comment_count ? <Conversion value={relevantVideo?.total_comment_count} /> : "0"}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <RelevantVideoInfList currentpage={page} tikTokEmbedID={tikTokEmbedID} setTikTokEmbedID={setTikTokEmbedID} contentModal={contentModal} setContentModal={setContentModal} handleSort={handleSort} sortClass={sortClass} sorting={sorting} relevantVideolist={relevantVideolist} totalCount={relevantVideo?.total} handlePageClick={handlePageClick} />
                </Card.Body>
            </Card>
            {/* <!-- Video Modal --> */}
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RelevantVideosInfSearch