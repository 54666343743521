import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { TikTokEmbed } from 'react-social-media-embed';
import { Context } from '../../../../../MasterLayout';
import Axios from '../../../../plugins/axios';
import Conversion from '../../../CommonComponents/Conversion';
import EventCalendar from "./EventCalendar";


const CalenderInfluencer = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const [onLoad, setOnLoad] = useState(false)
    const { dispatch } = useContext(Context);
    const params = useParams();
    const [calenderlist, setCalenderlist] = useState([]);
    const [listProductVideos, setlistProductVideos] = useState([]);
    const [items, setItems] = useState({ products: 0, othervideos: 0, productvideos: 0, lives: 0 });
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [dateRange, setDateRange] = useState({
        start_day: moment().startOf('month').format('YYYY-MM-DD'),
        end_day: moment().endOf('month').format('YYYY-MM-DD'),
    });
    const backgroundColor = {
        'product_count': 'rgb(125, 104, 225)',
        'product_video_count': 'red',
        'video_count': 'rgb(106, 206, 216)',
        'live_count': 'rgb(132, 207, 128)'
    };
    const [contentModal, setContentModal] = useState(false);
    const [tikTokEmbedID, setTikTokEmbedID] = useState('');

    useImperativeHandle(ref, () => ({
        async calender() {
            if (onLoad == false) {
                //setDateRange();
                calenderApi();
                setOnLoad(true);
            }
        }
    }));

    useEffect(() => {
        if (onLoad == true) {
            setCalendarEvents([]);
            setlistProductVideos([]);
            calenderApi();
        }
    }, [dateRange]);
    const calenderApi = async () => {
        try {
            let bodyParams = {
                ...dateRange,
                author_id: params.influencerId,
            };
            let response = await Axios.post(window?.location?.pathname?.includes('v2') ? '/api/v2/influencer-calendar' : '/api/influencer-calendar', bodyParams);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            const data = response.data.data
            let othervideos = 0, productvideos = 0, products = 0, lives = 0, calendarEventsList = [];
            if (response && response.status === 200) {
                for (let i = 0; i < data.length; i++) {
                    products = data[i].value.product_count + products;
                    productvideos = data[i].value.product_video_count + productvideos;
                    othervideos = data[i].value.video_count + othervideos;
                    lives = data[i].value.live_count + lives;

                    ['product_count', 'product_video_count', 'video_count', 'live_count'].map((value) => {
                        if (data[i].value[value] && data[i].value[value] > 0) {
                            calendarEventsList.push({
                                allDay: true,
                                start: new Date(data[i].date + " 00:00:00"),
                                //end: new Date("2024-02-04 23:59:59"),
                                title: data[i].value[value],
                                classNames: '',
                                backgroundColor: backgroundColor[value],
                                //display: 'list-item',
                            });
                        }
                        return null;
                    });
                }
            }
            setCalendarEvents(calendarEventsList);
            setCalenderlist(response.data.data);
            setItems({ products, othervideos, productvideos, lives })
        } catch (error) {
            console.error('Error: ' + error.message);
        };
    }
    const showList = (date) => {
        setSelectedDate(date);
        let [list] = calenderlist.filter(value => value.date == date);

        let live = [], productVideos = [], product = [], otherVideos = [];
        for (let i = 0; i < list?.value?.live_list?.length; i++) {
            list.value.live_list[i].type = "Lives"
            live.push(list?.value?.live_list[i]);
        }
        for (let i = 0; i < list?.value?.product_list?.length; i++) {
            list.value.product_list[i].type = "product"
            product.push(list?.value?.product_list[i]);
        }
        for (let i = 0; i < list?.value?.product_video_list?.length; i++) {
            list.value.product_video_list[i].type = "product-video"
            productVideos.push(list?.value?.product_video_list[i]);
        }
        for (let i = 0; i < list?.value?.video_list?.length; i++) {
            list.value.video_list[i].type = "other-video"
            otherVideos.push(list?.value?.video_list[i]);
        }

        setlistProductVideos([...live, ...productVideos, ...product, ...otherVideos])
    }
    const renderMonthContent = (month, shortMonth, longMonth, day) => {
        const fullYear = new Date(day).getFullYear();
        const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

        return <span title={tooltipText}>{shortMonth}</span>;
    };

    const handleDateChange = (date) => {
        setDateRange({
            start_day: moment(date, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'),
            end_day: moment(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD'),
        })
    }

    return (
        <>
            <div>
                <Card className='mb-5'>
                    <Card.Body>
                        <h3> {t('influencers.calender')}</h3>

                        {/* CALENDER NPM PACKAGE */}
                        <div className='row'>
                            <div className='col-lg-7'>
                                <p className='small mb-0'>
                                    <span className='text-primary'>{items.othervideos}</span> {t('influencers.other_videos')},
                                    <span className='text-primary ml-3'>{items.productvideos}</span> {t('influencers.product_videos')},
                                    <span className='text-primary ml-3'>{items.products}</span> {t('common.products')} and
                                    <span className='text-primary ml-3'>{items.lives}</span> {t('influencers.lives_were_released_In_Jan')}</p>
                                {/* CALENDER BUTTON FOR DUMMY PURPOSE */}
                                <div className='d-flex flex-wrap'>
                                    <div className='d-flex mr-3 align-items-center'>
                                        <i style={{ color: 'rgb(106, 206, 216)' }} className="fa fa-circle mr-1" aria-hidden="true"></i>
                                        <p className='mb-0'>{t('influencers.other_videos')}</p>
                                    </div>
                                    <div className='d-flex mr-3 align-items-center'>
                                        <i style={{ color: 'red' }} className="fa fa-circle mr-1" aria-hidden="true"></i>
                                        <p className='mb-0'>{t('influencers.product_videos')}</p>
                                    </div>
                                    <div className='d-flex mr-3 align-items-center'>
                                        <i style={{ color: 'rgb(125, 104, 225)' }} className="fa fa-circle mr-1" aria-hidden="true"></i>
                                        <p className='mb-0'>{t('common.products')}</p>
                                    </div>
                                    <div className='d-flex mr-3 align-items-center'>
                                        <i style={{ color: 'rgb(132, 207, 128)' }} className="fa fa-circle mr-1" aria-hidden="true"></i>
                                        <p className='mb-0'>{t('influencers.lives')}</p>
                                    </div>
                                </div>
                                <div className='cal-date'>
                                    <DatePicker
                                        selected={dateRange.start_day ? new Date(dateRange.start_day) : new Date()}
                                        renderMonthContent={renderMonthContent}
                                        showMonthYearPicker
                                        className='form-control form-control-sm'
                                        dateFormat="MM/yyyy"
                                        onChange={(date) => handleDateChange(date)}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-5'></div>
                            <div className='col-lg-7'>
                                {onLoad && <EventCalendar calendarEvents={calendarEvents} showList={showList} startDate={dateRange.start_day} />}
                            </div>
                            <div className='col-lg-5 pt-5'>
                                {/*CALENDER BUTTON LISTS AND SIDE BAR*/}
                                {/* CALENDER LISTS  */}
                                {/* //MAP FUNC STARTS HERE */}
                                {listProductVideos.length ? listProductVideos.map((liste, index) => (
                                    <Card key={index} className='h-auto bg-light'>
                                        {
                                            liste.type === 'Lives' && (
                                                <div key={index}>
                                                    <Card.Header className='border-0 bg-primary px-3 py-2 text-white'>
                                                        <Card.Title className='w-100 text-white'>
                                                            <div className='d-flex justify-content-between w-100'>
                                                                <p className='mb-0'>{selectedDate}</p>
                                                                <p className='mb-0'>{liste?.type}</p>
                                                            </div>
                                                        </Card.Title>
                                                    </Card.Header>

                                                    <Card.Body className='p-3'>
                                                        <div className='row'>
                                                            <div className='col-lg-2'>
                                                                <div className="prd-img mr-3 mb-2">
                                                                    <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${liste.room_cover}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-10'>
                                                                <OverlayTrigger overlay={<Tooltip>{liste?.title}</Tooltip>}>
                                                                    <p className='text-truncate mb-0' style={{ width: '350px' }}><strong>{liste?.title}</strong></p>
                                                                </OverlayTrigger>

                                                                <div className='d-flex'>
                                                                    <div className='mr-3'>
                                                                        <p className='mb-0'>Total viewers:  <Conversion value={liste?.total_user} />
                                                                        </p>
                                                                        <p className='mb-0'>Online viewers peak: {liste?.max_user_count}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className='mb-0'>Sales: {liste?.sold_count}</p>
                                                                        <p className='mb-0'>Revenue: {liste?.total_sales_usd_str} ({liste?.total_sales_str})</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            )
                                        }
                                        {
                                            liste.type === 'product' && (
                                                <div key={index}>
                                                    <Card.Header className='border-0 bg-primary px-3 py-2 text-white'>
                                                        <Card.Title className='w-100 text-white'>
                                                            <div className='d-flex justify-content-between w-100'>
                                                                <p className='mb-0'>{selectedDate}</p>
                                                                <p className='mb-0'><i className="fa fa-shopping-bag" aria-hidden="true"></i> Product</p>
                                                            </div>
                                                        </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body className='p-3'>
                                                        <div className='row'>
                                                            <div className='col-lg-2'>
                                                                <div className="prd-img mr-3 mb-2">
                                                                    <Link to={'/products/' + liste.product_id + '/details'} target='_blank'>
                                                                        {liste.images_privatization[0] && <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/product/${liste.images_privatization[0]}?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1`} />}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-10'>
                                                                <OverlayTrigger overlay={<Tooltip>{liste?.title}</Tooltip>}>
                                                                    <Link to={'/products/' + liste.product_id + '/details'} target='_blank'>
                                                                        <p className='text-truncate mb-0' style={{ width: '350px' }}><strong>{liste?.title}</strong></p>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                                <div className='d-flex'>
                                                                    <div className='mr-3'>
                                                                        <p className='mb-0'>Price: {liste?.usd_price}({liste?.price})
                                                                        </p>
                                                                        <p className='mb-0'>Sales：{liste?.estimated_sales_volume}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className='mb-0'>Commission: {(liste?.commission_rate * 100).toFixed(2)}%</p>
                                                                        <p className='mb-0'>Revenue： {liste?.estimated_sales_amount_usd} ({liste?.estimated_sales_amount})</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            )
                                        }
                                        {
                                            liste.type === 'product-video' && (
                                                <div key={index}>
                                                    <Card.Header className='border-0 bg-primary px-3 py-2 text-white'>
                                                        <Card.Title className='w-100 text-white'>
                                                            <div className='d-flex justify-content-between w-100'>
                                                                <p className='mb-0'>{selectedDate}</p>
                                                                <p className='mb-0'><i className="fa fa-video-camera" aria-hidden="true"></i> Product Video</p>
                                                            </div>
                                                        </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body className='p-3'>
                                                        <div className='row'>
                                                            <div className='col-lg-2'>
                                                                <div className="prd-img mr-3 mb-2">
                                                                    <Link to={'/products/' + liste.id + '/video-details'} target='_blank'>
                                                                        {liste.origin_cover_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + liste.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                                                    </Link>
                                                                    <Button
                                                                        variant="primary"
                                                                        className="p-0 video-popup-btn"
                                                                        onClick={() => { setTikTokEmbedID(liste.id); setContentModal(!contentModal); }}
                                                                    >
                                                                        <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-10'>
                                                                <OverlayTrigger overlay={<Tooltip>{liste?.title}</Tooltip>}>
                                                                    <Link to={'/products/' + liste.id + '/video-details'} target='_blank'>
                                                                        <p className='text-truncate mb-0' style={{ width: '350px' }}><strong>{liste?.title ? liste.title : liste?.desc}</strong></p>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                                <div className='d-flex'>
                                                                    <div className='mr-3'>
                                                                        <p className='mb-0'>Views: <Conversion value={liste?.play_count} />
                                                                        </p>
                                                                        <p className='mb-0'>Likes：{liste?.digg_count}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className='mb-0'>Sales: 47</p>
                                                                        <p className='mb-0'>Revenue： {liste?.product_info_list[0].estimated_sales_amount_usd} ({liste.product_info_list[0].estimated_sales_amount})</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            )
                                        }
                                        {
                                            liste.type === 'other-video' && (
                                                <div key={index}>
                                                    <Card.Header className='border-0 bg-primary px-3 py-2 text-white'>
                                                        <Card.Title className='w-100 text-white'>
                                                            <div className='d-flex justify-content-between w-100'>
                                                                <p className='mb-0'>{selectedDate}</p>
                                                                <p className='mb-0'><i className="fa fa-video-camera" aria-hidden="true"></i> Other Videos</p>
                                                            </div>
                                                        </Card.Title>
                                                    </Card.Header>
                                                    <Card.Body className='p-3'>
                                                        <div className='row'>
                                                            <div className='col-lg-2'>
                                                                <div className="prd-img mr-3 mb-2">
                                                                    <Link to={'/products/' + liste.id + '/video-details'} target='_blank'>
                                                                        {liste.origin_cover_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + liste.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                                                    </Link>
                                                                    <Button
                                                                        variant="primary"
                                                                        className="p-0 video-popup-btn"
                                                                        onClick={() => { setTikTokEmbedID(liste.id); setContentModal(!contentModal); }}
                                                                    >
                                                                        <i className="fa fa-play-circle" aria-hidden="true"></i>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-10'>
                                                                <OverlayTrigger overlay={<Tooltip>{liste?.desc}</Tooltip>}>
                                                                    <Link to={'/products/' + liste.id + '/video-details'} target='_blank'>
                                                                        <p className='text-truncate mb-0' style={{ width: '350px' }}><strong>{liste?.desc}</strong></p>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                                <div className='d-flex'>
                                                                    <div className='mr-3'>
                                                                        <p className='mb-0'>Views: 14.0K
                                                                        </p>
                                                                        <p className='mb-0'>Likes：{liste?.digg_count}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className='mb-0'>Comments: {liste?.comment_count}</p>
                                                                        <p className='mb-0'>Shares: {liste?.share_count}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            )
                                        }
                                    </Card>
                                )) : ''}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {/* <!-- Video Modal --> */}
            <Modal className="fade video-popup" show={contentModal}>
                <Modal.Body className='p-0'>
                    <div className='d-flex'>
                        <div className='text-center'>
                            <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                        </div>
                        <div className='px-3'>
                            <div className='d-flex flex-column'>
                                <Button
                                    variant="dark"
                                    className="mb-3"
                                    onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
})

export default CalenderInfluencer