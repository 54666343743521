import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Axios from "../../plugins/axios";

const MyAccount = ({ user, func }) => {
  const { t } = useTranslation();

  const [fullNameMessage, setFullNameMessage] = useState("");
  const [phoneNumberMessage, setPhoneNumberMessage] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordMessage, setCurrentPasswordMessage] = useState("");
  const [confirmNewPasswordMessage, setConfirmNewPasswordMessage] =
    useState("");

  const handleChange = async (e) => {
    user[e.target.name] = e.target.value;
  };

  const submitHandler = async () => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    try {
      if (!user.full_name || !user.mobileNumber) {
        if (!user.full_name) {
          setFullNameMessage(t("user_profile.full_name_is_required"));
        }
        if (!user.mobileNumber) {
          setPhoneNumberMessage(t("user_profile.phone_number_is_required"));
        }
        return;
      }
      if (currentPassword || newPassword || confirmNewPassword) {
        if (!currentPassword) {
          setCurrentPasswordMessage(
            t("user_profile.current_password_is_required")
          );
          return;
        }
        if (!newPassword || !confirmNewPassword) {
          setConfirmNewPasswordMessage(t("user_profile.password_mis_matches"));
          return;
        } else {
          if (newPassword != confirmNewPassword) {
            setConfirmNewPasswordMessage(
              t("user_profile.password_mis_matches")
            );
            return;
          } else if (!passwordRegex.test(newPassword)) {
            setConfirmNewPasswordMessage(
              "Password Must Contain Minimum Eight Characters With At Least One Uppercase Letter, One Lowercase Letter, One Number And One Special Character!"
            );
            return;
          }
        }
      }

      if (currentPassword && newPassword) {
        user.passwordObj = {
          currentPassword: currentPassword,
          newPassword: newPassword,
        };
      }

      const response = await Axios.post("/api/user", user).catch((e) => {
        console.error("Axios-Catch: onBlurEmail " + e.message);
      });

      if (response && response.status === 200) {
        func();
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <Card>
            <Card.Body>
              <h4>{t("user_profile.basic_information")}</h4>
              <p className="text-muted">
                {t("user_profile.you_can_edit_your")}
              </p>
              <div className="form-group">
                <label>{t("user_profile.name")}</label>
                <input
                  type="text"
                  className={`form-control form-control-sm ${
                    fullNameMessage ? "is-invalid" : ""
                  }`}
                  placeholder={t("user_profile.full_name")}
                  value={user.full_name}
                  name="full_name"
                  onChange={(e) => {
                    handleChange(e);
                    setFullNameMessage("");
                  }}
                  onBlur={() =>
                    !user.full_name
                      ? setFullNameMessage(
                          t("user_profile.full_name_is_required")
                        )
                      : ""
                  }
                  required
                />
                {fullNameMessage && (
                  <div className="invalid-feedback">{fullNameMessage}</div>
                )}
              </div>
              <div className="form-group">
                <label>{t("user_profile.phone_number")}</label>
                <input
                  type="number"
                  className={`form-control form-control-sm ${
                    phoneNumberMessage ? "is-invalid" : ""
                  }`}
                  placeholder={t("user_profile.phone_number")}
                  value={user.mobileNumber}
                  name="mobileNumber"
                  onChange={(e) => {
                    handleChange(e);
                    setPhoneNumberMessage("");
                  }}
                  onBlur={() =>
                    !user.mobileNumber
                      ? setPhoneNumberMessage(
                          t("user_profile.phone_number_is_required")
                        )
                      : ""
                  }
                  required
                />
                {phoneNumberMessage && (
                  <div className="invalid-feedback">{phoneNumberMessage}</div>
                )}
              </div>
              <div className="form-group">
                <label>{t("user_profile.email_address")}</label>
                <input
                  type="email"
                  className={`form-control form-control-sm`}
                  placeholder={t("user_profile.email_address")}
                  value={user.email}
                  name="email"
                  required
                  disabled
                />
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card>
            <Card.Body>
              <h4>{t("user_profile.change_password")}</h4>
              <p className="text-muted">
                {t("user_profile.you_can_change_your")}
              </p>
              <div className="form-group">
                <label>{t("user_profile.current_password")}</label>
                <input
                  type="password"
                  className={`form-control form-control-sm ${
                    currentPasswordMessage ? "is-invalid" : ""
                  }`}
                  placeholder={t("user_profile.current_password")}
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setCurrentPasswordMessage("");
                  }}
                />
                {currentPasswordMessage && (
                  <div className="invalid-feedback">
                    {currentPasswordMessage}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("user_profile.new_password")}</label>
                <input
                  type="password"
                  className={`form-control form-control-sm ${
                    confirmNewPasswordMessage ? "is-invalid" : ""
                  }`}
                  placeholder={t("user_profile.new_password")}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setConfirmNewPasswordMessage("");
                  }}
                />
              </div>
              <div className="form-group">
                <label>{t("user_profile.confirm_new_password")}</label>
                <input
                  type="password"
                  className={`form-control form-control-sm ${
                    confirmNewPasswordMessage ? "is-invalid" : ""
                  }`}
                  placeholder={t("user_profile.confirm_new_password")}
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    setConfirmNewPasswordMessage("");
                  }}
                />
                {confirmNewPasswordMessage && (
                  <div className="invalid-feedback">
                    {confirmNewPasswordMessage}
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Button
        variant="primary"
        size="sm"
        className="mr-5"
        onClick={() => submitHandler()}
      >
        {t("user_profile.update")}
      </Button>
    </>
  );
};

export default MyAccount;
